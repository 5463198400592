import React from 'react'

import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import { frontEndBaseUrl, isProductionMode } from '../../components/ConstantUtils';

// import LebronStretch from '';
// import {Font} from '@react-pdf/renderer';
// import MyCustomFont from '../fonts/Anton-Regular.ttf';



// Font.register({
//     family: 'AntonFamily',
//     src: MyCustomFont
//   })
  
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
    //   fontFamily: "",
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
    //   fontFamily: "AntonFamily",
    },
    image: {
      marginVertical: 10,
      marginHorizontal: 20,
      width: 150,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    //   fontFamily: "AntonFamily",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    //   fontFamily: "AntonFamily",
    },

    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: "red",
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: "red",
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: "red",
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },

    container: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
  });

export default function PrescriptionPDF() {
  return (
    <Document>
        return (
            <Page>
    
                <Image style={styles.image} src="../assets/img/brand/skin-n-you-logo-ol.jpg" fixed />
                <Text style={styles.text}>
                    <View style={styles.tableContainer}>
                        {/* <InvoiceTableHeader /> */}

                        <View style={styles.container}>
                            <Text style={styles.description}>Item Description</Text>
                            <Text style={styles.qty}>Qty</Text>
                            <Text style={styles.rate}>@</Text>
                            <Text style={styles.amount}>Amount</Text>
                        </View>


                        {/* <InvoiceTableRow items={invoice.items} /> */}
                        {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} /> */}
                        {/* <InvoiceTableFooter items={invoice.items} /> */}
                    </View>

                </Text>


            </Page>
        )

    </Document>
  )
}
