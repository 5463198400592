import React from 'react'
import { Link } from 'react-router-dom'

export default function Sidebar() {
  return (
	<>
		<div className="nav-application clearfix">
			
			<Link to="/appointment" className="btn btn-square text-sm">
				<span className="btn-inner--icon d-block"><i className="far fa-calendar-check fa-2x"></i></span>
				<span className="btn-inner--icon d-block pt-2">Appointments</span>
			</Link>
		
			<Link to="/patient" className="btn btn-square text-sm">
				<span className="btn-inner--icon d-block"><i className="far fa-user-injured fa-2x"></i></span>
				<span className="btn-inner--icon d-block pt-2">Patients</span>
			</Link>

			<Link to="/purchase-list" className="btn btn-square text-sm">
				<span className="btn-inner--icon d-block"><i className="far fa-medkit fa-2x"></i></span>
				<span className="btn-inner--icon d-block pt-2">Purchase</span>
			</Link>
			
			<Link to="/product" className="btn btn-square text-sm">
				<span className="btn-inner--icon d-block"><i className="far fa-tasks fa-2x"></i></span>
				<span className="btn-inner--icon d-block pt-2">Medicine Inventory</span>
			</Link>

			<Link to="/medicine-category" className="btn btn-square text-sm">
				<span className="btn-inner--icon d-block"><i className="fa fa-capsules fa-2x"></i></span>
				<span className="btn-inner--icon d-block pt-2">Medicine Category</span>
			</Link>


			<Link to="/branch-list" className="btn btn-square text-sm">
				<span className="btn-inner--icon d-block"><i className="far fa-hospital-user fa-2x"></i></span>
				<span className="btn-inner--icon d-block pt-2">Branches</span>
			</Link>
			
			<Link to="/user" className="btn btn-square text-sm">
				<span className="btn-inner--icon d-block"><i className="far fa-user-md fa-2x"></i></span>
				<span className="btn-inner--icon d-block pt-2">Staff & Doctors</span>
			</Link>

			<Link to="/sub-disease" className="btn btn-square text-sm">
				<span className="btn-inner--icon d-block"><i className="far fa-user fa-2x"></i></span>
				<span className="btn-inner--icon d-block pt-2">Treatment</span>
			</Link>

			<Link to="/Vendor" className="btn btn-square text-sm">
				<span className="btn-inner--icon d-block"><i className="fa fa-building fa-2x"></i></span>
				<span className="btn-inner--icon d-block pt-2">Pharma Vendor</span>
			</Link>



			<Link to="/report" className="btn btn-square text-sm">
				<span className="btn-inner--icon d-block"><i className="far fa-file-chart-line fa-2x"></i></span>
				<span className="btn-inner--icon d-block pt-2">Reports</span>
			</Link>

		</div>

		{/* <div className="card bg-gradient-warning">
			<div className="card-body">
				<h5 className="text-white">Heading here</h5>
				<p className="text-white mb-4">
					Example banner for any additional info.
				</p>
				<a href="#" className="btn btn-sm btn-block btn-white rounded-pill" target="_blank">Learn More</a>
			</div>
		</div> */}
	</>
  )
}
