import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import axios from 'axios';
import { accessToken } from '../../components/ValidationRegex';
import { Link, Navigate } from 'react-router-dom';
import ActionMenu from '../../components/ActionMenu';
import { apiBaseUrl } from '../../components/ConstantUtils';


import Dropdown from 'react-bootstrap/Dropdown';


export default function PrescriptionList() {

	if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }
  
    const actionMenuItem = [
		{
			'link_name': 'View',
			'link_to': '/purchase-detail',
			'column': 'id',
			'url_param': true
		},
		{
			'link_name': 'Sell Medicine',
			'link_to': '/prescription',
			'column': 'id',
			'url_param': false
		},
	]
	
  	const tableColumns = [
		{
			"name": "Patient",
			"selector": (row) => row.patient,
			"sortable": true 
		},
		{
			"name": "Doctor",
			"selector": (row) => row.doctor,
			"sortable": true 
		},
		{
			"name": "Created At",
			"selector": (row) => row.created_at,
			"sortable": true 
		},
		// {
		// 	"name": "Download Invoice",
		// 	"cell": (row) => row.invoice ? <a href={row.invoice} download>Download</a>: "",
			
		// },
		{
			"name": "Action",
			"cell": (row) => <ActionMenu row_data={row} pk={row.id} actionMenuItem={actionMenuItem}></ActionMenu>
		},
	]

	const [tableRows, setTableRows] = useState([]);
    const [dropdownName, setDropdownName] = useState("Today's Prescriptions");
	const [dropdownValue, setDropdownValue] = useState("today");
	const [pageEndPoint, setPageEndpoint] = useState("api/book/prescription-table/"+dropdownValue)
	
    

    useEffect(() => {
		setPageEndpoint("api/book/prescription-table/"+dropdownValue)
	}, [dropdownValue]);

    const handleDateDropdown = (e) => {
		setDropdownValue(e.target.getAttribute('data-value'));
		setDropdownName(e.target.getAttribute('data-menu-title'));
		setPageEndpoint("api/book/prescription-table/"+dropdownValue)
	}

    return (
        <div className="container-fluid container-application">
            <div className="sidenav show" id="sidenav-main">
                <Header></Header>
                <Sidebar></Sidebar>
            </div>
            <div className="main-content position-relative">
                <Navbar></Navbar>
                <div className="page-content">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            
                        <div className='row'>
							<div className='col-md-6'>
								<PageTitle link_not_required="1" pageName="All Prescriptions" link_to="add-appointment" link_name="Add Appointment"></PageTitle>
							</div>
							<div className='col-md-6 text-right'>
								<Dropdown className='mr-3'>
									<Dropdown.Toggle  variant="success" className='btn btn-sm btn-white btn-icon rounded-pill' id="dropdown-basic">
										<i className='fa fa-filter'></i> {dropdownName}
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item onClick={handleDateDropdown} data-value="today" data-menu-title="Today's Prescriptions">Today's</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="tomorrow" data-menu-title="Tomorrow's Prescriptions">Tomorrow's</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="yesterday" data-menu-title="Yestderday's Prescriptions" >Yesterday</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="this_week" data-menu-title="This Week's Prescriptions" >This Week</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="this_month" data-menu-title="This Month's Prescriptions">This Month</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="all" data-menu-title="All Prescriptions">All</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						
						<div className="card mt-4">
                            <div className="card-body card-stats">
                                <div className="row">
                                    <div className="col-md-12">
                                        <BoostrapDatatable param={dropdownValue} page_url={pageEndPoint} isServerSide={true} tableColumns={tableColumns} tableRows={tableRows}></BoostrapDatatable>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </div>
    )
}
