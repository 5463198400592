import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { apiBaseUrl } from './ConstantUtils';
import { accessToken } from './ValidationRegex';
import { Navigate } from 'react-router';

export default function UploadTreatmentImage(props) {

    const intialFormValues = {
		"images": "",
        "action": "",
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");

    const [isSubmitUploadImageForm, setIsSubmitUploadImageForm] = useState(false);

    const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}

    // Submit Upload Image Form
	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".upload_image_form");
			let formdata = new FormData(formObject);
            formdata.append('appointment_id', props.appointment_id);
            formdata.append('treatment_id', props.treatment_id);
            setIsSubmitUploadImageForm(true);

            let url = apiBaseUrl+ "api/book/appointment/treatment-after-images"
            if (props.upload_link == "before") {
                url = apiBaseUrl+ "api/book/appointment/treatment-before-images"
            } 

			axios({
                url: url,
                method: "POST",
                headers:{
					authorization: "Bearer "+ accessToken,
					'Content-Type': 'multipart/form-data'
				},
                data: formdata,
            }).then((res) => { 				
                setFormSubmitError("The treatment has been updated successfully.");
                setFormValues(intialFormValues);
                setIsSubmitUploadImageForm(false);
			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail'])
                    setIsSubmitUploadImageForm(false);
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormError(err.response.data);
                    setIsSubmitUploadImageForm(false);
				}
			});
		}
	}, [formErrors]);



    const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.doctor == "") {
			errors.doctor = "This field is required";
		}

		if (formDataObject.branch == "") {
			errors.branch = "This field is required";
		}

		if (formDataObject.appointment_at == "") {
			errors.appointment_at = "This field is required";
		}

        if (formDataObject.appointment_end_at == "") {
			errors.appointment_end_at = "This field is required";
		}

		if (formDataObject.about_disease == "") {
			errors.about_disease = "This field is required";
		}


        if (!props.patient_id && formDataObject.patient == "") {
            errors.patient = "This field is required";
        }

		return errors;
	}

    const handleUploadImageForm = (e) =>{
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	}



  return (
    <div>
        {formSubmitError && <div className='alert alert-success'>{formSubmitError}</div>}
        <form onSubmit={handleUploadImageForm} action='#!' method="post"  className={props.is_border_required ? 'border p-4 upload_image_form': 'upload_image_form'}>
            <div className='row'>

                <div className="col-md-12">
                    <div className="form-group">
                        <label className="form-control-label">Select Action</label>
                        <select value={formValues.action} onChange={handleChange} className='form-control' name="action">
                            <option value="no">Add new images along with Existing Images</option>
                            <option value="yes">Delete all existing images and upload new Images</option>
                        </select>
                        <span className='error_message'>{formErrors.action}</span>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label className="form-control-label">Upload Image (To upload multiple image... Use ctrl+select )</label>
                        <input value={formValues.images} onChange={handleChange} className="form-control" type="file" name="images" multiple/>
                        <span className='error_message'>{formErrors.images}</span>
                    </div>
                </div>

            </div>

            <div className='row'>
                <div className="col-md-12">
                    {formErrors.non_field_errors && <p className='error_message mb-4'>{formErrors.non_field_errors ? "Something went wrong ask development team to check this issues": ""}</p>}
                    
                    {!isSubmitUploadImageForm ? 
                        <div className="form-group text-right">
                            <button type="submit" className="btn btn-primary btn-sm" >Update Images</button>
                        </div>
                        : <div className="form-group text-right">
                            <button type="button" className="btn btn-info btn-sm" >Please Wait....</button>
                        </div>
                    }
                </div>
            </div>
        </form>
    </div>
  )
}


