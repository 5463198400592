import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
// import SelectField from '../../components/SelectField';

import { accessToken } from '../../components/ValidationRegex';

import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';
// import { apiBaseUrl } from  '../components/Constant';

export default function UserDetail() {
	const { id } = useParams()
	const [userData, setUserData] = useState();
	
	useEffect(() => {
		if (id) {
			axios({
				url: apiBaseUrl+ "api/account/staff/"+id+"?convert=fk",
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
			}).then((res) => { 
				var data = res.data;
				setUserData(data.data);
			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, []);


  return (
    <div className="container-fluid container-application">
		<div className="sidenav show" id="sidenav-main">
			<Header></Header>
			<Sidebar></Sidebar>
		</div>
    	<div className="main-content position-relative">
			<Navbar></Navbar>
			<div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<PageTitle pageName="Staff Details" link_to="user" link_name="View All Staff"></PageTitle>
						<div className="card mt-4">
						<div className="card-body card-stats">
							<div className="row">
								<div className="col-md-12">
									<table className='table table-bordered table-striped'>
										<tbody>
											<tr>
												<th>Name</th>
												<td>{userData ? userData.name: ""}</td>
												<th>Mobile</th>
												<td>{userData ? userData.mobile: ""}</td>
											</tr>
											<tr>
												<th>Email</th>
												<td>{userData ? userData.email: ""}</td>
												<th>Gender</th>
												<td>{userData ? userData.gender: ""}</td>
											</tr>

											<tr>
												<th>Telephone</th>
												<td>{userData ? userData.highest_qualification: ""}</td>
												<th>Address</th>
												<td>{userData ? userData.specialization: ""}</td>
											</tr>
											<tr>
												<th>Role</th>
												<td>{userData ? userData.role.name: ""}</td>
												<th>Branch</th>
												<td>{userData ? userData.branch.name: ""}</td>
											</tr>

											<tr>
												<th>Date of Birth</th>
												<td>{userData ? userData.date_of_birth: ""}</td>
												<th>Date of Joining</th>
												<td>{userData ? userData.date_of_joining: ""}</td>
											</tr>
											
											<tr>
												<th>Created At</th>
												<td>{userData ? userData.created_at: ""}</td>
												<th>Created By</th>
												<td></td>
											</tr>
										</tbody>
									</table>

									{userData ? 
										<table className='table table-bordered mt-3'>
											<thead>
												<tr>
													<th>Address</th>
													<th>City</th>
													<th>State</th>
													<th>Pincode</th>
												</tr>
											</thead>
											{userData.address.length > 0 ? 
											<tbody>											
											{userData.address.map((info, i) => (
												<tr>
													<td>{info.address}</td>
													<td>{info.city}</td>
													<td>{info.state}</td>
													<td>{info.pincode}</td>
												</tr>
												))}
											</tbody>
											: "<div>User address not found</div>"
										}
										</table>
										: "<div>User address not found</div>"
									}

								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
  )
}
