import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
// import SelectField from '../../components/SelectField';

import { accessToken } from '../../components/ValidationRegex';

import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';
// import { apiBaseUrl } from  '../components/Constant';


export default function BranchAdd() {

	const { id } = useParams()

	const intialFormValues = {
		"code": "",
		"name": "",
		"telephone": "",
		"mobile": "",
		"email": "",
		"address": "",
		"state": "",
		"city": "",
		"pincode": "",
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");

	const [cityList, setCityList] = useState([]);
	const [stateList, setStateList] = useState([]);


	const handleChange = (e) => {
		console.log("e.target ", e.target);
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}


	const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.code == "") {
			errors.code = "This field is required";
		}

		if (formDataObject.name == "") {
			errors.name = "This field is required";
		}

		if (formDataObject.telephone == "") {
			errors.telephone = "This field is required";
		}

		if (formDataObject.mobile == "") {
			errors.mobile = "This field is required";
		}

		if (formDataObject.email == "") {
			errors.email = "This field is required";
		}

		if (formDataObject.address == "") {
			errors.address = "This field is required";
		}

		if (formDataObject.state == "") {
			errors.state = "This field is required";
		}

		if (formDataObject.city == "") {
			errors.city = "This field is required";
		}

		if (formDataObject.pincode == "") {
			errors.pincode = "This field is required";
		}

		return errors;
	}

	const handleBrandFormSubmit = (e) => {
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	} 

	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".branch_add_form");
			const formdata = new FormData(formObject);

			let method = "POST";
			let url = apiBaseUrl+ "api/core/branch";

			if (id) {
				method = "PATCH";
				url = apiBaseUrl+ "api/core/branch/"+id;
			}

			axios({
                url: url,
                method: method,
                headers: {authorization: "Bearer "+ accessToken},
                data: formdata,
            }).then((res) => { 
				if (id) {
					setFormSubmitError("Branch added successfully.");
					// setFormValues(intialFormValues);	
				} else {
					setFormSubmitError("Branch details updated successfully.");
					setFormValues(intialFormValues);	
				}


			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail'])
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormError(err.response.data);
				}
			});

		}
	}, [formErrors]);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/state",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: formValues,
		}).then((res) => { 
			var data = res.data;
			setStateList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/city",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: formValues,
		}).then((res) => { 
			var data = res.data;
			setCityList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		if (id) {
			axios({
				url: apiBaseUrl+ "api/core/branch/"+id,
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				data: formValues,
			}).then((res) => { 
				var data = res.data;
				console.log("Data at Branch ", data);
				setFormValues(data.data);
			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, []);



  return (
    <div className="container-fluid container-application">
        
        <div className="sidenav show" id="sidenav-main">
            <Header></Header>
            <Sidebar></Sidebar>
        </div>
        
        <div className="main-content position-relative">
            <Navbar></Navbar>
            
            <div className="page-content">
              <div className="row justify-content-center">

          <div className="col-lg-12">
            
            <PageTitle pageName={id ? "Edit Branch":"Add New Branch"} link_to="branch-list" link_name="View All Branch"></PageTitle>

              <form className="mt-4 branch_add_form" onSubmit={handleBrandFormSubmit}>
                <div className="card">
                  <div className="card-body">
					{formSubmitError && <div class="alert alert-success alert-dismissible">{formSubmitError}</div>}
                    <div className="row">
					  
						<div className="col-md-6">
							<div className="form-group">
								<label className="form-control-label">Branch Code</label>
								<input className="form-control" type="text"  value={formValues.code} onChange={handleChange} name="code"  
									placeholder="Enter Branch Code" />
								<span className='error_message'>{formErrors.code}</span>
							</div>
						</div>

						<div className="col-md-6">
							<div className="form-group">
								<label className="form-control-label">Branch Name</label>
								<input className="form-control" type="text"  value={formValues.name} onChange={handleChange} name="name"  
									placeholder="Enter Branch Name" />
								<span className='error_message'>{formErrors.name}</span>
							</div>
						</div>

						<div className="col-md-6">
							<div className="form-group">
								<label className="form-control-label">Telephone Number</label>
								<input className="form-control" type="text"  value={formValues.telephone} onChange={handleChange} name="telephone"  
									placeholder="Enter Telephone Name" />
								<span className='error_message'>{formErrors.telephone}</span>
							</div>
						</div>

						<div className="col-md-6">
							<div className="form-group">
								<label className="form-control-label">Mobile Number</label>
								<input className="form-control" type="text"  value={formValues.mobile} onChange={handleChange} name="mobile"  
									placeholder="Enter Mobile Name" />
								
								<span className='error_message'>{formErrors.mobile}</span>
							</div>
						</div>

						<div className="col-md-6">
							<div className="form-group">
								<label className="form-control-label">Branch Email</label>
								<input className="form-control" type="text"  value={formValues.email} onChange={handleChange} name="email"  
									placeholder="Enter Branch Email Address" />								
								<span className='error_message'>{formErrors.email}</span>
							</div>
						</div>

						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Branch Address</label>
								<input className="form-control" type="text"  value={formValues.address} onChange={handleChange} name="address"  
									placeholder="Enter Branch Address" />
								<span className='error_message'>{formErrors.address}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Branch State</label>
								<select className='form-control' name="state" value={formValues.state} onChange={handleChange}>
									<option value="">Select</option>
									{stateList.map((info, i) => (
										<option value={info.id}>{info.name}</option>
									))}
								</select>
								<span className='error_message'>{formErrors.state}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Branch City</label>
								<select className='form-control' name="city" value={formValues.city} onChange={handleChange}>
									<option value="">Select</option>
									{cityList.map((info, i) => (
										<option value={info.id}>{info.name}</option>
									))}
								</select>
								<span className='error_message'>{formErrors.city}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Branch Pincode</label>
								<input className="form-control" type="text"  value={formValues.pincode} onChange={handleChange} name="pincode"  
									placeholder="Enter Pincode" />
								<span className='error_message'>{formErrors.pincode}</span>
							</div>
						</div>

						<div className="col-md-12 ">
							<div className="text-right">
								<button type="submit" className="btn btn-primary rounded-pill">Submit</button>
							</div>
						</div>
                    </div>
                  </div>
                </div>
              </form>
          </div>
        </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
  )
}
