import React from 'react'
import { Link } from 'react-router-dom'

export default function PageTitle(props) {
  return (
    <div className="page-title">
        <div className="row justify-content-between align-items-center">
            <div className={props.link_not_required != "1"? "col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0": "col-md-12 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0"}>
                <div className="d-inline-block">
                <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">{props.pageName}</h5>
                </div>
            </div>

            {props.link_not_required != "1"? 
                <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
                    {props.isModal == "modal" ? (
                        <a href="#!" data-bs-toggle="modal" data-bs-target={props.link_to} className="btn btn-sm btn-white btn-icon rounded-pill">{props.link_name}</a>
                    ) : (
                        <Link to={"/"+props.link_to} className="btn btn-sm btn-white btn-icon rounded-pill">
                            <span className="btn-inner--icon"><i className="far fa-list"></i></span>
                            <span className="btn-inner--text">{props.link_name}</span>
                        </Link>
                    )}
                </div>
            :""}
        </div>
    </div>
  )
}
