import React, { useEffect, useState } from 'react'

import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import SelectField from '../../components/SelectField';
import { Navigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { accessToken } from '../../components/ValidationRegex';
import { apiBaseUrl } from '../../components/ConstantUtils';
import AppointmentForm from '../../components/AppointmentForm';

// import { apiBaseUrl } from  '../components/Constant';

export default function AppointmentAdd() {
	let { id } = useParams()

	// const [searchParams, setSearchParams] = useSearchParams();
	// const id = searchParams.get('id');

	const intialFormValues = {
		"branch": "",
		"doctor": "",
		"appointment_at": "",
		"about_disease": "",
		"non_field_errors": ""
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");
	const [branchList, setBranchList] = useState([]);
	const [doctorList, setDoctorList] = useState([]);


	
	const [patientSearchValue, setPatientSearchValue] = useState("");
	const [patientSearchResult, setpatientSearchResult] = useState([]);
	const [searchFieldError, setSearchFieldError] = useState("");
	const [isSearch, setIsSearch] = useState(false);


	const [isPatientSelected, setIsPatientSelected] = useState(false);
	const [selectedPatientIndex, setSelectedPatientIndex] = useState(-1)


	useEffect(() => {
		if (id) {
			axios({
				url: apiBaseUrl +"api/account/staff/"+id+"?user_type=2",
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				data: [],
			}).then((res) => { 
				var data = res.data.data;
				setPatientSearchValue(data.user.email)
				setIsSearch(true);
			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		} else {
			// setPatientSearchValue("")
		}
	}, [isSearch]);

	const getPatientList = () => {
		axios({
			url: apiBaseUrl +"api/core/patient?search="+patientSearchValue,
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			setpatientSearchResult(data)
			setIsPatientSelected(false);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}

	// Search Patient List
	useEffect(() => {
		if (isSearch) {
			getPatientList()
		} else {
			setpatientSearchResult([])
			setIsPatientSelected(false);
		}
	}, [isSearch]);
	
	useEffect(() => {
		if (isSearch) {
			getPatientList()
		} else {
			setIsSearch(false);
			setpatientSearchResult([])
			setIsPatientSelected(false);
		}
	}, [patientSearchValue,id]);

	
	

	const handlePatientSearchValue = (event) => {
		console.log("in search change event")
		if (event.target.value && event.target.value.length > 2 ) {
			setPatientSearchValue(event.target.value)
			setIsSearch(true);
		} else {
			setPatientSearchValue(event.target.value)
			// setIsSearch(true);
			setpatientSearchResult([]);
			setIsSearch(false);
			setIsPatientSelected(false);
		}

	}

	const handlePatientSearchButton = (event) => {
		if (! patientSearchValue) {
			setSearchFieldError("This field is required");
			setpatientSearchResult([]);
			setIsSearch(false);
		} else {
			setSearchFieldError("");
			setIsSearch(true);
		}
	} 

	const handlePatientSelection = (event) => {
		var index = event.target.attributes.getNamedItem('data-index').value;
		var name = event.target.attributes.getNamedItem('data-patient-name').value;
		var id = event.target.attributes.getNamedItem('data-patient-id').value;
		setSelectedPatientIndex(index);
		setIsPatientSelected(true)
	}


	return (
		<div className="container-fluid container-application">
			<div className="sidenav show" id="sidenav-main">
				<Header></Header>
				<Sidebar></Sidebar>
			</div>
			<div className="main-content position-relative">
				<Navbar></Navbar>
				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<PageTitle pageName="Add Appointment" link_to="appointment" link_name="View All Appointment's"></PageTitle>
							<div className="card">
								<div className="card-body">

									<div className='row'>
										<div className='col-md-12'>
											<div class="form-group">
												<label>Search Patient</label>
												<input type='search' onChange={handlePatientSearchValue} value={patientSearchValue} name="patient" className='form-control' placeholder='Enter Patient Name Or Mobile Number Or Email'/>
												<span className='error_message'>{searchFieldError}</span>
											</div>
										</div>
										{/* <div className='col-md-4 mt-4'>																					
											<button type='button' onClick={handlePatientSearchButton} className='btn btn-primary mt-2'>Search & Get Patient</button>
										</div> */}
									</div>

									
									{ !isPatientSelected ? 
										<div className="row"><br/>
											<div className='col-md-12'>
												<h6>Patient List</h6>
												<table className='table table-bordered'>
													<thead>
														<tr>
															<th>Patient Name</th>
															<th>Patient Email</th>
															<th>Patient Mobile Number</th>
															<th>Action</th>
														</tr>
													</thead>
													{patientSearchResult.length > 0 && patientSearchValue.length > 0 ? 
													<tbody className='background: red'>
														{patientSearchResult.map((info, i) => (
															<tr>
																<td>{info.user.name}</td>
																<td>{info.user.email}</td>
																<td>{info.user.mobile}</td>
																<td><button onClick={handlePatientSelection} data-index={i} data-patient-name={info.user.name} data-patient-id={info.user.id} type="button" className='btn btn-sm btn-primary'>Select</button></td>
															</tr>
														))}
													</tbody>
													: <tbody><tr><td colSpan={4} className='text-center'>{patientSearchValue == ""? "Please search to get patient list": <span className='text-warning'>Sorry, We are not found Patient with search details</span>}</td></tr></tbody>}
												</table>
											</div>
										</div>
									:""}



												
									{ isPatientSelected ? 
										<div>
											<div className="row mt-4 "><br/>
												<div className='col-md-6 '>
													<h6>Add Appointment for {patientSearchResult[selectedPatientIndex].user.name} ({patientSearchResult[selectedPatientIndex].age},{patientSearchResult[selectedPatientIndex].user.gender})</h6>													
													<AppointmentForm is_border_required={true} isPatientSelected={isPatientSelected} patient_id={patientSearchResult[selectedPatientIndex].user.id} />
												</div>
												<div className='col-md-6'>
													<h6>Chief Concern </h6>
													<table className='table table-bordered'>
														<tbody>
															<tr>
																<th>Dermatology Concerns</th>
																<td>{patientSearchResult[selectedPatientIndex].dermatology_concern}</td>
															</tr>
															<tr>
																<th>Any other Dermatology Concerns</th>
																<td>{patientSearchResult[selectedPatientIndex].other_dermatology_concern}</td>
															</tr>
															<tr>
																<th>Cosmetic Concerns</th>
																<td>{patientSearchResult[selectedPatientIndex].cosmetic_concern}</td>
															</tr>
															<tr>
																<th>Any other Cosmetic Concerns</th>
																<td>{patientSearchResult[selectedPatientIndex].other_cosmetic_concern}</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div> 
										</div>
									: ""}
								</div>
							</div>

						</div>
					</div>
				</div>
				<Footer></Footer>
			</div>
		</div>
	)
}
