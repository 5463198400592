import React, { useEffect, useState } from 'react'

import axios from 'axios';
import { accessToken } from '../components/ValidationRegex';
import { Navigate } from 'react-router-dom';
import { apiBaseUrl } from '../components/ConstantUtils';
import PatientTreatment from '../components/PatientTreatment';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function TreatmentHistory(props) {
	const [patientAppointmentList, setPatientAppointmentList] = useState()
	const [selectedAppointmentId, setAppointmentId] = useState()
	const [selectedPatientId, setPatientId] = useState()
	const [show, setShow] = useState(false);

    // Modal Window 
	const handleClose = () => setShow(false);
	const handleShow = (e) => {
		const appointmentId = e.target.getAttribute('data_appointment_id')
		const patientId = e.target.getAttribute('data_patient_id')
		setAppointmentId(appointmentId);
		setPatientId(patientId);
		setShow(true);
	}

    useEffect(() => {
		if (props.patient_id) {	
			axios({
				url: apiBaseUrl+ "api/book/patient-appointment?patient_id="+props.patient_id,
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				// data: {'patient_id': patientData.user.id},
			}).then((res) => { 
				var data = res.data.data;
				setPatientAppointmentList(data)
			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, []);


  return (
    <div>
        <div class="row">
            <div className='col-md-12'>
                
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Appointment Date</th>
                            <th>Patient</th>
                            <th>Doctor</th>
                            <th>Status</th>
                            <th>Have Prescription ?</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {patientAppointmentList ? patientAppointmentList.map((info) => (
                            <tr>
                                <td>{info.appointment_at}</td>
                                <td>{info.patient__name}</td>
                                <td>{info.doctor__name}</td>
                                <td>{info.status}</td>
                                <td>{info.prescription_appointment__id ? "Yes": "No"}</td>
                                <td><button onClick={handleShow} data_appointment_id={info.id} data_patient_id={info.patient} type="button" className='btn btn-xs btn-primary'>View Details</button></td>
                            </tr>
                        )): 
                            <tr>
                                <td colSpan={6}>Data Not Available</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
        
        <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title id = 'contained-modal-title-vcenter'>View Treatment Details</Modal.Title>
                <Button type='button' className='btn-close btn-xs btn-secondary' onClick={handleClose}>X</Button>
            </Modal.Header>
            <Modal.Body>
                <PatientTreatment patientId={selectedPatientId} appointmentId={selectedAppointmentId} />
            </Modal.Body>
        </Modal>


    </div>
  )
}
