import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { accessToken } from './ValidationRegex';
import { apiBaseUrl } from './ConstantUtils';
import axios from 'axios';

export default function BoostrapDatatable(props) {

  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
//   const [page_url, setPageUrl] = useState(props.page_url);

	useEffect(() => {
		if (props.isServerSide === true) {
			apiCall(1);
		}
	}, [search, props.page_url, props.isReload]);

	const apiCall = (pageNumber=1, count_per_page=10) => {
		let page_url = props.page_url
		if (!page_url) {
			// setPageUrl("api/inventory/medicine")
			page_url = "api/inventory/medicine"
		}
		
		let nextPageURL = apiBaseUrl + page_url +"?paginate=1&page="+pageNumber+"&count_per_page="+count_per_page;
		if (search) {
			nextPageURL += "&search="+search 
		}

		axios({
			url: nextPageURL+"&page="+pageNumber,
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data.results;
			
			var count = res.data.data.count;
			setTableData(data);
			setTotalRows(count);
		}).catch((err) => { 
			console.log("Got Error at Server side data table")
		});
	}

	const handlePageChange = (page) => {
		apiCall(page);
		setPageNumber(page)	
	};

	const handlePageLength = (count_per_page) => {
		apiCall(pageNumber, count_per_page)
	};

	const paginateOptions = {
		persistSelectedOnSort: true,
		persistSelectedOnPageChange: false
	}

	const handleServerSideSearch = (e) => {
		setSearch(e.target.value)		
	}

	
	if (props.isServerSide == true) {
		return (
			<DataTable 
			// fixedHeader 
			onChangeRowsPerPage={handlePageLength}
			onChangePage={handlePageChange}
			paginationTotalRows={totalRows}
			columns={props.tableColumns} 
			data={tableData}  
			progressPending={false}
			pagination 
			paginationPerPage={10} 
			paginationServer={true}
			PaginationServerOptions={paginateOptions}
			// fixedHeaderScrollHeight='1000'
			subHeader
			subHeaderComponent = {
				<input type='search' placeholder='Search' className='form-control w-25' value={search} onChange={handleServerSideSearch}/>       
			}     
			></DataTable>
		)
	} else {
		return (
			<DataTable 
			fixedHeader 
			columns={props.tableColumns} 
			data={props.tableRows}  
			pagination 
			paginationPerPage={15} 
			subHeader
			subHeaderComponent = {
				<input type='search' placeholder='Search' className='form-control w-25' value={search} onChange={(e) => setSearch(e.target.value)}/>       
			}     
			></DataTable>
		)
	}

  
}
