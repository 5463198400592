import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import axios from 'axios';
import { accessToken } from '../../components/ValidationRegex';
import { Navigate } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';
import ActionMenu from '../../components/ActionMenu';

// import { apiBaseUrl } from  '../components/Constant';

export default function UserList() {
	if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }

	const actionMenuItem = [
		{
			'link_name': 'View',
			'link_to': '/user-detail',
			'column': 'id',
			'url_param': true
		},
		{
			'link_name': 'Edit',
			'link_to': '/add-user',
			'column': 'id',
			'url_param': true
		},
		{
			'link_name': 'Change Password',
			'link_to': '/change-user-password',
			'column': 'id',
			'url_param': true
		}
	]
	

  	const tableColumns = [
		{
			"name": "Name",
			"selector": (row) => row.name,
			"sortable": true 
		},
		{
			"name": "Email",
			"selector": (row) => row.email,
			"sortable": true 
		},
		{
			"name": "Mobile No.",
			"selector": (row) => row.mobile,
			"sortable": true 
		},
		{
			"name": "Role",
			"selector": (row) => row.role,
			"sortable": true 
		},
		{
			"name": "Branch",
			"selector": (row) => row.branch_name,
			"sortable": true 
		},
		{
			"name": "Created At",
			"selector": (row) => row.created_at,
			"sortable": true 
		},
		{
			"name": "Action",
			"cell": (row) => <ActionMenu row_data={row} pk={row.id} actionMenuItem={actionMenuItem}></ActionMenu>
		},
	]

	const [tableRows, setTableRows] = useState([]);
	const [pageEndPoint, setPageEndpoint] = useState("api/account/staff")
	
    
	// useEffect(() => {
	// 	axios({
	// 		url: apiBaseUrl+ "api/account/staff",
	// 		method: "GET",
	// 		headers: {authorization: "Bearer "+accessToken},
	// 		data: [],
	// 	}).then((res) => { 
	// 		var data = res.data.data;
	// 		setTableRows(data);
	// 	}).catch((err) => { 
	// 		if (err.response.status == 401) {
	// 			//localStorage.removeItem("token");
	// 			return <Navigate to="/login" replace />;
	// 		}
	// 	});
	// }, []);


  return (
    <div className="container-fluid container-application">
		<div className="sidenav show" id="sidenav-main">
			<Header></Header>
			<Sidebar></Sidebar>
		</div>
    	<div className="main-content position-relative">
			<Navbar></Navbar>
			<div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<PageTitle pageName="All Staffs" link_to="add-user" link_name="Add Staff"></PageTitle>
						<div className="card mt-4">
						<div className="card-body card-stats">
							<div className="row">
							<div className="col-md-12">
								<BoostrapDatatable  page_url={pageEndPoint} isServerSide={true} tableColumns={tableColumns} tableRows={tableRows}></BoostrapDatatable>
							</div>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
  )

  
}
