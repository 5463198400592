import React from 'react'

export default function Footer() {
  return (
    <div className="footer pt-5 pb-4 footer-light" id="footer-main">
        <div className="row text-center text-sm-left align-items-sm-center">
          <div className="col-sm-6">
            <p className="text-sm mb-0">&copy; 2023 <a href="#" className="h6 text-sm" target="_blank">Skin & You Clinic</a>. All rights reserved.</p>
          </div>
        </div>
      </div>
  )
}
