import React, { useState } from 'react'
import { Link } from 'react-router-dom';

export default function ActionMenu(props) {

    const [isMenuOpen, setIsMenuOpen] = useState(false);    
    const handleDropdownMenu = (event) => {
        setIsMenuOpen(isMenuOpen ? false:true);
    }

    return (
        
        <div class="dropdown">
            {props.actionMenuItem && 
                <Link to={props.actionMenuItem[0].link_to+"/"+props.pk} type="button" class="btn btn-primary btn-sm menu_left_button">{props.actionMenuItem[0].link_name}</Link>
            }
            <button onClick={handleDropdownMenu} type="button" class="btn btn-primary btn-sm dropdown-toggle menu_right_button"  data-bs-toggle="dropdown"></button>
            <ul class={isMenuOpen ? 'dropdown-menu table_dropdown_menu show': 'dropdown-menu table_dropdown_menu'}>
                {props.actionMenuItem.map((item,i) => (
                    <li><Link to={item.link_to+"/"+props.pk} class="dropdown-item">{item.link_name}</Link></li>
                ))}
            </ul>
        </div>
    )
}
