import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import axios from 'axios';
import { accessToken } from '../../components/ValidationRegex';
import { Navigate } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';
import ActionMenu from '../../components/ActionMenu';

// import { apiBaseUrl } from  '../components/Constant';

export default function BranchList() {

	if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }

	const actionMenuItem = [
		{
			'link_name': 'View',
			'link_to': '/branch-detail',
			'column': 'id',
			'url_param': true
		},
		{
			'link_name': 'Edit',
			'link_to': '/add-branch',
			'column': 'id',
			'url_param': true
		},
		// {
		// 	'link_name': 'Delete',
		// 	'link_to': '/patient-delete',
		// 	'column': 'id',
		// 	'url_param': true
		// }
	]
	
	const tableColumns = [
		{
			"name": "Branch Code",
			"selector": (row) => row.code,
			"sortable": true 
		},
		{
			"name": "Branch Name",
			"selector": (row) => row.name,
			"sortable": true 
			
		},
		{
			"name": "Branch Contact",
			"selector": (row) => row.mobile,
			"sortable": true 
		},
		{
			"name": "Action",
			"cell": (row) => <ActionMenu row_data={row} pk={row.id} actionMenuItem={actionMenuItem}></ActionMenu>
		},
	]
	const [tableRows, setTableRows] = useState([]);

	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/branch",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data;

			console.log("res == ", res);
			setTableRows(data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);

	return (
    <div className="container-fluid container-application">
        
        <div className="sidenav show" id="sidenav-main">
            <Header></Header>
            <Sidebar></Sidebar>
        </div>
        
        <div className="main-content position-relative">
            <Navbar></Navbar>
            <div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<PageTitle pageName="All Branchs" link_to="add-branch" link_name="Add New Branch"></PageTitle>

						<div className="card mt-4">
							<div className="card-body card-stats">
								<div className="row">
									<div className="col-md-12">
										<BoostrapDatatable tableColumns={tableColumns} tableRows={tableRows}></BoostrapDatatable>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
            </div>
            <Footer></Footer>
        </div>
    </div>
  )
}
