import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { apiBaseUrl } from './ConstantUtils';
import { accessToken } from './ValidationRegex';
import { Navigate } from 'react-router';

export default function VendorForm(props) {
    
    const intialFormValues = {
		"name": "",
		"contact": "",
		"email": "",
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");


    const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}

    // Submit Vendor Form
	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".vendor_form");
			const formdata = new FormData(formObject);

            let url  = apiBaseUrl+ "api/core/vendor";
            let method = "POST";

            if (props.vendorId) {
                url = apiBaseUrl+ "api/core/vendor/"+props.vendorId;
                method = "PATCH";
            } 

			axios({
                url: url,
                method: method,
                headers: {authorization: "Bearer "+ accessToken},
                data: formdata,
            }).then((res) => { 
				if (props.vendorId) {
                    setFormSubmitError("Vendor has been updated successfully.");
                } else {
                    setFormSubmitError("Vendor has been added successfully.");
                    setFormValues(intialFormValues);
                }   
				
			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail']);					
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormError(err.response.data);
				}
			});
		}
	}, [formErrors]);


    useEffect(() => {
		if (props.vendorId) {
			axios({
				url: apiBaseUrl +"api/core/vendor/"+props.vendorId,
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				data: [],
			}).then((res) => { 
				var data = res.data.data;
                console.log("data ", data)
                setFormValues(data)
			}).catch((err) => { 
				if (err.response.status == 401) {
					return <Navigate to="/login" replace />;
				}
			});
		} else {
			setFormValues(intialFormValues);
		}
	}, [props.vendorId]);


    const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.name == "") {
			errors.name = "This field is required";
		}

        let nameRegex = /^[a-zA-Z ]{2,30}$/;
		if (!nameRegex.test(formDataObject.name)) {
			errors.name = "Please enter valid name";
		}


		if (formDataObject.email == "") {
			errors.email = "This field is required";
		}

		if (formDataObject.contact == "") {
			errors.contact = "This field is required";
		}

		return errors;
	}

    const handleAppointmentForm = (e) =>{
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	}


  return (
    <div>
        {formSubmitError && <div className='alert alert-success'>{formSubmitError}</div>}
        <form onSubmit={handleAppointmentForm} action='#!' method="post"  className={props.is_border_required ? 'border p-4 vendor_form': 'vendor_form'}>
            <div className='row'>
                
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="form-control-label">Name</label>
                        <input className="form-control" type="text"  value={formValues.name} onChange={handleChange} name="name"  
                            placeholder="Enter vendor name" />								
                        <span className='error_message'>{formErrors.name}</span>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label className="form-control-label">Email</label>
                        <input className="form-control" type="text"  value={formValues.email} onChange={handleChange} name="email"  
                            placeholder="Enter vendor email" />								
                        <span className='error_message'>{formErrors.email}</span>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label className="form-control-label">Contact No.</label>
                        <input className="form-control" type="text"  value={formValues.contact} onChange={handleChange} name="contact"  
                            placeholder="Enter vendor contact no." />								
                        <span className='error_message'>{formErrors.contact}</span>
                    </div>
                </div>

                <div className="col-md-12 d-none">
                    <div className="form-group">
                        <label className="form-control-label">Status</label>
                        <input className="form-control" type="text"  value="True" name="is_active"  />								
                    </div>
                </div>

            </div>

            <div className='row'>
                <div className="col-md-12">
                    {formErrors.non_field_errors && <p className='error_message mb-4'>{formErrors.non_field_errors ? "Vendor already exist": ""}</p>}
                    <div className="form-group text-right">
                        <button type="submit" className="btn btn-primary btn-sm" >Add Vendor</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
  )
}


