import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
// import SelectField from '../../components/SelectField';

import { accessToken } from '../../components/ValidationRegex';

import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';


export default function UserChangePassword() {
    const { id } = useParams()
	const [userData, setUserData] = useState();
	
    const intialFormValues = {
		"new_password": "",
		"confirm_password": ""
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");


    useEffect(() => {
		if (id) {
			axios({
				url: apiBaseUrl+ "api/account/staff/"+id+"?convert=fk",
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
			}).then((res) => { 
				var data = res.data;
				setUserData(data.data);
			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, []);

    const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}


	const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.new_password == "") {
			errors.new_password = "This field is required";
		}

		if (formDataObject.confirm_password == "") {
			errors.confirm_password = "This field is required";
		}

		return errors;
	}

	const handleChangePasswordFormSubmit = (e) => {
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	} 

	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".user_change_password");
			const formdata = new FormData(formObject);

			axios({
                url: apiBaseUrl+ "api/account/user/change-password/"+id,
                method: "POST",
                headers: {authorization: "Bearer "+ accessToken},
                data: formdata,
            }).then((res) => { 
				setFormSubmitError("Password has been changed successfully.");
				setFormValues(intialFormValues);
			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail'])
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {

                    console.log(err.response);
                    setFormSubmitError(err.response.data.data.detail);
                    setFormError(err.response.data);
				}
			});

		}
	}, [formErrors]);

  
    return (
        <div className="container-fluid container-application">
            
            <div className="sidenav show" id="sidenav-main">
                <Header></Header>
                <Sidebar></Sidebar>
            </div>
            
            <div className="main-content position-relative">
                <Navbar></Navbar>
                
                <div className="page-content">
                  <div className="row justify-content-center">
    
                    <div className="col-lg-12">
                
                        <PageTitle pageName="Change Password" link_to="dashboard" link_name="View Dashboard"></PageTitle>
                    
                        <div className="card">
                            <div className="card-body">     
                            <div className='row'>
                                <div className='col-md-5'>
                                    <form className="mt-4 user_change_password" onSubmit={handleChangePasswordFormSubmit}>
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-control-label">New Password</label>
                                                    <input className="form-control" type="password"  value={formValues.new_password} onChange={handleChange} name="new_password"  
                                                        placeholder="Enter new password" />
                                                    <span className='error_message'>{formErrors.new_password}</span>
                                                </div>
                                            </div>
                                        
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-control-label">Confirm Password</label>
                                                    <input className="form-control" type="password"  value={formValues.confirm_password} onChange={handleChange} name="confirm_password"  
                                                        placeholder="Enter confirm password" />								
                                                    <span className='error_message'>{formErrors.confirm_password}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                {formSubmitError && <div className="error_message">{formSubmitError}<br/></div>}
                                                    <div className="text-left mt-3">
                                                        <button type="submit" className="btn btn-primary rounded-pill">Change User Password</button>
                                                    </div>
                                                </div>
                                        </div>
                                    </form>
                                </div>

                                <div className='col-md-7'>
                                    <table className='table table-bordered table-striped'>
                                        <tbody>
                                            <tr>
                                                <th>Name</th>
                                                <td>{userData ? userData.name: ""}</td>
                                                <th>Mobile</th>
                                                <td>{userData ? userData.mobile: ""}</td>
                                            </tr>
                                            <tr>
                                                <th>Email</th>
                                                <td>{userData ? userData.email: ""}</td>
                                                <th>Gender</th>
                                                <td>{userData ? userData.gender: ""}</td>
                                            </tr>

                                            <tr>
                                                <th>Telephone</th>
                                                <td>{userData ? userData.highest_qualification: ""}</td>
                                                <th>Address</th>
                                                <td>{userData ? userData.specialization: ""}</td>
                                            </tr>
                                            <tr>
                                                <th>Role</th>
                                                <td>{userData ? userData.role.name: ""}</td>
                                                <th>Branch</th>
                                                <td>{userData ? userData.branch.name: ""}</td>
                                            </tr>

                                            <tr>
                                                <th>Date of Birth</th>
                                                <td>{userData ? userData.date_of_birth: ""}</td>
                                                <th>Date of Joining</th>
                                                <td>{userData ? userData.date_of_joining: ""}</td>
                                            </tr>
                                            
                                            <tr>
                                                <th>Created At</th>
                                                <td>{userData ? userData.created_at: ""}</td>
                                                <th>Created By</th>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                
                            </div>
                        </div>
                        
                    </div>
                  


              </div>
            </div>
                </div>
                <Footer></Footer>
            </div>
        </div>
      )

}
