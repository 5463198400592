import React, { useState } from 'react'
import ActionMenu from '../../components/ActionMenu';
import PageTitle from '../../components/PageTitle';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import VendorForm from '../../components/VendorForm';


export default function VendorList() {

	if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }
	
	const actionMenuItem = [
		{
			'link_name': 'Edit',
			'link_to': '/edit',
			'column': 'id',
			'url_param': false
		},
	]
	
  	const tableColumns = [
		{
			"name": "Name",
			"selector": (row) => row.name,
			"sortable": true 
		},
		{
			"name": "Email",
			"selector": (row) => row.email,
			"sortable": true 
		},
		{
			"name": "Contact No.",
			"selector": (row) => row.contact,
			"sortable": true 
		},
		{
			"name": "Creatd At",
			"selector": (row) => row.created_at,
			"sortable": true 
		},
		{
			"name": "Action",
			"cell": (row) => <button data-id={row.id} className='btn btn-primary btn-xs' onClick={handleAddVendor}>Edit</button>
		},
	]

	const [tableRows, setTableRows] = useState([]);

	const [vendorId, setVendorId] = useState();

    const page_url = "api/core/vendor"

	// Handle Boostrap Modal
	const [show, setShow] = useState(false);

	const handleClose = (e) => {
		setShow(false);
	} 
	

	const handleShow = () => setShow(true);

	const handleAddVendor = (e) => {
        setShow(true);
		const id = e.currentTarget.dataset.id;
		setVendorId(id)
    }


  return (
    <div className="container-fluid container-application">
		<div className="sidenav show" id="sidenav-main">
			<Header></Header>
			<Sidebar></Sidebar>
		</div>
    	<div className="main-content position-relative">
			<Navbar></Navbar>
			<div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">

						<div className="page-title">
							<div className="row justify-content-between align-items-center">
								<div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
									<div className="d-inline-block">
										<h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">All Vendors</h5>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
									<a href="#!" onClick={handleAddVendor} className="btn btn-sm btn-white btn-icon rounded-pill">Add Vendor</a>
								</div>	
							</div>
						</div>

						<div className="card mt-4">
							<div className="card-body card-stats">
								<div className="row">
									<div className="col-md-12">
										<BoostrapDatatable  isReload={show} page_url={page_url} isServerSide={true}  tableColumns={tableColumns} tableRows={tableRows}></BoostrapDatatable>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title id ='contained-modal-title-vcenter'>{vendorId ? "Edit Vendor": "Add Vendor"}</Modal.Title>
					<Button type='button' className='btn-close btn-xs btn-secondary' onClick={handleClose}>X</Button>
				</Modal.Header>
				<Modal.Body>
					<VendorForm vendorId={vendorId} />
				</Modal.Body>
			</Modal>


			<Footer></Footer>
		</div>
	</div>
  )
}
