import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { apiBaseUrl } from './ConstantUtils';
import { accessToken } from './ValidationRegex';
import { Navigate } from 'react-router';

export default function AppointmentForm(props) {
    
    let dateToBookAppointment = props.appointment_date;
    let endDateToBookAppointment = props.appointment_date;
    //console.log("dateToBookAppointment ", dateToBookAppointment)
    if (!dateToBookAppointment){
        dateToBookAppointment = ""
    } else {
        let d = new Date();
        let hour = d.getHours() < 10 ? "0"+d.getHours(): d.getHours();
        let minutes = d.getMinutes() < 10 ? "0"+d.getMinutes(): d.getMinutes();

        let time = " "+hour + ":"+minutes;
        dateToBookAppointment += time
    }

    if (!endDateToBookAppointment){
        endDateToBookAppointment = ""
    } else {
        let endDate = new Date();
        let endHour = (endDate.getHours()+1) < 10 ? "0"+(endDate.getHours()+1): (endDate.getHours()+1);
        let endMinutes = endDate.getMinutes() < 10 ? "0"+endDate.getMinutes(): endDate.getMinutes();

        let endTime = " "+endHour + ":"+endMinutes;
        endDateToBookAppointment += endTime
    }

    const intialFormValues = {
		"branch": "",
		"doctor": "",
		"appointment_at": dateToBookAppointment,
        "appointment_end_at": endDateToBookAppointment,
		"about_disease": "",
        "patient": props.patient_id ? props.patient_id: "",
		"non_field_errors": ""
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");

    const [isSubmitAppointmentForm, setIsSubmitAppointmentForm] = useState(false);


    const [branchList, setBranchList] = useState([]);
    const [doctorList, setDoctorList] = useState([]);
    const [patientList, setPatientList] = useState([]);

    const [appointmentDetail, setAppointmentDetail] = useState();

    const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}

    // Get Doctor List
	useEffect(() => {
		if (props.isPatientSelected) {
			axios({
				url: apiBaseUrl+ "api/account/staff?search=Doctor",
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				data: [],
			}).then((res) => { 
				var data = res.data.data;
				setDoctorList(data)
			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		} 
	}, [props.isPatientSelected]);
    
    // Branch List
	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/branch?records=all",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
		}).then((res) => { 
			console.log("res ", res);
			var data = res.data;
			setBranchList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);

    // Submit Appointment Form
	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".appointment_form");
			const formdata = new FormData(formObject);
            setIsSubmitAppointmentForm(true);

            let url = apiBaseUrl+ "api/book/appointment"
            let method = "POST"

            if (props.is_edit == true) {
                url = apiBaseUrl+ "api/book/appointment/"+props.appointment_id
                method = "PATCH"
            } 

			axios({
                url: url,
                method: method,
                headers: {authorization: "Bearer "+ accessToken},
                data: formdata,
            }).then((res) => { 
				
				if (props.is_edit == true) {
                    setFormSubmitError("The appointment has been updated successfully.");
                } else {
                    setFormSubmitError("The appointment has been booked successfully.");
                    setFormValues(intialFormValues);
                }
                

                setIsSubmitAppointmentForm(false);
                
			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail'])
                    setIsSubmitAppointmentForm(false);
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormError(err.response.data);
					// setFormSubmitError();
                    setIsSubmitAppointmentForm(false);
				}
			});
		}
	}, [formErrors]);


    useEffect(() => {
		if (!props.patient_id) {
            
			axios({
				url: apiBaseUrl +"api/core/patient",
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				data: [],
			}).then((res) => { 
				var data = res.data.data;
				setPatientList(data)
			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		} else {
			setPatientList([])
		}
	}, [props.patient_id]);


    const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.doctor == "") {
			errors.doctor = "This field is required";
		}

		if (formDataObject.branch == "") {
			errors.branch = "This field is required";
		}

		if (formDataObject.appointment_at == "") {
			errors.appointment_at = "This field is required";
		}

        if (formDataObject.appointment_end_at == "") {
			errors.appointment_end_at = "This field is required";
		}

		if (formDataObject.about_disease == "") {
			errors.about_disease = "This field is required";
		}


        if (!props.patient_id && formDataObject.patient == "") {
            errors.patient = "This field is required";
        }

		return errors;
	}

    const handleAppointmentForm = (e) =>{
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	}


    useEffect(() => {
		if (props.appointment_id) {
			axios({
				url: apiBaseUrl +"api/book/appointment/"+props.appointment_id,
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				data: [],
			}).then((res) => { 
				var data = res.data.data;
				// setAppointmentDetail(data)

                let start_date_split = data.appointment_at.split("/")
                let start_time_split = start_date_split[2].split(" ")
                let new_start_date = start_time_split[0]+"-"+start_date_split[1]+"-"+start_date_split[0] + " "+start_time_split[1]

                let end_date_split = data.appointment_end_at.split("/")
                let end_time_split = end_date_split[2].split(" ")
                let new_end_date = end_time_split[0]+"-"+end_date_split[1]+"-"+end_date_split[0] + " "+end_time_split[1]

                let form_input = {
                    "branch": data.branch.id,
                    "doctor": data.doctor.id,
                    "appointment_at": new_start_date,
                    "appointment_end_at": new_end_date,
                    "about_disease":data.about_disease,
                    "patient": data.patient.id ? data.patient.id: "",
                    "non_field_errors": ""
                };

                setFormValues(form_input)

			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		} else {
			setPatientList([])
		}
	}, [props.appointment_id]);


  return (
    <div>
        {formSubmitError && <div className='alert alert-success'>{formSubmitError}</div>}
        <form onSubmit={handleAppointmentForm} action='#!' method="post"  className={props.is_border_required ? 'border p-4 appointment_form': 'appointment_form'}>
            <div className='row'>
                {props.patient_id ? <input type='hidden' name="patient"  value={props.patient_id} />
                    :
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label">Select Patient </label>
                            <select className='form-control' name="patient" value={formValues.patient} onChange={handleChange}>
                                <option value="">Select</option>
                                {patientList.map((info, i) => (
                                    <option value={info.user__id}>{info.user__name}</option>
                                ))}
                            </select>
                            <span className='error_message'>{formErrors.patient}</span>
                        </div>
                    </div>
                }

                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-control-label">Select Branch </label>
                        <select className='form-control' name="branch" value={formValues.branch} onChange={handleChange}>
                            <option value="">Select</option>
                            {branchList.map((info, i) => (
                                <option selected value={info.id}>{info.name}</option>
                            ))}
                        </select>
                        <span className='error_message'>{formErrors.branch}</span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="form-control-label">Select Doctor </label>
                        <select className='form-control' name="doctor" value={formValues.doctor} onChange={handleChange}>
                            <option value="">Select</option>
                            {doctorList.map((info, i) => (
                                <option value={info.id}>{info.name}</option>
                            ))}
                        </select>
                        <span className='error_message'>{formErrors.doctor}</span>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="form-control-label">Appointment Start Date & Time</label>
                        <input className="form-control" type="datetime-local"  value={formValues.appointment_at} onChange={handleChange} name="appointment_at"  
                            placeholder="Enter Appointment start date and time" />								
                        <span className='error_message'>{formErrors.appointment_at}</span>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label className="form-control-label">Appointment End Date & Time</label>
                        <input className="form-control" type="datetime-local"  value={formValues.appointment_end_at} onChange={handleChange} name="appointment_end_at"  
                            placeholder="Enter Appointment end date and time" />								
                        <span className='error_message'>{formErrors.appointment_end_at}</span>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label className="form-control-label">About Patient Disease</label>
                        <input className="form-control" type="text"  value={formValues.about_disease} onChange={handleChange} name="about_disease"  
                            placeholder="Enter about patient disease" />								
                        <span className='error_message'>{formErrors.about_disease}</span>
                    </div>
                </div>

            </div>

            <div className='row'>
                <div className="col-md-12">
                    {formErrors.non_field_errors && <p className='error_message mb-4'>{formErrors.non_field_errors ? "The doctor already has an appointment with provided date and time": ""}</p>}
                    
                    {!isSubmitAppointmentForm ? 
                        <div className="form-group text-right">
                            {props.is_edit == true ? 
                                <button type="submit" className="btn btn-primary btn-sm" >Update Appointment</button>
                                : 
                                <button type="submit" className="btn btn-primary btn-sm" >Add Appointment</button>
                            }

                        </div>
                        : <div className="form-group text-right">
                            <button type="button" className="btn btn-info btn-sm" >Please Wait....</button>
                        </div>
                    }
                </div>
            </div>
        </form>
    </div>
  )
}


