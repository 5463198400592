import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/PageTitle';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl } from '../../components/ConstantUtils';
import { accessToken } from '../../components/ValidationRegex';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';

import Select2 from 'react-select2-wrapper';
import Alert from '../../components/AlertMessage';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import VendorForm from '../../components/VendorForm';
import Footer from '../../components/Footer';

export default function PurchaseAdd() {

    // Parent Form
    const intialParentFormValues = {
        "vendor": "",
        "invoice_date": "",
        "invoice_no": "",
        "invoice": "",
    };
    const [parentFormValues, setParentFormValues] = useState(intialParentFormValues);
    const [parentFormErrors, setParentFormError] = useState({});
    const [isSubmitParent, setIsSubmitParent] = useState(false);
    const [parentFormSubmitError, setParentFormSubmitError] = useState("");
    
    const intialFormValues = {
		"medicine": "",
		"medicine_name": "",
		"qty": "",
		"price": "",
		"description": "",
	};

	const [formValues, setFormValues] = useState(intialFormValues);
    const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");

	const [purchaseMedicineList, setPurchaseMedicineList] = useState([]);
	const [medicineList, setMedicineList] = useState();
	const [medicineListInKeyValue, setMedicineListInKeyValue] = useState();
    const [existingPrescriptionList, setExistingPrescriptionList] = useState([]);

    const [vendorList, setVendorList] = useState();

    // Handle Boostrap Modal
	const [show, setShow] = useState(false);
	const handleClose = (e) => {
		setShow(false);
	} 
	
	const handleShow = () => setShow(true);
	const handleAddVendor = (e) => {
        setShow(true);
    }



    useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/vendor",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
            var new_data = []
            for (var i =0; i < data.length; i++) {
				new_data.push({text: data[i]['name'], id: data[i]['id']});
			}
			setVendorList(new_data)
		}).catch((err) => { 
			if (err.response.status == 401) {
				return <Navigate to="/login" replace />;
			}
		});
	}, [show]);


    useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/inventory/medicine",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			var new_data = []
			var key_medicines = {}
			for (var i =0; i < data.length; i++) {
				new_data.push({text: data[i]['name'], id: data[i]['id']});
				key_medicines[data[i]['id']] = data[i]['name'];
			}
			setMedicineListInKeyValue(key_medicines);
			setMedicineList(new_data)
		}).catch((err) => { 
			if (err.response.status == 401) {
				return <Navigate to="/login" replace />;
			}
		});
	}, []);

    const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.medicine == "") {
			errors.medicine = "This field is required";
		}

		if (formDataObject.qty == "") {
			errors.qty = "This field is required";
		}

		if (formDataObject.price == "") {
			errors.price = "This field is required";
		}

		if (formDataObject.description == "") {
			errors.description = "This field is required";
		}

		return errors;
	}

    const handleFormSubmit = (e) => {
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	} 


    const validatePurchaseForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.vendor == "") {
			errors.vendor = "This field is required";
		}

		if (formDataObject.invoice_date == "") {
			errors.invoice_date = "This field is required";
		}

		if (formDataObject.invoice_no == "") {
			errors.invoice_no = "This field is required";
		}

        if (purchaseMedicineList.length == 0) {
            errors.non_field_error = "This field is required";
            setParentFormSubmitError("This field is required");
        }

		return errors;
	}


    const handlePurchaseFormSubmit = (e) => {
		e.preventDefault();
		setParentFormError(validatePurchaseForm(parentFormValues));
		setIsSubmitParent(true);
	} 


    // Submit Form to the servers
	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".purchase_item_form");
			const formData = new FormData(formObject);
			var object = {
				'created_by': localStorage.getItem('user')
			};

			formData.forEach(function(value, key){
    			object[key] = value;
			});

			purchaseMedicineList.push(object);
			setPurchaseMedicineList(purchaseMedicineList);
			setFormValues(intialFormValues);
			setIsSubmit(false);
			setFormError({});
		}
	}, [formErrors]);


    // Submit Form to the servers
	useEffect(() => {
		if (Object.keys(parentFormErrors).length == 0 && isSubmitParent){
			const formObject = document.querySelector(".purchase_form");
			let formData = new FormData(formObject);
			formData.append('created_by', localStorage.getItem('user'));
            formData.append('purchase_item', JSON.stringify(purchaseMedicineList))

			axios({
                url: apiBaseUrl+ "api/inventory/purchase",
                method: "POST",
                headers: {
					authorization: "Bearer "+ accessToken,
					'Content-Type': 'multipart/form-data'
				},
                data: formData,
            }).then((res) => { 
                console.log("Response === ", res);
                setPurchaseMedicineList([]);
                setFormValues(intialFormValues);
                setParentFormValues(intialParentFormValues);
                setParentFormSubmitError("Purchase detail added successfully.")

			}).catch((err) => { 
				if (err.response.status == 401) {
					setParentFormSubmitError(err['response']['data']['detail'])
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					// setTreatmentFormError(err.response.data);
					// // setFormSubmitError();

                    // print
				}
			});
		}
	}, [parentFormErrors]);

    const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}

    const handlePurchaseChange = (e) => {
		const {name,value} = e.target;
		setParentFormValues({...parentFormValues, [name]: value});
	}

    const deletePurchaseItem = (e) => {
        let dataIndex = parseInt(e.target.getAttribute("data-index"));
        let new_purchase_list = []
        for (var i = 0; i < purchaseMedicineList.length; i++) {
            if (i != dataIndex) {
                new_purchase_list.push(purchaseMedicineList[i]);
            }
        }
        // purchaseMedicineList,    
        setPurchaseMedicineList(new_purchase_list);
    }

    

    return (
        <div className="container-fluid container-application">
            <div className="sidenav show" id="sidenav-main">
                <Header></Header>
                <Sidebar></Sidebar>
            </div>
            <div className="main-content position-relative">
                <Navbar></Navbar>
                <div className="page-content">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className='row mb-3'>
                                <div className='col-md-7'>
                                    <PageTitle link_not_required="1" pageName="Add Purchase Details" link_to="purchase-list" link_name="View All Purchase"></PageTitle>
                                </div>
                                <div className='col-md-5 text-right'>
                                    <button onClick={handleAddVendor} className="btn btn-sm btn-white btn-icon rounded-pill">
                                        <span className="btn-inner--icon"><i className="fa fa-building"></i></span>
                                        <span className="btn-inner--text">Add Vendor</span>
                                    </button>	

                                    <Link to="/purchase-list" className="btn btn-sm btn-white btn-icon rounded-pill">
                                        <span className="btn-inner--icon"><i className="far fa-list"></i></span>
                                        <span className="btn-inner--text">View All Purchase</span>
                                    </Link>								

                                </div> 
                            </div>
                            
                            <div className="card">
                                <div className="card-body">
                                    <div className="container tab-pane tab_content">
                                        <form className='purchase_form'>
                                            <Alert alert_message={parentFormSubmitError} />

                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <label>Select Vendor</label>
                                                    <Select2 name="vendor" value={parentFormValues.vendor} onChange={handlePurchaseChange} 
                                                        data={vendorList ? vendorList: []}
                                                        options={{
                                                                placeholder: 'Select Vendor',
                                                            }}
                                                    />
                                                    <span className='error_message'>{parentFormErrors.vendor}</span>
                                                </div>

                                                <div className='col-md-3'>
                                                    <label>Invoice Date</label>
                                                    <input value={parentFormValues.invoice_date} onChange={handlePurchaseChange} className='form-control' name="invoice_date" type='date'/>
                                                    <span className='error_message'>{parentFormErrors.invoice_date}</span>
                                                </div>

                                                <div className='col-md-3'>
                                                    <label>Invoice No.</label>
                                                    <input value={parentFormValues.invoice_no} onChange={handlePurchaseChange} className='form-control' name="invoice_no" />
                                                    <span className='error_message'>{parentFormErrors.invoice_no}</span>
                                                </div>

                                                <div className='col-md-3'>
                                                    <label>Upload purchase invoice</label>
                                                    <input className='form-control' value={parentFormValues.invoice} onChange={handlePurchaseChange} type="file" name="invoice" />
                                                    {/* <span className='error_message'>{formErrors.file}</span> */}
                                                </div>


                                            </div>

                                            <div className='row'>                                            
                                                <div className='col-md-12'>
                                                    <form className="mt-3 purchase_item_form" action='#!'>
                                                        <div className='row'>
                                                            <div className='col-md-3 form-group tab_content_form'>
                                                                <label>Select Medicine</label>
                                                                <Select2 name="medicine" value={formValues.medicine} onChange={handleChange} 
                                                                    data={medicineList ? medicineList: []}
                                                                    options={{
                                                                        placeholder: 'Select Medicine',
                                                                    }}
                                                                />
                                                                <span className='error_message'>{formErrors.medicine}</span>
                                                            </div>
                                                                
                                                            <div className='form-group col-md-2 tab_content_form'>
                                                                <label>Qty</label>
                                                                <input type='number' onChange={handleChange} value={formValues.qty} className='form-control text-right' name="qty"  />
                                                                <span className='error_message'>{formErrors.qty}</span>
                                                            </div>

                                                            <div className='form-group col-md-2 tab_content_form'>
                                                                <label>Price</label>
                                                                <input type='number' onChange={handleChange} value={formValues.price} className='form-control text-right' name="price" />
                                                                <span className='error_message'>{formErrors.price}</span>
                                                            </div>

                                                            <div className='form-group col-md-3 tab_content_form'>
                                                                <label>Description</label>
                                                                <input type="text" onChange={handleChange} value={formValues.description} className='form-control' name="description" />
                                                                <span className='error_message'>{formErrors.description}</span>
                                                            </div>

                                                            <div className="form-group col-md-2 mt-3 tab_content_form">
                                                                <div className="text-left mt-4">
                                                                    <button type="button" onClick={handleFormSubmit} className="btn btn-primary btn-xs rounded-pill"><i className='fa fa-plus'></i> Add</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
    
                                                {purchaseMedicineList.length > 0 ? 
                                                <div className='col-md-12'>
                                                    {/* <h5 className='mt-4'>Medicine List</h5> */}
                                                    <table className='mt-4 table table-bordered bg-white'>
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">Medicine</th>
                                                                <th className="text-center">Qty</th>
                                                                <th className="text-center">Price</th>
                                                                <th className="text-center">Description</th>
                                                                <th className="text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {purchaseMedicineList.map((info, i) => (
                                                                <tr>
                                                                    <td className="text-center">{medicineListInKeyValue ? medicineListInKeyValue[info['medicine']]: ""}</td>
                                                                    <td className="text-center">{info['qty']}</td>
                                                                    <td className="text-center">{info['price']}</td>
                                                                    <td className="text-center">{info['description']}</td>
                                                                    <td className="text-center"><a href="#!" onClick={deletePurchaseItem} data-index={i}><i onClick={deletePurchaseItem} data-index={i} className='fa fa-trash'></i></a></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        
                                                        
                                                    </table>
                                                    <div className='text-right'>
                                                        <button type='button' onClick={handlePurchaseFormSubmit}  className='btn btn-primary mt-3 rounded-pill'>Submit</button>
                                                    </div>
                                                </div>
                                                : <div className='col-md-12 mt-6 text-center'>Items not added in Purchase Invoice.</div>}

                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title id ='contained-modal-title-vcenter'>Add Vendor</Modal.Title>
                        <Button type='button' className='btn-close btn-xs btn-secondary' onClick={handleClose}>X</Button>
                    </Modal.Header>
                    <Modal.Body>
                        <VendorForm />
                    </Modal.Body>
                </Modal>

                <Footer></Footer>

            </div>
        </div>
    )
}
