import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import axios from 'axios';
import { accessToken } from '../../components/ValidationRegex';
import { Link, Navigate } from 'react-router-dom';
import ActionMenu from '../../components/ActionMenu';
import { apiBaseUrl } from '../../components/ConstantUtils';


import Dropdown from 'react-bootstrap/Dropdown';



export default function AppointmentList() {

	if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }


  	const actionMenuItem = [
		{
			'link_name': 'View',
			'link_to': '/appointment-detail',
			'column': 'id',
			'url_param': true
		},
	]
	
  	const tableColumns = [
		{
			"name": "Appointment At",
			"selector": (row) => row.appointment_at,
			"sortable": true 
		},
		{
			"name": "Patient",
			"selector": (row) => row.patient,
			"sortable": true 
		},
		{
			"name": "Doctor",
			"selector": (row) => row.doctor,
			"sortable": true 
		},
		{
			"name": "Branch",
			"selector": (row) => row.branch,
			"sortable": true 
		},
		// {
		// 	"name": "Is Prescription?",
		// 	"cell": (row) => row.prescription.length > 0 ? <Link to={'/prescription/'+ row.prescription[0].prescription} className='text-warning'>View Prescription</Link>: <span>Not Created</span>,
		// 	"sortable": true 
		// },
		{
			"name": "Status",
			"selector": (row) => row.status,
			"sortable": true 
		},
		// {
		// 	"name": "Created At",
		// 	"selector": (row) => row.created_at,
		// 	"sortable": true 
		// },
		{
			"name": "Action",
			"cell": (row) => <Link to={"/appointment-detail/"+row.id} type="button" class="btn btn-primary btn-sm btn-xs">View</Link>
		},
	]

	const [tableRows, setTableRows] = useState([]);
	const [dropdownName, setDropdownName] = useState("Today's Appointments");
	const [dropdownValue, setDropdownValue] = useState("today");

	// useEffect(() => {
	// 	let url = apiBaseUrl+ "api/book/appointment-table/"+dropdownValue
	// 	if (dropdownValue == "all") {
	// 		url = apiBaseUrl+ "api/book/appointment"
	// 	}
	// 	axios({
	// 		url: url,
	// 		method: "GET",
	// 		headers: {authorization: "Bearer "+accessToken},
	// 		data: [],
	// 	}).then((res) => { 
	// 		var data = res.data.data;
	// 		setTableRows(data);
	// 	}).catch((err) => { 
	// 		if (err.response.status == 401) {
	// 			//localStorage.removeItem("token");
	// 			return <Navigate to="/login" replace />;
	// 		}
	// 	});
	// }, [dropdownValue]);



	const [pageEndPoint, setPageEndpoint] = useState("api/book/appointment-table/"+dropdownValue)
    useEffect(() => {
		setPageEndpoint("api/book/appointment-table/"+dropdownValue)
	}, [dropdownValue]);

    const handleDateDropdown = (e) => {
		setDropdownValue(e.target.getAttribute('data-value'));
		setDropdownName(e.target.getAttribute('data-menu-title'));
		setPageEndpoint("api/book/appointment-table/"+dropdownValue)
	}


  return (
    <div className="container-fluid container-application">
		<div className="sidenav show" id="sidenav-main">
			<Header></Header>
			<Sidebar></Sidebar>
		</div>
    	<div className="main-content position-relative">
			<Navbar></Navbar>
			<div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<div className='row'>
							<div className='col-md-6'>
								<PageTitle link_not_required="1" pageName="All Appointments" link_to="add-appointment" link_name="Add Appointment"></PageTitle>
							</div>
							<div className='col-md-6 text-right'>
								<Dropdown className='mr-3'>
									<Dropdown.Toggle  variant="success" className='btn btn-sm btn-white btn-icon rounded-pill' id="dropdown-basic">
										<i className='fa fa-filter'></i> {dropdownName}
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item onClick={handleDateDropdown} data-value="today" data-menu-title="Today's Appointments">Today's</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="tomorrow" data-menu-title="Tomorrow's Appointments">Tomorrow's</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="yesterday" data-menu-title="Yestderday's Appointments" >Yesterday</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="this_week" data-menu-title="This Week's Appointments" >This Week</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="this_month" data-menu-title="This Month's Appointments">This Month</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="all" data-menu-title="All Appointment">All</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>

								{/* <Dropdown className='mr-3'>
									<Dropdown.Toggle  variant="success" className='btn btn-sm btn-white btn-icon rounded-pill' id="dropdown-basic">
										<i className='fa fa-filter'></i> Select Doctor
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item onClick={handleDateDropdown} data-value="today" data-menu-title="Today's Appointments">Today's</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="tomorrow" data-menu-title="Tomorrow's Appointments">Tomorrow's</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="yesterday" data-menu-title="Yestderday's Appointments" >Yesterday</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="this_week" data-menu-title="This Week's Appointments" >This Week</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="this_month" data-menu-title="This Month's Appointments">This Month</Dropdown.Item>
										<Dropdown.Item onClick={handleDateDropdown} data-value="all" data-menu-title="All Appointment">All</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
													 */}
								<Link to="/add-appointment" className="btn btn-sm btn-white btn-icon rounded-pill">
									<span className="btn-inner--icon"><i className="far fa-list"></i></span>
									<span className="btn-inner--text">Add Appointment</span>
								</Link>
							</div>
						</div>
						

						<div className="card mt-4">
							<div className="card-body card-stats">
								<div className="row">
									<div className="col-md-12">
										<BoostrapDatatable param={dropdownValue} page_url={pageEndPoint} isServerSide={true} tableColumns={tableColumns} tableRows={tableRows}></BoostrapDatatable>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
  )
}
