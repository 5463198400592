import React from "react"

export const apiBaseUrl = "https://api.skinandyou.co.in/"
export const frontEndBaseUrl = "https://skinandyou.co.in/"

// export const apiBaseUrl = "http://localhost:8000/"

export const isProductionMode = true

export const FREQUENCYLIST  = [
    "Once Daily",
    "Twice Daily",
    "Three Times Daily",
    "Four Times Daily",
    "Every 30 Minutes",
    "Every Hour",
    "Every Eight Hours",
    "Every Ten Hours",
    "Every Twelve Hours",
    "Every Alternate Days",
    "Every Two Days",
    "Every Three Days",
    "Every Four Days",
    "Every Five Days",
    "Every Six Days",
    "Every Seven Days",
    "Every Week"
] 

export const DURATIONLIST  = ["Day(s)","Week(s)","Month(s)","Year(s)"] 


export const DATE_MAPPERS = {
    'today': {
        'title': "Today's Appointment",
        'start_date': new Date(),
        'end_date': new Date(),
    },
    'yesterday': {
        'title': "Yesterday's Appointment",
        'start_date': new Date(),
        'end_date': new Date(),
    },
    'this_week': {
        'title': "This Week's Appointment",
        'start_date': new Date(),
        'end_date': new Date(),
    },
    'this_month': {
        'title': "This Month's Appointment",
        'start_date': new Date(),
        'end_date': new Date(),
    }
}
