	import React, { useEffect, useState } from 'react'
	import Sidebar from '../../components/Sidebar';
	import Footer from '../../components/Footer';
	import Navbar from '../../components/Navbar';
	import Header from '../../components/Header' 

	import TextField from '../../components/TextField' 
	import PageTitle from '../../components/PageTitle';
	import DateField from '../../components/DateField';
	// import SelectField from '../../components/SelectField';

	import { accessToken } from '../../components/ValidationRegex';

	import axios from 'axios';
	import { Navigate, useParams } from 'react-router-dom';
	import { apiBaseUrl } from '../../components/ConstantUtils';
	// import { apiBaseUrl } from  '../components/Constant';


	export default function BranchEdit() {
		const { id } = useParams()
		const [branchData, setBranchData] = useState();

		useEffect(() => {
			if (id) {
				axios({
					url: apiBaseUrl+ "api/core/branch/"+id+"?convert=fk",
					method: "GET",
					headers: {authorization: "Bearer "+accessToken},
				}).then((res) => { 
					var data = res.data;
					setBranchData(data.data);
				}).catch((err) => { 
					if (err.response.status == 401) {
						//localStorage.removeItem("token");
						return <Navigate to="/login" replace />;
					}
				});
			}
		}, []);


	return (
		<div className="container-fluid container-application">
			
			<div className="sidenav show" id="sidenav-main">
				<Header></Header>
				<Sidebar></Sidebar>
			</div>
			
			<div className="main-content position-relative">
				<Navbar></Navbar>
				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<PageTitle pageName={branchData ? branchData.name: ""} link_to="branch-list" link_name="View Branch"></PageTitle>


							{/* <div class="row">
								<div class="col-lg-3">
									<div class="card card-stats">									
										<div class="card-body">
											<div class="row">
											<div class="col">
												<h6 class="text-muted mb-1">Today's Appointment</h6>
												<span class="h5 font-weight-bold mb-0">0</span>
											</div>
											<div class="col-auto">
												<div class="icon bg-gradient-warning text-white rounded-circle icon-shape">
												<i class="far fa-users-medical"></i>
												</div>
											</div>
											</div>
											<p class="mt-3 mb-0 text-sm">
											<span class="badge badge-soft-success mr-2"><i class="far fa-arrow-up"></i> 0</span>
											<span class="text-nowrap">Since last month</span>
											</p>
										</div>
									</div>
								</div>

								<div class="col-lg-3">
									<div class="card card-stats">									
										<div class="card-body">
											<div class="row">
											<div class="col">
												<h6 class="text-muted mb-1">New Patients</h6>
												<span class="h5 font-weight-bold mb-0">0</span>
											</div>
											<div class="col-auto">
												<div class="icon bg-gradient-warning text-white rounded-circle icon-shape">
												<i class="far fa-users-medical"></i>
												</div>
											</div>
											</div>
											<p class="mt-3 mb-0 text-sm">
											<span class="badge badge-soft-success mr-2"><i class="far fa-arrow-up"></i> 0%</span>
											<span class="text-nowrap">Since last month</span>
											</p>
										</div>
									</div>
								</div>



								<div class="col-lg-3">
									<div class="card card-stats">
										<div class="card-body">
											<div class="row">
											<div class="col">
												<h6 class="text-muted mb-1">Total Patients</h6>
												<span class="h5 font-weight-bold mb-0">2300</span>
											</div>
											<div class="col-auto">
												<div class="icon bg-gradient-dark text-white rounded-circle icon-shape">
												<i class="far fa-users-medical"></i>
												</div>
											</div>
											</div>
											<p class="mt-3 mb-0 text-sm">
											<span class="badge badge-soft-success mr-2"><i class="far fa-arrow-up"></i> 10%</span>
											<span class="text-nowrap">Since last month</span>
											</p>
										</div>
									</div>
								</div>

								<div class="col-lg-3">
									<div class="card card-stats">
										<div class="card-body">
											<div class="row">
												<div class="col">
													<h6 class="text-muted mb-1">Total OPD</h6>
													<span class="h5 font-weight-bold mb-0">1500</span>
												</div>
												<div class="col-auto">
													<div class="icon bg-gradient-warning text-white rounded-circle icon-shape">
														<i class="far fa-stretcher"></i>
													</div>
												</div>
											</div>
											<p class="mt-3 mb-0 text-sm">
												<span class="badge badge-soft-success mr-2"><i class="far fa-arrow-up"></i> 5.85%</span>
												<span class="text-nowrap">Since last month</span>
											</p>
										</div>
									</div>
								</div>

							</div> */}

							<div className="card mt-4">
								<div className="card-body card-stats">
									<div className="row">
										<div className="col-md-12">
											<table className='table table-bordered table-striped'>
												<tbody>
													<tr>
														<th>Branch Code</th>
														<td>{branchData ? branchData.code: ""}</td>
														<th>Name</th>
														<td>{branchData ? branchData.name: ""}</td>
													</tr>
													<tr>
														<th>Email</th>
														<td>{branchData ? branchData.email: ""}</td>
														<th>Mobile Number</th>
														<td>{branchData ? branchData.mobile: ""}</td>
													</tr>

													<tr>
														<th>Telephone</th>
														<td>{branchData ? branchData.telephone: ""}</td>
														<th>Address</th>
														<td>{branchData ? branchData.address: ""}</td>
													</tr>
													<tr>
														<th>State</th>
														<td>{branchData ? branchData.state: ""}</td>
														<th>City</th>
														<td>{branchData ? branchData.city: ""}</td>
													</tr>

													<tr>
														<th>Pincode</th>
														<td>{branchData ? branchData.pincode: ""}</td>
													</tr>
													
													<tr>
														<th>Created At</th>
														<td>{branchData ? branchData.created_at: ""}</td>
														<th>Created By</th>
														<td></td>
													</tr>

												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
				<Footer></Footer>
			</div>
		</div>

	)
	}
