import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { apiBaseUrl } from './ConstantUtils';
import { accessToken } from './ValidationRegex';
import { Navigate } from 'react-router';

export default function SubDiseaseForm(props) {

    const intialFormValues = {
		"name": "",
		"disease_for": ""
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");


    const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}

    // Submit Disease Form
	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".disease_form");
			const formdata = new FormData(formObject);
            let url  = apiBaseUrl+ "api/core/sub-disease";
            let method = "POST";

            if (props.diseaseId) {
                url = apiBaseUrl+ "api/core/sub-disease/"+props.diseaseId;
                method = "PATCH";
            } 

			axios({
                url: url,
                method: method,
                headers: {authorization: "Bearer "+ accessToken},
                data: formdata,
            }).then((res) => { 
				if (props.diseaseId) {
                    setFormSubmitError("Disease has been updated successfully.");
                } else {
                    setFormSubmitError("Disease has been added successfully.");
                    setFormValues(intialFormValues);
                }   
				
			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail']);					
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormError(err.response.data);
				}
			});
		}
	}, [formErrors]);


    useEffect(() => {
		if (props.diseaseId) {
			axios({
				url: apiBaseUrl +"api/core/sub-disease/"+props.diseaseId,
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				data: [],
			}).then((res) => { 
				var data = res.data;
                setFormValues(data)
			}).catch((err) => { 
				if (err.response.status == 401) {
					return <Navigate to="/login" replace />;
				}
			});
		} else {
			setFormValues(intialFormValues);
		}
	}, [props.diseaseId]);


    const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.name == "") {
			errors.name = "This field is required";
		}

		if (formDataObject.email == "") {
			errors.email = "This field is required";
		}

		if (formDataObject.contact == "") {
			errors.contact = "This field is required";
		}

		return errors;
	}

    const handleDiseaseForm = (e) =>{
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	}


  return (
    <div>
        {formSubmitError && <div className='alert alert-success'>{formSubmitError}</div>}
        <form onSubmit={handleDiseaseForm} action='#!' method="post"  className={props.is_border_required ? 'border p-4 disease_form': 'disease_form'}>
            <div className='row'>
                
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="form-control-label">Treatment Name</label>
                        <input className="form-control" type="text"  value={formValues.name} onChange={handleChange} name="name"  
                            placeholder="Enter Sub Disease Name" />
                        <span className='error_message'>{formErrors.name}</span>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="form-group">
                        <label className="form-control-label">Select Treatment Area</label>
                        <select name="disease_for" value={formValues.disease_for} onChange={handleChange} className="form-control">
                            <option value="">Select</option>
                            <option value="Face">Face</option>
                            <option value="Skin">Scars</option>
                            <option value="Body">Body</option>
                            <option value="Hair">Hair</option>
                            <option value="Laser">Laser</option>
                            <option value="Threadlift">Threadlift</option>
                            <option value="Inchloss / Body Shapping">Inchloss / Body Shapping</option>
                            <option value="Laser Hair Removal">Laser Hair Removal</option>
                        </select>
                        <span className='error_message'>{formErrors.disease_for}</span>
                    </div>
                </div>


            </div>

            <div className='row'>
                <div className="col-md-12">
                    {formErrors.non_field_errors && <p className='error_message mb-4'>{formErrors.non_field_errors ? "Disease already exist": ""}</p>}
                    <div className="form-group text-right">
                        {props.diseaseId ? 
                            <button type="submit" className="btn btn-primary btn-sm" >Update Disease</button>
                        : <button type="submit" className="btn btn-primary btn-sm" >Add Disease</button> }
                    </div>
                </div>
            </div>
        </form>
    </div>
  )
}
