import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { apiBaseUrl } from './ConstantUtils';
import { accessToken } from './ValidationRegex';
import { Navigate } from 'react-router';

export default function TreatmentForm(props) {
    console.log("props.treatment_detail.sub_disease ", props.treatment_detail.sub_disease)
    const intialFormValues = {
		"sub_disease": props.treatment_detail.sub_disease[0].id,
		"observation": props.treatment_detail.observation,
		"procedure_history": props.treatment_detail.medical_history,
		"medical_history": props.treatment_detail.procedure_history,
		"technician_name": props.treatment_detail.technician_name
	};

//     <tr>
//     <th className='width150px'>Sub Disease</th>
//     <td>
//         {treatmentDetail[0] ? treatmentDetail[0]['sub_disease'].map((info) => (
//             <div className='mt-2'>{info.name}</div>
//         )): ""}															
//     </td>
// </tr>
// <tr>
//     <th className='width150px'>Observation</th>
//     <td>{treatmentDetail[0] ? treatmentDetail[0]['observation']: ""}</td>
// </tr>
// <tr>
//     <th className='width150px'>Medical History</th>
//     <td>{treatmentDetail[0] ? treatmentDetail[0]['medical_history']: ""}</td>
// </tr>
// <tr>
//     <th className='width150px'>Procedure History</th>
//     <td>{treatmentDetail[0] ? treatmentDetail[0]['procedure_history']: ""}</td>
// </tr>

// <tr>
//     <th className='width150px'>Technician Name</th>
//     <td>{treatmentDetail[0] ? treatmentDetail[0]['technician_name']: ""}</td>
// </tr>


	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");
	const [isEditable, setIsEditable] = useState(false);

    const [isSubmitUploadImageForm, setIsSubmitUploadImageForm] = useState(false);

    const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}

    // Submit Upload Image Form
	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".treatment_form_edit");
			let formdata = new FormData(formObject);
            // formdata.append('appointment_id', props.appointment_id);
            // formdata.append('treatment_id', props.treatment_id);
            setIsSubmitUploadImageForm(true);

			axios({
                url: apiBaseUrl+ "api/book/appointment/treatment/"+props.treatment_id,
                method: "PATCH",
                headers:{
					authorization: "Bearer "+ accessToken,
					// 'Content-Type': 'multipart/form-data'
				},
                data: formdata,
            }).then((res) => { 				
                setFormSubmitError("The treatment has been updated successfully.");
                setFormValues(intialFormValues);
                setIsSubmitUploadImageForm(false);
			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail'])
                    setIsSubmitUploadImageForm(false);
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormError(err.response.data);
                    setIsSubmitUploadImageForm(false);
				}
			});
		}
	}, [formErrors]);



    const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.sub_disease == "") {
			errors.sub_disease = "This field is required";
		}

		if (formDataObject.observation == "") {
			errors.observation = "This field is required";
		}
		return errors;
	}

    const handleTreatmentForm = (e) =>{
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	}


    let disease_selected = []
    for (let i=0; i< props.treatment_detail.sub_disease.length; i++) {
        // setSelectedDise
        disease_selected.push(props.treatment_detail.sub_disease[i]['id'])        
    }

    const handleSelectedDisease = (e) =>{
		e.preventDefault();
        setIsEditable(true);
	}



  return (
    <div>
        {formSubmitError && <div className='alert alert-success'>{formSubmitError}</div>}
        <form onSubmit={handleTreatmentForm} action='#!' method="post"  className={props.is_border_required ? 'border p-4 treatment_form_edit': 'treatment_form_edit'}>
            <div className='row'>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label">Select Treatment</label>
                            {isEditable == true ? 
                                <div className='treatment_options_div'>
                                    {props.disease_list.map((info) => (
                                        <div class="form-check">
                                            <input class="form-check-input" value={info.id} type="checkbox"  name="sub_disease" />
                                            <label class="form-check-label">{info.text}</label>
                                        </div>
                                    ))}
                                </div> 
                            : 
                                <div className='treatment_options_div'>
                                    {props.disease_list.map((info) => (
                                        <div class="form-check">
                                            {
                                                disease_selected.includes(info.id) ? 
                                                <input class="form-check-input" value={info.id} type="checkbox"  name="sub_disease" onClick={handleSelectedDisease} checked/>
                                                : <input class="form-check-input" value={info.id} type="checkbox"  name="sub_disease" onClick={handleSelectedDisease} />
                                            }
                                            <label class="form-check-label">{info.text}</label>
                                        </div>
                                    ))}
                                </div> 
                            }
                            
                            <span className='error_message'>{formErrors.sub_disease}</span>
                        </div>
                    </div>




                    <div className="col-md-12">												
                        <div className="form-group">
                            <label className="form-control-label">Technician Name</label>
                            <input value={formValues.technician_name} onChange={handleChange} className="form-control" type="text" name="technician_name"/>
                            <span className='error_message'>{formErrors.technician_name}</span>
                        </div>
                        <span className='error_message'>{formErrors.technician_name}</span>
                    </div>
                
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label">Your Observation & Suggestion</label>
                            <input value={formValues.observation} onChange={handleChange} className="form-control" type="text" name="observation"/>
                            <span className='error_message'>{formErrors.observation}</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label">Medical History</label>
                            <input value={formValues.medical_history} onChange={handleChange} className="form-control" type="text" name="medical_history"/>
                            <span className='error_message'>{formErrors.medical_history}</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label">Procedure History</label>
                            <input value={formValues.procedure_history} onChange={handleChange} className="form-control" type="text" name="procedure_history"/>
                            <span className='error_message'>{formErrors.procedure_history}</span>
                        </div>
                    </div>
            </div>

            <div className='row'>
                <div className="col-md-12">
                    {formErrors.non_field_errors && <p className='error_message mb-4'>{formErrors.non_field_errors ? "Something went wrong ask development team to check this issues": ""}</p>}
                    
                    {!isSubmitUploadImageForm ? 
                        <div className="form-group text-right">
                            <button type="submit" className="btn btn-primary btn-sm" >Update Treatment Details</button>
                        </div>
                        : <div className="form-group text-right">
                            <button type="button" className="btn btn-info btn-sm" >Please Wait....</button>
                        </div>
                    }
                </div>
            </div>
        </form>
    </div>
  )
}


