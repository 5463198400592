import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import PageTitle from '../../components/PageTitle';
import { accessToken } from '../../components/ValidationRegex';

import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';
import Select2 from 'react-select2-wrapper';

export default function ProductAdd() {
	const { id } = useParams()

    const intialFormValues = {
		"medicine_category": "",
		"sku_code": "",
		"name": "",
		"content": "",
		"price": "",
		"opening_qty": ""
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");

	const [categoryList, setCategoryList] = useState();

	const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}

	const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.medicine_category == "") {
			errors.medicine_category = "This field is required";
		}

		if (formDataObject.sku_code == "") {
			errors.sku_code = "This field is required";
		}

		if (formDataObject.name == "") {
			errors.name = "This field is required";
		}

		if (formDataObject.content == "") {
			errors.content = "This field is required";
		}

		if (formDataObject.opening_qty == "") {
			errors.opening_qty = "This field is required";
		}

		if (formDataObject.price == "") {
			errors.price = "This field is required";
		}

		return errors;
	}

	const handleStaffFormSubmit = (e) => {
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	} 

	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".medicine_form");
			const formdata = new FormData(formObject);

			let method = "POST";
			let url = apiBaseUrl+ "api/inventory/medicine";

			if (id) {
				method = "PATCH";
				url = apiBaseUrl+ "api/inventory/medicine/"+id;
			}

			axios({
                url: url,
                method: method,
                headers: {authorization: "Bearer "+ accessToken},
                data: formdata,
            }).then((res) => { 
				if (id) {
					setFormSubmitError("Medince updated successfully.");
				} else {
					setFormSubmitError("Medince added successfully.");
					setFormValues(intialFormValues);
				}
			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail'])
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormError(err.response.data);
					setFormSubmitError("Unable to add medicine");
				}
			});

		}
	}, [formErrors]);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/inventory/medicine-category",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			// data: formValues,
		}).then((res) => { 
			var data = res.data.data;
			var new_data = []
            for (var i =0; i < data.length; i++) {
				new_data.push({text: data[i]['name'], id: data[i]['id']});
			}
			setCategoryList(new_data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		if (id) {	
			axios({
				url: apiBaseUrl+ "api/inventory/medicine/"+id+"?raw=1",
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
			}).then((res) => { 
				var data = res.data.data;
				setFormValues(data['medicine'])
			}).catch((err) => { 
				if (err.response.status == 401) {
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, []);

	


  return (
    <div className="container-fluid container-application">
        
        <div className="sidenav show" id="sidenav-main">
            <Header></Header>
            <Sidebar></Sidebar>
        </div>
        
        <div className="main-content position-relative">
            <Navbar></Navbar>
            
            <div className="page-content">
              <div className="row justify-content-center">

          <div className="col-lg-12">
            
            <PageTitle pageName={id ? "Edit Medicine":"Add Medicine"} link_to="product" link_name="View All Medicines"></PageTitle>

              <form className="mt-4 medicine_form" onSubmit={handleStaffFormSubmit}>
                <div className="card">
                  <div className="card-body">
					{formSubmitError && <div class="alert alert-success alert-dismissible">{formSubmitError}</div>}
                    <div className="row">
						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Medicine Category</label>
								<Select2 name="medicine_category" value={formValues.medicine_category} onChange={handleChange} 
									data={categoryList ? categoryList: []}
									options={{
											placeholder: 'Select Medicine Category',
										}}
								/>
								<span className='error_message'>{formErrors.medicine_category}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Name</label>
								<input className="form-control" type="text"  value={formValues.name} onChange={handleChange} name="name"  
									placeholder="Enter Medicine Name" />
								<span className='error_message'>{formErrors.name}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">SKU Code</label>
								<input className="form-control" type="text"  value={formValues.sku_code} onChange={handleChange} name="sku_code"  
									placeholder="Enter Medicine SKU Code" />
								<span className='error_message'>{formErrors.sku_code}</span>
							</div>
						</div>

						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Content</label>
								<input className="form-control" type="text"  value={formValues.content} onChange={handleChange} name="content"  
									placeholder="Enter about medicine content Address" />								
								<span className='error_message'>{formErrors.content}</span>
							</div>
						</div>	

						<div className="col-md-6">
							<div className="form-group">
								<label className="form-control-label">Price</label>
								<input className="form-control" type="text"  value={formValues.price} onChange={handleChange} name="price"  
									placeholder="Enter medicine price" />								
								<span className='error_message'>{formErrors.price}</span>
							</div>
						</div>	

						<div className="col-md-6">
							<div className="form-group">
								<label className="form-control-label">Opening Quntity</label>
								<input className="form-control" type="text"  value={formValues.opening_qty} onChange={handleChange} name="opening_qty"  
									placeholder="Enter medicine opening quantity" />								
								<span className='error_message'>{formErrors.opening_qty}</span>
							</div>
						</div>	


						<div className="col-md-12 ">
							<div className="text-right">
								<button type="submit" className="btn btn-primary rounded-pill">Submit</button>
							</div>
						</div>
						

					</div>

                  </div>
                </div>
              </form>
          </div>
        </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
  )
}
