import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import axios from 'axios';
import { accessToken } from '../../components/ValidationRegex';
import { Navigate, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';

// import { apiBaseUrl } from  '../components/Constant';

export default function PatientAdd() {
    
	const intialFormValues = {
		"age": "",
		"name": "",
		"medical_record_no": "",
		// "date_of_birth": "",
		"gender": "",
		"mobile": "",
		"email": "",
		"address": "",
		"state": "",
		"city": "",
		"pincode": "",
		"has_smoking": "True",
		"has_alcoholic": "True",
		"has_pets": "True",
		"has_sunscreen_use": "True",
		"has_outdoor_activities": "True",
		"has_skin_care_activity": "True",
		"dermatology_concern": "",
		"other_dermatology_concern": "",
		"cosmetic_concern": "",
		"other_cosmetic_concern": "",
		"allergy_or_oral_contraceptive": "",
		"hair_complaints": "",
		"nail_compalints": "",
		"menstrual_child_birth_history": "",
		"weight_gain_loss_in_3_months": "",
		"diet": "",
		"excercise": "",
		"sleeping_habits": "",
	};

	const { id } = useParams()

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");

	const [isSubmittedPatientForm, setIsSubmittedPatientForm] = useState(false);

	const [cityList, setCityList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [branchList, setBranchList] = useState([]);

	const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}


	const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.age == "") {
			errors.age = "This field is required";
		}

		if (!id) {
			if (formDataObject.branch == "") {
				errors.branch = "This field is required";
			}
		}
		

		if (formDataObject.name == "") {
			errors.name = "This field is required";
		}

		let nameRegex = /^[a-zA-Z ]{2,30}$/;
		if (!nameRegex.test(formDataObject.name)) {
			errors.name = "Please enter valid name";
		}

		if (formDataObject.mobile == "") {
			errors.mobile = "This field is required";
		}

		if (formDataObject.email == "") {
			errors.email = "This field is required";
		}

		// if (formDataObject.date_of_birth == "") {
		// 	errors.date_of_birth = "This field is required";
		// }

		if (formDataObject.gender == "") {
			errors.gender = "This field is required";
		}
		if (formDataObject.medical_record_no == "") {
			errors.medical_record_no = "This field is required";
		}

		

		return errors;
	}

	const handleStaffFormSubmit = (e) => {
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
		window. scrollTo({ top: 0, left: 0, behavior: 'smooth' })
	} 

	useEffect(() => {
		if (id) {
			axios({
				url: apiBaseUrl+ "api/account/patient/"+id+"?user_type=2",
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				data: [],
			}).then((res) => { 
				var data = res.data.data;
				let address = "";
				let state = "";
				let city = "";
				let pincode = "";

				if (data['address'].length > 0) {
					address = data['address'][0]['address'];
					state = data['address'][0]['state'];
					city = data['address'][0]['city'];
					pincode = data['address'][0]['pincode'];
				}


				let info = {
					"age": data['age'],
					"name": data['user']['name'],
					// "date_of_birth": data['user']['date_of_birth'],
					"gender": data['user']['gender'],
					"mobile": data['user']['mobile'],
					"email": data['user']['email'],
					"address": address,
					"state": state,
					"city": city,
					"pincode": pincode,
					"medical_record_no": data['medical_record_no'],
					"has_smoking": data['has_smoking'],
					"has_alcoholic": data['has_alcoholic'],
					"has_pets": data['has_pets'],
					"has_sunscreen_use": data['has_sunscreen_use'],
					"has_outdoor_activities": data['has_outdoor_activities'],
					"has_skin_care_activity": data['has_skin_care_activity'],
					"dermatology_concern": data['dermatology_concern'],
					"other_dermatology_concern": data['other_dermatology_concern'],
					"cosmetic_concern": data['cosmetic_concern'],
					"other_cosmetic_concern": data['other_cosmetic_concern'],
					"allergy_or_oral_contraceptive": data['allergy_or_oral_contraceptive'],
					"hair_complaints": data['hair_complaints'],
					"nail_compalints": data['nail_compalints'],
					"menstrual_child_birth_history": data['menstrual_child_birth_history'],
					"weight_gain_loss_in_3_months": data['weight_gain_loss_in_3_months'],
					"diet": data['diet'],
					"excercise": data['excercise'],
					"sleeping_habits": data['sleeping_habits'],
				}
				setFormValues(info)				

			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, []);

	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".patient_form");
			const formdata = new FormData(formObject);
			setIsSubmittedPatientForm(true);

			let method = "POST"
			let url = apiBaseUrl+ "api/account/staff?user_type=2"

			if (id) {
				method = "PATCH"
				url = apiBaseUrl+ "api/account/patient/"+id
			}

			axios({
                url: url,
                method: method,
                headers: {authorization: "Bearer "+ accessToken},
                data: formdata,
            }).then((res) => { 
				if (id) {
					setFormSubmitError("Patient details updated successfully.");
					setFormValues(formValues);
				} else {
					setFormSubmitError("Patient details added successfully.");
					setFormValues(intialFormValues);
				}

				setIsSubmittedPatientForm(false);
				window. scrollTo({ top: 0, left: 0, behavior: 'smooth' })

			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail'])
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormError(err.response.data);
				}

				setIsSubmittedPatientForm(false);
				window. scrollTo({ top: 0, left: 0, behavior: 'smooth' })
			});

		}
	}, [formErrors]);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/state",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: formValues,
		}).then((res) => { 
			var data = res.data;
			setStateList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/city",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: formValues,
		}).then((res) => { 
			var data = res.data;
			setCityList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/branch?records=all",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: formValues,
		}).then((res) => { 
			var data = res.data;
			setBranchList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);

	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/branch?records=all",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: formValues,
		}).then((res) => { 
			var data = res.data;
			setBranchList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


  return (
    <div className="container-fluid container-application">
        
        <div className="sidenav show" id="sidenav-main">
            <Header></Header>
            <Sidebar></Sidebar>
        </div>
        
        <div className="main-content position-relative">
            <Navbar></Navbar>
            
            <div className="page-content">
              <div className="row justify-content-center">

          <div className="col-lg-12">
            
            <PageTitle pageName={id ? "Edit Patient" :"Add Patient"} link_to="patient" link_name="View All Patient"></PageTitle>

              <form className="mt-4 patient_form" onSubmit={handleStaffFormSubmit}>
                <div className="card">
                  <div className="card-body">
					{formSubmitError && <div class="alert alert-success alert-dismissible">{formSubmitError}</div>}

                    <div className="row">
						{/* <input type='hidden' value='False' name='is_staff'/> */}

 						{!id && 
							<div className="col-md-3">
								<div className="form-group">
									<label className="form-control-label">Select Branch </label>
									<select className='form-control' name="branch" onChange={handleChange}>
										<option value="">Select</option>
										{branchList.map((info, i) => (
											<option value={info.id}>{info.name}</option>
										))}
									</select>
									<span className='error_message'>{formErrors.branch}</span>
								</div>
							</div>
						}

						<div className="col-md-3">
							<div className="form-group">
								<label className="form-control-label">Name</label>
								<input className="form-control" type="text"  value={formValues.name} onChange={handleChange} name="name"  
									placeholder="Enter Name" />
								<span className='error_message'>{formErrors.name}</span>
							</div>
						</div>

						<div className="col-md-3">
							<div className="form-group">
								<label className="form-control-label">Mobile Number</label>
								<input className="form-control" type="text"  value={formValues.mobile} onChange={handleChange} name="mobile"  
									placeholder="Enter Mobile Name" />
								<span className='error_message'>{formErrors.mobile}</span>
							</div>
						</div>

						<div className="col-md-3">
							<div className="form-group">
								<label className="form-control-label">Email</label>
								<input className="form-control" type="text"  value={formValues.email} onChange={handleChange} name="email"  
									placeholder="Enter Email Address" />								
								<span className='error_message'>{formErrors.email}</span>
							</div>
						</div>

						{/* <div className="col-md-3">
							<div className="form-group">
								<label className="form-control-label">Date of Birth</label>
								<input className="form-control" type="date"  value={formValues.date_of_birth} onChange={handleChange} name="date_of_birth"  
									placeholder="Enter Date of Birth" />								
								<span className='error_message'>{formErrors.date_of_birth}</span>
							</div>
						</div> */}

						<div className="col-md-3">
							<div className="form-group">
								<label className="form-control-label">Age</label>
								<input className="form-control" type="number"  value={formValues.age} onChange={handleChange} name="age"  
									placeholder="Enter Age" />								
								<span className='error_message'>{formErrors.age}</span>
							</div>
						</div>

						<div className="col-md-3">
							<div className="form-group">
								<label className="form-control-label">Select Gender </label>
								<select className='form-control' name="gender" value={formValues.gender} onChange={handleChange}>
									<option value="">Select</option>
									<option value="Male">Male</option>
									<option value="Female">Female</option>
									<option value="Unknown">Unknown</option>
								</select>
								<span className='error_message'>{formErrors.gender}</span>
							</div>
						</div>

						<div className="col-md-3">
							<div className="form-group">
								<label className="form-control-label">Medical Record No.</label>
								<input className="form-control" type="number"  value={formValues.medical_record_no} onChange={handleChange} name="medical_record_no"  
									placeholder="Enter Medical Record No." />								
								<span className='error_message'>{formErrors.medical_record_no}</span>
							</div>
						</div>
						
					</div>

					<h5 className='mt-3'>Patient Address</h5>
					<div className='row'>
						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Address</label>
								<input className="form-control" type="text"  value={formValues.address} onChange={handleChange} name="address"  
									placeholder="Enter Address" />
								<span className='error_message'>{formErrors.address}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">State</label>
								<select className='form-control' name="state" value={formValues.state} onChange={handleChange}>
									<option value="">Select</option>
									{stateList.map((info, i) => (
										<option value={info.id}>{info.name}</option>
									))}
								</select>
								
								<span className='error_message'>{formErrors.state}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">City</label>
								<select className='form-control' name="city" value={formValues.city} onChange={handleChange}>
									<option value="">Select</option>
									{cityList.map((info, i) => (
										<option value={info.id}>{info.name}</option>
									))}
								</select>
								<span className='error_message'>{formErrors.city}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Pincode</label>
								<input className="form-control" type="text"  value={formValues.pincode} onChange={handleChange} name="pincode"  
									placeholder="Enter Pincode" />
								<span className='error_message'>{formErrors.pincode}</span>
							</div>
						</div>
					</div>

					<h5 className='mt-3'>Chief Complaints</h5>
					
					<div className='row'>
						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Dermatology Concerns</label>
								<input className="form-control" type="text" value={formValues.dermatology_concern} onChange={handleChange} name="dermatology_concern"  
									placeholder="Enter Dermatology Concerns" />
								<span className='error_message'>{formErrors.dermatology_concern}</span>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Any other Dermatology Concerns</label>
								<input className="form-control" type="text"  value={formValues.other_dermatology_concern} onChange={handleChange} name="other_dermatology_concern"  
									placeholder="Enter Any Other Dermatology Concerns" />
								<span className='error_message'>{formErrors.other_dermatology_concern}</span>
							</div>
						</div>
						
						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Cosmetic Concerns</label>
								<input className="form-control" type="text"  value={formValues.cosmetic_concern} onChange={handleChange} name="cosmetic_concern"  
									placeholder="Enter Cosmetic Concerns" />
								<span className='error_message'>{formErrors.cosmetic_concern}</span>
							</div>
						</div>

						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Any other Cosmetic Concerns</label>
								<input className="form-control" type="text"  value={formValues.other_cosmetic_concern} onChange={handleChange} name="other_cosmetic_concern"  
									placeholder="Enter Any Other Cosmetic Concerns" />
								<span className='error_message'>{formErrors.other_cosmetic_concern}</span>
							</div>
						</div>
					</div>

					<h5 className='mt-3'>Other Details</h5>
					<div className='row'>										
						<div className="col-md-2">
							<div className="form-group">
								<div class="form-check">
									{formValues.has_alcoholic == true ? 
									<input checked class="form-check-input" type="checkbox" onChange={handleChange}  name="has_alcoholic" value={formValues.has_alcoholic} />
									: 
									<input class="form-check-input" type="checkbox" onChange={handleChange}  name="has_alcoholic" value={formValues.has_alcoholic} />
									}
									
									<label class="form-check-label">Alcohal</label>
								</div>
								<span className='error_message'>{formErrors.has_alcoholic}</span>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<div class="form-check">
									{formValues.has_smoking == true ? 
										<input checked class="form-check-input" type="checkbox" onChange={handleChange}  name="has_smoking" value={formValues.has_smoking} />
									:
										<input class="form-check-input" type="checkbox" onChange={handleChange}  name="has_smoking" value={formValues.has_smoking} />
									}										
									
									<label class="form-check-label">Smoking</label>
								</div>
								<span className='error_message'>{formErrors.has_smoking}</span>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<div class="form-check">
								{formValues.has_sunscreen_use == true ? 
									<input checked class="form-check-input" type="checkbox" onChange={handleChange} name="has_sunscreen_use" value={formValues.has_sunscreen_use} />
								:
									<input class="form-check-input" type="checkbox" onChange={handleChange} name="has_sunscreen_use" value={formValues.has_sunscreen_use} />
								}	
									
									<label class="form-check-label">Sun Screen Use</label>
								</div>
								<span className='error_message'>{formErrors.has_sunscreen_use}</span>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<div class="form-check">
								{formValues.has_pets == true ? 
									<input checked class="form-check-input" type="checkbox" onChange={handleChange} name="has_pets" value={formValues.has_pets} />
									:
									<input class="form-check-input" type="checkbox" onChange={handleChange} name="has_pets" value={formValues.has_pets} />
								}
									
									<label class="form-check-label">Pets</label>
								</div>
								<span className='error_message'>{formErrors.has_pets}</span>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<div class="form-check">
								{formValues.has_outdoor_activities == true ? 
									<input checked class="form-check-input" type="checkbox" onChange={handleChange} name="has_outdoor_activities" value={formValues.has_outdoor_activities} />
									: 
									<input class="form-check-input" type="checkbox" onChange={handleChange} name="has_outdoor_activities" value={formValues.has_outdoor_activities} />
								}	
									
									<label class="form-check-label">Outdoor Activities</label>
								</div>
								<span className='error_message'>{formErrors.has_outdoor_activities}</span>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<div class="form-check">
								{formValues.has_skin_care_activity == true ? 
									<input checked class="form-check-input" type="checkbox" onChange={handleChange}  name="has_skin_care_activity" value={formValues.has_skin_care_activity} />
									: 
									<input class="form-check-input" type="checkbox" onChange={handleChange}  name="has_skin_care_activity" value={formValues.has_skin_care_activity} />
								}
									
									<label class="form-check-label">Skin Care Routine</label>
								</div>
								<span className='error_message'>{formErrors.has_skin_care_activity}</span>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Medications including non prescriptions allergy or oral contraceptive or ayurvedic pill</label>
								<input className="form-control" type="text" value={formValues.allergy_or_oral_contraceptive} onChange={handleChange} name="allergy_or_oral_contraceptive"  
									placeholder="Enter Dermatology Concerns" />
								<span className='error_message'>{formErrors.allergy_or_oral_contraceptive}</span>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Menstrual History / Childbirth History</label>
								<input className="form-control" type="text"  value={formValues.menstrual_child_birth_history} onChange={handleChange} name="menstrual_child_birth_history"  
									placeholder="Enter Menstrual History / Childbirth History" />
								<span className='error_message'>{formErrors.menstrual_child_birth_history}</span>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Hair Complaints</label>
								<input className="form-control" type="text"  value={formValues.hair_complaints} onChange={handleChange} name="hair_complaints"  
									placeholder="Enter hair Compalaints" />
								<span className='error_message'>{formErrors.hair_complaints}</span>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Nail Complaints</label>
								<input className="form-control" type="text"  value={formValues.nail_compalints} onChange={handleChange} name="nail_compalints"  
									placeholder="Enter nail Complaints" />
								<span className='error_message'>{formErrors.nail_compalints}</span>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Weight gain / loss in the last 3 months</label>
								<input className="form-control" type="text"  value={formValues.weight_gain_loss_in_3_months} onChange={handleChange} name="weight_gain_loss_in_3_months"  
									placeholder="Enter Weight Gain or Loss" />
								<span className='error_message'>{formErrors.weight_gain_loss_in_3_months}</span>
							</div>
						</div>

						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Diet</label>
								<input className="form-control" type="text"  value={formValues.diet} onChange={handleChange} name="diet"  
									placeholder="Enter Diet" />
								<span className='error_message'>{formErrors.diet}</span>
							</div>
						</div>

						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Excercise</label>
								<input className="form-control" type="text"  value={formValues.excercise} onChange={handleChange} name="excercise"  
									placeholder="Enter exercise" />
								<span className='error_message'>{formErrors.excercise}</span>
							</div>
						</div>

						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Sleeping Habits</label>
								<input className="form-control" type="text"  value={formValues.sleeping_habits} onChange={handleChange} name="sleeping_habits"  
									placeholder="Enter Sleeping Habits" />
								<span className='error_message'>{formErrors.sleeping_habits}</span>
							</div>
						</div>

					</div>	


					<div className='row'>
						<div className="col-md-12 ">
							<div className="text-right">
								{!isSubmittedPatientForm ?
									<button type="submit" className="btn btn-primary rounded-pill">Submit</button>
									: <button type="button" className="btn btn-info rounded-pill">Please wait....</button> 
								}
							</div>
						</div>
                    </div>
                  </div>
                </div>
              </form>
          </div>
        </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
  )
}
