import React, { useEffect, useState } from 'react'
import { Outlet,Link, Navigate } from "react-router-dom";

import Header from '../components/Header' 

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick

import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import HeaderSearchInput from '../components/HeaderSearchInput';

import { apiBaseUrl } from  '../components/ConstantUtils';
import axios from 'axios';
import { accessToken } from '../components/ValidationRegex';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AppointmentForm from '../components/AppointmentForm';




function renderEventContent(eventInfo) {
    let title_split = eventInfo.event.title.split("~~~");
    let status = title_split[0]
    let new_title = title_split[1]

    if (status == "Cancelled") {
        return (
            <>
              <i><s><Link to={"/appointment-detail/"+eventInfo.event.extendedProps.publicId}> {new_title} </Link></s></i>
            </>
          )
    } else {
        return (
            <>
              <i><Link to={"/appointment-detail/"+eventInfo.event.extendedProps.publicId}> {new_title} </Link></i>
            </>
          )
    } 
  }


export default function Dashboard() {

    if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }


    const [calendarItems, setCalendarItems] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [branchValue, setBranchValue] = useState("");
    const [branchId, setBranchId] = useState("");
    const [doctorId, setDoctorId] = useState("");
    const [doctorList, setDoctorList] = useState([]);

    const [dateToBookAppointment, setDateToBookAppointment] = useState([]);

    // Handle Boostrap Modal
    const [show, setShow] = useState(false);
    const [isReloadCalendar, setIsReloadCalendar] = useState(show);    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    // Get Calendar Data
	useEffect(() => {
        let url = apiBaseUrl+ "api/book/appointment?for=calendar"
        if (branchId != "" && branchId != -100) {
            url += "&branch_id="+branchId;
        }

        if (doctorId != "") {
            url += "&doctor_id="+doctorId;
        }

		axios({
			url: url,
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			setCalendarItems(data)
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, [branchId,doctorId,isReloadCalendar]);


    // Branch List
	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/branch?records=all",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
		}).then((res) => { 
			// console.log("res ", res);
			var data = res.data;
			setBranchList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);

    useEffect(() => {
        let url = apiBaseUrl+ "api/account/staff?seacrh=Doctor"
        if (branchId != "" && branchId != 0 ) {
            url = apiBaseUrl+ "api/account/staff?seacrh=Doctor&branch_id="+branchId
        }
		axios({
			url: url,
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
		}).then((res) => { 
			// console.log("Doctor res ", res);
			var data = res.data;
			setDoctorList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, [branchId]);



    const handleBranchChange = (e) => { 
        if (e.target.value >= 0) {
            setBranchValue(branchList[e.target.value].name);
            setBranchId(branchList[e.target.value].id);
        } else {
            setBranchValue("All Branchs");
            setBranchId("");
        }   
    }

    const handleUserChange = (e) => {
        // console.log('User Id ==  ', e.target.value)
        setDoctorId(e.target.value);
    }

    const handleInputChange = (e) => {
        // console.log("input Change function called");
    }

    let events = []
    if (calendarItems.length > 0) {
        calendarItems.map((info, i) => {
            // const appointment_date = new Date(info.appointment_at);
            // console.log("appointment_date ", info.appointment_at.substr(11, 5))

            let end_at = ""
            if (info.appointment_end_at) {
                end_at = "to "+info.appointment_end_at.substr(11, 5)
            }
            events.push({
                title: info.status+"~~~"+info.patient +" at "+info.appointment_at.substr(11, 5) +" " + end_at + " for "+ info.about_disease,
                start: info.appointment_at,
                end: info.appointment_end_at,
                publicId: info.id
            });
        });
        // events.push({
        //     title: "",
        //     start: new Date()
        // });

        // events = [
        //     { title: 'Meeting', start: new Date() },
        //     { title: 'Meeting 1', start: new Date() },
        //     { title: 'Meeting 2', start: new Date() }
        // ]
    }  

    const handleCalendarClick = (e) => {
        setShow(true);
        setDateToBookAppointment(e.dateStr)
        setIsReloadCalendar(true)
    }
    
  return (
    <div className="container-fluid container-application">
        <div className="sidenav show" id="sidenav-main">
            <Header></Header>
            <Sidebar></Sidebar>
        </div>
        
        <div className="main-content position-relative">
            <Navbar></Navbar>
            <HeaderSearchInput></HeaderSearchInput>

            <div className="page-content">
                <div className="page-title">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-3 d-flex align-items-center text-left">
                    
                        <a href="#" className="text-white pr-3" data-toggle="tooltip" data-placement="left" data-original-title="Go back"><i className="far fa-angle-left"></i></a>
                        <h5 className="fullcalendar-title h4 d-inline-block font-weight-400 mb-0 text-white">Calendar</h5>
                    </div>
                    <div className='col-md-3 text-center'>
                        <h5 className="h5 d-inline-block font-weight-400 mb-0 text-white m-auto"><i className="fas fa-map-marker-alt"></i> {branchValue == ""? "All Branchs": branchValue}</h5>
                    </div>



                    <div className="col-lg-6 mt-lg-0 text-lg-right">
                        <select className='form-control-sm' onChange={handleBranchChange}>
                            <option value="-100">All Branchs</option>
                            {branchList.map((info, i) => (
                                <option value={i}>{info.name}</option>
                            ))}
                        </select>
                        
                        <select className='form-control-sm ml-3' onChange={handleUserChange}>
                            <option value="">All Doctors</option>
                            {doctorList.map((info, i) => (
                                <option value={info.id}>{info.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col">
                    <div className="card overflow-hidden">
                    {/* <div className="calendar" data-toggle="calendar" id="calendar"></div> */}

                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView='dayGridDay'
                        weekends={true} 
                        events={events}
                        eventContent={renderEventContent}
                        headerToolbar= {{
                            left: 'prev,next',
                            center: 'title',
                            right: 'dayGridMonth,dayGridWeek,dayGridDay' // user can switch between the two
                        }}
                        dateClick={handleCalendarClick}
                    />

                    </div>
                    <div className="modal fade" id="new-appt" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">                  
                        <div className="modal-body">
                            <form className="new-appt--form">
                                <div className="form-group">
                                    <label className="form-control-label">Book an Appointment with Doctor</label>
                                    <input type="text"  onChange={handleInputChange}  className="form-control form-control-alternative new-appt--title" placeholder="Patient Name" />
                                </div>
                                <div className="form-group mb-0">
                                    <label className="form-control-label d-block mb-3">Status color</label>
                                    <div className="btn-group btn-group-toggle btn-group-colors event-tag" data-toggle="buttons">
                                    <label className="btn bg-info active"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-info" checked /></label>
                                    <label className="btn bg-warning"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-warning" /></label>
                                    <label className="btn bg-danger"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-danger" /></label>
                                    <label className="btn bg-success"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-success" /></label>
                                    <label className="btn bg-secondary"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-default" /></label>
                                    <label className="btn bg-primary"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-primary" /></label>
                                    </div>
                                </div>
                                <input onChange={handleInputChange} type="hidden" className="new-appt--start" />
                                <input onChange={handleInputChange} type="hidden" className="new-appt--end" />
                            </form>
                        </div>
                        
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-link" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-sm btn-primary rounded-pill float-right new-appt--add">Submit</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    
                    <div className="modal fade" id="edit-appt" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                        
                        <div className="modal-body">
                            <form className="edit-appt--form">
                            <div className="form-group">
                                <label htmlFor="patient-name" className="form-control-label">Book an Appointment with Doctor</label>
                                <input onChange={handleInputChange} id="patient-name" type="text" className="form-control form-control-alternative edit-appt--title" placeholder="Patient Name" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="treatment-decription" className="form-control-label">Treatment Description</label>
                                <textarea onChange={handleInputChange} id="treatment-decription" className="form-control form-control-alternative edit-appt--description textarea-autosize" placeholder="Treatment Desctiption"></textarea>
                                <i className="form-group--bar"></i>
                            </div>
                            <div className="form-group">
                                <label className="form-control-label d-block mb-3">Status color</label>
                                <div className="btn-group btn-group-toggle btn-group-colors event-tag mb-0" data-toggle="buttons">
                                <label className="btn bg-info active"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-info" checked /></label>
                                <label className="btn bg-warning"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-warning" /></label>
                                <label className="btn bg-danger"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-danger" /></label>
                                <label className="btn bg-success"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-success" /></label>
                                <label className="btn bg-secondary"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-default" /></label>
                                <label className="btn bg-primary"><input onChange={handleInputChange} type="radio" name="event-tag" value="bg-primary" /></label>
                                </div>
                            </div>
                            
                            </form>
                        </div>
                        
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-link" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-sm btn-primary rounded-pill float-right" data-calendar="update">Update</button>
                            <button type="button" className="btn btn-sm btn-danger rounded-pill float-right" data-calendar="delete">Delete</button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            
            <Footer></Footer>
        </div>


        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title id = 'contained-modal-title-vcenter'>Add Appointment</Modal.Title>
                <Button type='button' className='btn-close btn-xs btn-secondary' onClick={handleClose}>X</Button>
            </Modal.Header>
            <Modal.Body>
                <AppointmentForm is_border_required={false} appointment_date={dateToBookAppointment} isPatientSelected={true} />
                {/* patient_id={patientSearchResult[selectedPatientIndex].user.id} */}
            </Modal.Body>
        </Modal>

  </div>
  ) 
}
