import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { apiBaseUrl } from './ConstantUtils';
import { accessToken } from './ValidationRegex';
import { Navigate } from 'react-router-dom';


export default function PatientTreatment(props) {

    const [appointmentData, setAppointmentData] = useState()
    useEffect(() => {
		if (props.patientId && props.appointmentId) {	
			axios({
				url: apiBaseUrl+ "api/book/patient-treatment?patient_id="+props.patientId+"&appointment_id="+props.appointmentId,
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
			}).then((res) => { 
				var data = res.data.data;
				setAppointmentData(data)
			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, []);

    return (
        <div className='row'>

            {appointmentData ?               
                <>
                <div className='col-md-12'>
                    { appointmentData['images'].length > 0 ? 
                        <Carousel data-bs-theme="dark">      
                            {appointmentData['images'].map((info) => (
                                <Carousel.Item>
                                    <img className="d-block w-50" src={apiBaseUrl +""+info.image} alt="" />
                                </Carousel.Item>
                            )) }
                        </Carousel>
                    : 
                        <p className='text-center mt-3 mb-3'>Patient treatment images not uploaded with this appointment</p>
                    }
                </div>
            

            <div className='col-md-12 mt-5'>
                { appointmentData['prescription_item'].length > 0 ? 
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Medicine</th>
                                <th>Dose & Frequency</th>
                                <th>Course Duration</th>
                                <th>Instruction / Remark</th>                    
                            </tr>
                        </thead>
                        <tbody>       
                        {appointmentData['prescription_item'].map((info) => (
                            <tr>
                                <td>{info['medicine__name']}</td>
                                <td>{info['dose'] + " " +  info['frequency']}</td>
                                <td>{info['duration_count'] + " "+ info['duration_name']}</td>
                                <td>{info['instruction']}</td>
                            </tr> 
                        ))}
                        </tbody>
                    </table>
                    : <p className='text-center'>Prescription not available with this appointment</p> 
                }
            </div>
            </>  

        : ""}
        </div>
    )


  
}

