import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
// import SelectField from '../../components/SelectField';

import { accessToken, validName } from '../../components/ValidationRegex';

import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';

// import { apiBaseUrl } from  '../components/Constant';


export default function UserAdd() {
	const { id } = useParams()
    const intialFormValues = {
		"role": "",
		"branch": "",
		"name": "",
		"password": "",
		"date_of_birth": "",
		"date_of_joining": "",
		"highest_qualification": "",
		"specialization": "",
		"gender": "",
		"mobile": "",
		"email": "",
		"address": "",
		"state": "",
		"city": "",
		"pincode": "",
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");

	const [cityList, setCityList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [roleList, setRoleList] = useState([]);
	const [branchList, setBranchList] = useState([]);

	const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}


	const validateForm = (formDataObject) => {
		const errors = {};
		
		if (formDataObject.role == "") {
			errors.role = "This field is required";
		}

		if (formDataObject.branch == "") {
			errors.branch = "This field is required";
		}

		if (formDataObject.name == "") {
			errors.name = "This field is required";
		}

		let nameRegex = /^[a-zA-Z ]{2,30}$/;
		if (!nameRegex.test(formDataObject.name)) {
			errors.name = "Please enter valid name";
		}

		if (formDataObject.mobile == "") {
			errors.mobile = "This field is required";
		}

		if (formDataObject.email == "") {
			errors.email = "This field is required";
		}

		/*
		if (formDataObject.date_of_birth == "") {
			errors.date_of_birth = "This field is required";
		}

		if (formDataObject.date_of_joining == "") {
			errors.date_of_joining = "This field is required";
		}

		if (formDataObject.address == "") {
			errors.address = "This field is required";
		}

		if (formDataObject.state == "") {
			errors.state = "This field is required";
		}

		if (formDataObject.city == "") {
			errors.city = "This field is required";
		}

		if (formDataObject.pincode == "") {
			errors.pincode = "This field is required";
		}

		if (formDataObject.highest_qualification == "") {
			errors.highest_qualification = "This field is required";
		}

		if (formDataObject.specialization == "") {
			errors.specialization = "This field is required";
		}
		*/
		if (formDataObject.gender == "") {
			errors.gender = "This field is required";
		}
		
		if (formDataObject.password == "" && !id) {
			errors.password = "This field is required";
		}
	

		return errors;
	}

	const handleStaffFormSubmit = (e) => {
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	} 

	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".staff_form");
			const formdata = new FormData(formObject);

			let method = "POST";
			let url = apiBaseUrl+ "api/account/staff";

			if (id) {
				method = "PATCH";
				url = apiBaseUrl+ "api/account/staff/"+id;
			}

			axios({
                url: url,
                method: method,
                headers: {authorization: "Bearer "+ accessToken},
                data: formdata,
            }).then((res) => { 
				if (id) {
					setFormSubmitError("Staff updated successfully.");
				} else {
					setFormSubmitError("Staff added successfully.");
					setFormValues(intialFormValues);
				}
			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail'])
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormError(err.response.data);
				}
			});

		}
	}, [formErrors]);


	useEffect(() => {
		if (id) {
			axios({
				url: apiBaseUrl+ "api/account/staff/"+id+"?convert=fk&form=1",
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				data: formValues,
			}).then((res) => { 
				var data = res.data.data;
				var role_id = data['role'];
				data['role'] = role_id;
				data['branch'] = data.branch.id;

				let state = "";
				let city = "";
				let pincode = "";
				let address = "";
				
				if (data.address.length > 0) {
					state = data['address'][0]['state']
					city = data['address'][0]['city']
					pincode = data['address'][0]['pincode']
					address = data['address'][0]['address']
					
				}
				data['state'] = state;
				data['city'] = city;
				data['pincode'] = pincode;
				data['address'] = address;
				data['role'] = role_id;


				setFormValues(data);
			}).catch((err) => { 
				if (err.response.status == 401) {
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, []);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/state",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			// data: formValues,
		}).then((res) => { 
			var data = res.data;
			setStateList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/city",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			// data: formValues,
		}).then((res) => { 
			var data = res.data;
			setCityList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);

	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/role",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: formValues,
		}).then((res) => { 
			var data = res.data;
			setRoleList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);

	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/branch?records=all",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			// data: formValues,
		}).then((res) => { 
			var data = res.data;
			setBranchList(data.data);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


  return (
    <div className="container-fluid container-application">
        
        <div className="sidenav show" id="sidenav-main">
            <Header></Header>
            <Sidebar></Sidebar>
        </div>
        
        <div className="main-content position-relative">
            <Navbar></Navbar>
            
            <div className="page-content">
              <div className="row justify-content-center">

          <div className="col-lg-12">
            
            <PageTitle pageName={id ? "Edit Staff":"Add Staff"} link_to="user" link_name="View All Staff"></PageTitle>

              <form className="mt-4 staff_form" onSubmit={handleStaffFormSubmit}>
                <div className="card">
                  <div className="card-body">
					{formSubmitError && <div class="alert alert-success alert-dismissible">{formSubmitError}</div>}
                    <div className="row">
						<input type='hidden' value='True' name='is_staff'/>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Select Branch </label>
								<select className='form-control' name="branch" value={formValues.branch} onChange={handleChange}>
									<option value="">Select</option>
									{branchList.map((info, i) => (
										<option value={info.id}>{info.name}</option>
									))}
								</select>
								<span className='error_message'>{formErrors.branch}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Select Role </label>
								<select className='form-control' name="role" value={formValues.role} onChange={handleChange}>
									<option value="">Select</option>
									{roleList.map((info, i) => (
										<option value={info.id}>{info.name}</option>
									))}
								</select>
								<span className='error_message'>{formErrors.role}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Name</label>
								<input className="form-control" type="text"  value={formValues.name} onChange={handleChange} name="name"  
									placeholder="Enter Name" />
								<span className='error_message'>{formErrors.name}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Mobile Number</label>
								<input className="form-control" type="text"  value={formValues.mobile} onChange={handleChange} name="mobile"  
									placeholder="Enter Mobile Name" />
								<span className='error_message'>{formErrors.mobile}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Email</label>
								<input className="form-control" type="text"  value={formValues.email} onChange={handleChange} name="email"  
									placeholder="Enter Email Address" />								
								<span className='error_message'>{formErrors.email}</span>
							</div>
						</div>

						{!id && <div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Password</label>
								<input className="form-control" type="password"  value={formValues.password} onChange={handleChange} name="password"  
									placeholder="Enter Password" />								
								<span className='error_message'>{formErrors.password}</span>
							</div>
						</div>}

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Date of Birth</label>
								<input className="form-control" type="date"  value={formValues.date_of_birth} onChange={handleChange} name="date_of_birth"  
									placeholder="Enter Password" />								
								<span className='error_message'>{formErrors.date_of_birth}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Date of Joining</label>
								<input className="form-control" type="date"  value={formValues.date_of_joining} onChange={handleChange} name="date_of_joining"  
									placeholder="Enter Password" />								
								<span className='error_message'>{formErrors.date_of_joining}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Select Gender </label>
								<select className='form-control' name="gender" value={formValues.gender} onChange={handleChange}>
									<option value="">Select</option>
									<option value="Male">Male</option>
									<option value="Female">Female</option>
									<option value="Unknown">Unknown</option>
								</select>
								<span className='error_message'>{formErrors.gender}</span>
							</div>
						</div>

					</div>

					<div className='row'>
						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Highest Qualification</label>
								<input className="form-control" type="text"  value={formValues.highest_qualification} onChange={handleChange} name="highest_qualification"  
									placeholder="Enter Higher Education" />
								<span className='error_message'>{formErrors.highest_qualification}</span>
							</div>
						</div>
						

						<div className="col-md-6">
							<div className="form-group">
								<label className="form-control-label">Doctors specialization</label>
								<input className="form-control" type="text"  value={formValues.specialization} onChange={handleChange} name="specialization"  
									placeholder="Dermatologists, Allergists/Immunologists" />
								<span className='error_message'>{formErrors.specialization}</span>
							</div>
						</div>


						<div className="col-md-12">
							<div className="form-group">
								<label className="form-control-label">Address</label>
								<input className="form-control" type="text"  value={formValues.address} onChange={handleChange} name="address"  
									placeholder="Enter Address" />
								<span className='error_message'>{formErrors.address}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">State</label>
								<select className='form-control' name="state" value={formValues.state} onChange={handleChange}>
									<option value="">Select</option>
									{stateList.map((info, i) => (
										<option value={info.id}>{info.name}</option>
									))}
								</select>
								
								<span className='error_message'>{formErrors.state}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">City</label>
								<select className='form-control' name="city" value={formValues.city} onChange={handleChange}>
									<option value="">Select</option>
									{cityList.map((info, i) => (
										<option value={info.id}>{info.name}</option>
									))}
								</select>
								<span className='error_message'>{formErrors.city}</span>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label className="form-control-label">Pincode</label>
								<input className="form-control" type="text"  value={formValues.pincode} onChange={handleChange} name="pincode"  
									placeholder="Enter Pincode" />
								<span className='error_message'>{formErrors.pincode}</span>
							</div>
						</div>

						<div className="col-md-12 ">
							<div className="text-right">
								<button type="submit" className="btn btn-primary rounded-pill">Submit</button>
							</div>
						</div>
                    </div>
                  </div>
                </div>
              </form>
          </div>
        </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
  )
}
