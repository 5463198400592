import React, {useEffect, useState} from 'react'

import Image from '../components/image';
import EmailTextFieldWithIcon from '../components/emailTextFieldWithIcon';
import PasswordTextFieldWithIcon from '../components/passwordTextFieldWithIcon';
import ButtonWithIcon from '../components/buttonWithIcon';
import { Navigate } from 'react-router-dom';
import validator from "validator";

import { validEmail, validPassword } from '../components/ValidationRegex';
import axios from 'axios';
import { apiBaseUrl } from '../components/ConstantUtils';

export default function LoginPage() {

	const intialFormValues = {
		"email": "",
		"password": ""
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");
	const [isLoggedIn, setLoginStatus] = useState(false);
	const [access_token, setAccessToken] = useState("");

	const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}

	const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.email == "") {
			errors.email = "This field is required";
		} else 	if (!validator.isEmail(formDataObject.email)) {
			errors.email = "Please enter valid email address";
        } 

		if (formDataObject.password == "") {
			errors.password = "This field is required";
		}

		return errors;
	}

	const handleLoginForm = (e) => {
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	} 

	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".login_form");
			const formdata = new FormData(formObject);

			axios({
                url: apiBaseUrl+ "api/account/login",
                method: "POST",
                data: formdata,
            }).then((res) => { 
				var data = res.data;
				localStorage.setItem("isLoggedIn", true);
				localStorage.setItem("token", data['data']['access']);
				localStorage.setItem("name", data['data']['user_data']['name']);
            	localStorage.setItem("user", data['data']['user_data']['id']);
            	localStorage.setItem("branch_id", data['data']['user_data']['branch']['branch_id']);
            	localStorage.setItem("branch_code", data['data']['user_data']['branch']['branch_code']);
            	localStorage.setItem("branch_name", data['data']['user_data']['branch']['branch_name']);
            	localStorage.setItem("role", data['data']['user_data']['role']);
            	localStorage.setItem("specialization", data['data']['user_data']['specialization']);
            	localStorage.setItem("gender", data['data']['user_data']['gender']);

				setAccessToken(data['data']['access']);
				setFormValues(intialFormValues);
				setLoginStatus(true);
				// return <Navigate to="/Dashboard" />;
			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail'])
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormSubmitError(err['response']['data']['data']['error'])
					setFormError(err['response']['data']['data']['error']);
				}
			});
		}
	}, [formErrors]);


	
	if (isLoggedIn == true) {
		localStorage.setItem("token", access_token);
		return <Navigate to="/dashboard" replace />;
	}
	
	if (localStorage.getItem('token') != null && localStorage.getItem('token') != "") {
		return <Navigate to="/dashboard" replace />;
	}


  return (
    <div className="container-fluid container-application">
        <div className="main-content position-relative">
            <Image></Image>
            <div className="page-content">
              <div className="py-5 d-flex align-items-center">
                <div className="w-100">
                  <div className="row justify-content-center">
                    <div className="col-sm-8 col-lg-4">
                      <div className="card shadow zindex-100 mb-0">
                        <div className="card-body px-md-5 py-5">
                          <div className="mb-3">
                            <h6 className="h3">Login</h6>
                            <p className="text-muted mb-0">Sign in to your account to continue.</p>
                          </div>
                          <span className="clearfix"></span>
						  	
							<form onSubmit={handleLoginForm} className='login_form'>
								<div className="form-group">
									<label className="form-control-label">Email address</label>
									<div className="input-group input-group-merge">
										<div className="input-group-prepend">
											<span className="input-group-text"><i className="far fa-user"></i></span>
										</div>
										<input type="email" onChange={handleChange} value={formValues.email} className="form-control" name="email"  placeholder="user@domail.com" />
									</div>
									<span className='error_message'>{formErrors.email}</span>
								</div>
								<div className="form-group mb-4">
									<div className="d-flex align-items-center justify-content-between">
										<div>
											<label className="form-control-label">Password</label>
										</div>
									</div>
									<div className="input-group input-group-merge">
										<div className="input-group-prepend">
											<span className="input-group-text"><i className="far fa-key"></i></span>
										</div>
										<input type="password" onChange={handleChange} value={formValues.password} className="form-control" name="password"  placeholder="Password" />
										<div className="input-group-append">
											<span className="input-group-text">
												{/* <a href="#" data-toggle="password-text" data-target="#input-password">
													<i className="far fa-eye"></i>
												</a> */}
											</span>
										</div>
									</div>
									<span className='error_message'>{formErrors.password}</span>
								</div>

								<span className='error_message'>{formSubmitError}</span>
								<div className="mt-4">
									
									<button type="submit" className="btn btn-sm btn-primary btn-icon rounded-pill">
										<span className="btn-inner--text">Sign in</span>
										<span className="btn-inner--icon"><i className="far fa-long-arrow-alt-right"></i></span>
									</button>
								</div>

							</form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}
