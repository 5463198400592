import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import axios from 'axios';
import { accessToken, loggedInBranchId, loggedInUserId } from '../../components/ValidationRegex';
import { Link, Navigate, useParams } from 'react-router-dom';
import { DURATIONLIST, FREQUENCYLIST, apiBaseUrl, frontEndBaseUrl, isProductionMode } from '../../components/ConstantUtils';

import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';

import html2pdf from 'html2pdf.js/dist/html2pdf.min';

import ReactDOMServer from 'react-dom/server';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TreatmentHistory from '../../components/TreatmentHistory';
import { Collapse } from 'bootstrap';
import AppointmentForm from '../../components/AppointmentForm';

import UploadTreatmentImage from '../../components/UploadTreatmentImage'
import TreatmentForm from '../../components/TreatmentForm'

// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';


export default function AppointmentDetail() {
  	const { id } = useParams()
	const [isLoaded, setIsLoaded] = useState(false)
	const [patientData, setPatientData] = useState()
	const [isLoader, setLoader] = useState(false)

	const [isTreatmentFormSubmitted, setTreatmentFormSubmitted] = useState(false)
	const [isPrescriptionFormSubmitted, setPrescriptionFormSubmitted] = useState(false)

	const intialFormValues = {
		"medicine": "",
		"medicine_name": "",
		"dose": "",
		"frequency": "",
		"duration_count": "",
		"duration_name": "",
		"instruction": ""
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");

	const [cancellationMessage, setCanceAppointmentMessage] = useState("");

	const [prescriptionMedicineList, setPrescriptionMedicineList] = useState([]);

	const [medicineList, setMedicineList] = useState();
	const [medicineListInKeyValue, setMedicineListInKeyValue] = useState();


	const [testsList, setTestsList] = useState();
	const [testsListInKeyValue, setTestsListInKeyValue] = useState();
	const [prescriptionAdviceTestList, setPrescriptionAdviceTestList] = useState([]);

	const intialAdviceFormValues = {
		"advice_test": "",
		"fasting_pardial": "",
		"remark": ""
	};

	const [adviceFormValues, setAdviceFormValues] = useState(intialAdviceFormValues);
	const [adviceFormErrors, setAdviceFormError] = useState({});
	const [isSubmitAdvice, setIsSubmitAdvice] = useState(false);
	

	
	const [existingPrescriptionList, setExistingPrescriptionList] = useState([]);
	const [existingPrescriptionAdviceTestList, setExistingPrescriptionAdviceTestList] = useState([]);
	

	const [diseaseList, setDiseaseList] = useState([]);
	const [treatmentDetail, setTreatmentDetail] = useState([]);

	const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

	const [showEditAppointment, setShowEditAppointment] = useState(false);
	const [showBeforeImage, setShowBeforeImage] = useState(false);
	const [showAfterImage, setShowAfterImage] = useState(false);
	const [showTreatmentForm, setShowTreatmentForm] = useState(false);

	const [reloadDetail, setReloadDetails] = useState(false);
	const [isShowPrescriptionTab, isSetShowPrescriptionTab] = useState(true);


	const intialTreatmentFormValues = {
		"sub_disease": "",
		"files": "",
		"after_files": "",
		"observation": "",
		"procedure_history": "",
		"medical_history": "",
		"technician_name": ""
	};

	
	const [treatmentFormValues, setTreatmentFormValues] = useState(intialTreatmentFormValues);
	const [treatmentFormErrors, setTreatmentFormError] = useState({});
	const [isSubmitTreatment, setIsSubmitTreatment] = useState(false);
	const [treatmentFormSubmitError, setTreatmentFormSubmitError] = useState("");
	const [isCancel, setIsCancel] = useState(false);


	const handleTab = (currentElement) => {
		document.querySelectorAll('.tab_link').forEach(function(e) {
			e.classList.remove("active");
		});

		document.querySelectorAll('.tab_content').forEach(function(e) {
			e.classList.remove("active");
			e.classList.remove("show");
		});

		currentElement.target.classList.add("active");
		const data_href = currentElement.target.getAttribute("data_href");
		
		var element = document.getElementById(data_href);
		element.classList.add("active");
		element.classList.add("show");
	}

	useEffect(() => {
		if (isCancel) {	
			axios({
				url: apiBaseUrl+ "api/book/appointment/"+id,
				method: "PATCH",
				headers: {authorization: "Bearer "+accessToken},
				data: {'status': 'Cancelled', 'cancelled_at': new Date()},
			}).then((res) => { 
				var data = res.data.data;
			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, [showEditAppointment]);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/book/appointment/"+id,
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			setPatientData(data)
			setIsLoaded(true)
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/book/appointment/patient-detail/"+id,
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			// console.log("Data ", data);
			setTreatmentDetail(data)
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, [reloadDetail]);



	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/book/prescription/item/"+id,
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			var data_advice = res.data.data_advice;
			setExistingPrescriptionList(data)
			setExistingPrescriptionAdviceTestList(data_advice);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/inventory/medicine",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			var new_data = []
			var key_medicines = {}
			for (var i =0; i < data.length; i++) {
				new_data.push({text: data[i]['name']+ " - " +data[i]['content'], id: data[i]['id']});
				key_medicines[data[i]['id']] = data[i]['name'];
			}
			setMedicineListInKeyValue(key_medicines);
			setMedicineList(new_data)
		}).catch((err) => { 
			if (err.response.status == 401) {
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/treatment-test-master",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			var new_data = []
			var key_tests = {}
			for (var i =0; i < data.length; i++) {
				new_data.push({text: data[i]['name'], id: data[i]['id']});
				key_tests[data[i]['id']] = data[i]['name'];
			}

			console.log('key_tests ', key_tests)
			setTestsListInKeyValue(key_tests);
			setTestsList(new_data)
		}).catch((err) => { 
			if (err.response.status == 401) {
				return <Navigate to="/login" replace />;
			}
		});
	}, []);




	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/sub-disease",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			var new_data = []
			var key_medicines = {}
			for (var i =0; i < data.length; i++) {
				new_data.push({text: data[i]['name'], id: data[i]['id']});
				key_medicines[data[i]['id']] = data[i]['name'];
			}
			setDiseaseList(new_data)
		}).catch((err) => { 
			if (err.response.status == 401) {
				return <Navigate to="/login" replace />;
			}
		});
	}, []);

	const handleCreatePrescriptionButton = () => {
		setPrescriptionFormSubmitted(true);
		axios({
			url: apiBaseUrl+ "api/book/prescription",
			method: "POST",
			headers: {authorization: "Bearer "+accessToken},
			data: {
				'items': prescriptionMedicineList,		
				'advice_test_list': prescriptionAdviceTestList,		
				'patient': patientData ? patientData.patient.id: "",
				'appointment': id,
				'branch': localStorage.getItem('branch_id'),
				'doctor': localStorage.getItem('user'),
				'created_by': localStorage.getItem('user')
			} 
		}).then((res) => { 
			setPrescriptionFormSubmitted(false);

			var data = res.data.data;
			var data_advice = res.data.data_advice;

			console.log("adviced_test  ", data_advice)

			setExistingPrescriptionList(data)
			setExistingPrescriptionAdviceTestList(data_advice)
			setPrescriptionAdviceTestList([])

			console.log("Existing Prescription ", existingPrescriptionAdviceTestList)

		}).catch((err) => { 
			setPrescriptionFormSubmitted(false);
			if (err.response.status == 401) {
				return <Navigate to="/login" replace />;
			}
			setFormSubmitError("Prescription already exist with this appointment for the same patient.")
		});
	}

	const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.medicine == "") {
			errors.medicine = "This field is required";
		}

		if (formDataObject.dose == "") {
			errors.dose = "This field is required";
		}

		if (formDataObject.frequency == "") {
			errors.dose = "This field is required";
		}

		if (formDataObject.duration_count == "") {
			errors.duration_name = "This field is required";
		}

		if (formDataObject.duration_name == "") {
			errors.duration_name = "This field is required";
		}

		if (formDataObject.instruction == "") {
			errors.instruction = "This field is required";
		}

		return errors;
	}



	const validateTreatmentForm = (formDataObject) => {
		const errors = {};
		// if (formDataObject.sub_disease == "") {
		// 	errors.sub_disease = "This field is required";
		// }

		// if (formDataObject.files == "") {
		// 	errors.files = "This field is required";
		// }

		if (formDataObject.observation == "") {
			errors.observation = "This field is required";
		}
		return errors;
	}


	// check form errors and call to use effect hooks to submit
	const handleTreatmentFormSubmit = (e) => {
		e.preventDefault();
		setTreatmentFormError(validateTreatmentForm(treatmentFormValues));
		setIsSubmitTreatment(true);
	} 

	const handleFormSubmit = (e) => {
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	} 


	const validateAdviceTestForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.adviced_test == "") {
			errors.adviced_test = "This field is required";
		}

		if (formDataObject.fasting_pardial == "") {
			errors.fasting_pardial = "This field is required";
		}

		return errors;
	}

	const handleAdviceTestFormSubmit = (e) => {
		e.preventDefault();
		setAdviceFormError(validateAdviceTestForm(formValues));
		setIsSubmitAdvice(true);
	} 

	// Prepare to submit Advice Tests Form
	useEffect(() => {
		if (Object.keys(adviceFormErrors).length == 0 && isSubmitAdvice){
			const formObject = document.querySelector(".advice_form");
			const formData = new FormData(formObject);
			var object = {
				'created_by': localStorage.getItem('id')
			};

			formData.forEach(function(value, key){
    			object[key] = value;
			});

			prescriptionAdviceTestList.push(object);
			setPrescriptionAdviceTestList(prescriptionAdviceTestList);
			setAdviceFormValues(intialAdviceFormValues);
			setIsSubmitAdvice(false);
			setAdviceFormError({});
		}
	}, [adviceFormErrors]);

	


	// Prepare to submit 
	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".prescription_form");
			const formData = new FormData(formObject);
			var object = {
				'created_by': localStorage.getItem('id')
			};

			formData.forEach(function(value, key){
    			object[key] = value;
			});

			prescriptionMedicineList.push(object);
			setPrescriptionMedicineList(prescriptionMedicineList);
			setFormValues(intialFormValues);
			setIsSubmit(false);
			setFormError({});
		}
	}, [formErrors]);

	useEffect(() => {
		if (Object.keys(treatmentFormErrors).length == 0 && isSubmitTreatment){

			const formObject = document.querySelector(".start_treatment_form");
			let formData = new FormData(formObject);
			var object = {
				'patient': patientData ? patientData.patient.id: "",
				'appointment': id,
				'branch': localStorage.getItem('branch_id'),
				'doctor': patientData ? patientData.doctor.id: "",
				'created_by': localStorage.getItem('user')
			};

			formData.append('created_by', localStorage.getItem('user'));
			formData.append('patient', patientData.patient.id);
			formData.append('appointment', id);
			formData.append('doctor', patientData.doctor.id);
			formData.append('branch', localStorage.getItem('branch_id'));

			setTreatmentFormSubmitted(true);

			axios({
                url: apiBaseUrl+ "api/book/appointment/patient-detail",
                method: "POST",
                headers: {
					authorization: "Bearer "+ accessToken,
					'Content-Type': 'multipart/form-data'
				},
                data: formData,
            }).then((res) => { 
				setTreatmentFormSubmitted(false);

				setTreatmentDetail(res.data.data)
				setTreatmentFormSubmitError("Patient details has been submitted");
				setFormValues(intialTreatmentFormValues);

				
			}).catch((err) => { 
				setTreatmentFormSubmitted(false);
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail'])
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setTreatmentFormError(err.response.data);
					// setFormSubmitError();
				}
				
			});
		}
	}, [treatmentFormErrors,reloadDetail]);


	// Handle Input change event
	const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}

	// Advice Form Change Event
	const handleAdviceFormChange = (e) => {
		const {name,value} = e.target;
		setAdviceFormValues({...adviceFormValues, [name]: value});
	}

	const handleTreatmentChange = (e) => {
		const {name,value} = e.target;
		setTreatmentFormValues({...treatmentFormValues, [name]: value});
	}



	// Handle Boostrap Modal
	const [showCancel, setCancelShow] = useState(false);
	const handleCancelClose = (e) => {
		setCancelShow(false);
	} 
	
	const handleCancelShow = () => setCancelShow(true);
	const handeCancelAppointment = (e) => {
        setCancelShow(true);
    }

	const handleCancelConfirmationButton = () => {
		setLoader(true)

		axios({
			url: apiBaseUrl+ "api/book/appointment/"+id,
			method: "PATCH",
			headers: {
				authorization: "Bearer "+ accessToken,
				'Content-Type': 'multipart/form-data'
			},
			data: {'status': 'Cancelled', 'cancelled_at': new Date()},
		}).then((res) => { 
			setCanceAppointmentMessage("The Appointment has been cancelled.");
		}).catch((err) => { 
			if (err.response.status == 401) {
				setCanceAppointmentMessage(err['response']['data']['detail']);
				return <Navigate to="/login" replace />;
			} else if (err.response.status == 400) {
				setCanceAppointmentMessage(err.response.data);
			}
		});
	}

	const deletePrecriptionItem = (e) => {
        let dataIndex = parseInt(e.target.getAttribute("data-index"));
        let new_medicine_list = []
        for (var i = 0; i < prescriptionMedicineList.length; i++) {
            if (i != dataIndex) {
                new_medicine_list.push(prescriptionMedicineList[i]);
            }
        }
        setPrescriptionMedicineList(new_medicine_list);
    }

	const deletePrecriptionAdviceTestItem = (e) => {
        let dataIndex = parseInt(e.target.getAttribute("data-index"));
        let new_advice_list = []
        for (var i = 0; i < prescriptionAdviceTestList.length; i++) {
            if (i != dataIndex) {
                new_advice_list.push(prescriptionAdviceTestList[i]);
            }
        }
        setPrescriptionAdviceTestList(new_advice_list);
    }

	// Handle Edit Appointment Modal
	
	const handleEditAppointmentClose = (e) => {
		setShowEditAppointment(false);
		setReloadDetails(true);
	} 
	
	const handelAppointmentEditShow = (e) => {
        setShowEditAppointment(true);
    }



	// const [showBeforeImage, setShowBeforeImage] = useState(false);
	// const [showAfterImage, setShowAfterImage] = useState(false);

	// Show Before Images
	const handleShowBeforeImageClose = (e) => {
		setShowBeforeImage(false);
		setReloadDetails(true);
	} 
	
	const handleShowBeforeImages = (e) => {
        setShowBeforeImage(true);
		setReloadDetails(false);
    }

	// SHow and Hide After Images Modal
	const handleShowAfterImageClose = (e) => {
		setShowAfterImage(false);
		setReloadDetails(true);
	} 
	
	const handleShowAfterImages = (e) => {
        setShowAfterImage(true);
		setReloadDetails(false);
		
    }

	// Show and Hide Treatment Form
	// const [showTreatmentForm, setShowTreatmentForm] = useState(false);

	const handleShowTreatmentFormClose = (e) => {
		setShowTreatmentForm(false);
		setReloadDetails(true);
	} 
	
	const handleShowTreatmentForm = (e) => {
        setShowTreatmentForm(true);
		setReloadDetails(false);
    }

	const handlePrescriptionInternalTab = () => {
		isSetShowPrescriptionTab(false);
	}

  return (
	<div className="container-fluid container-application">
        <div className="sidenav show" id="sidenav-main">
            <Header></Header>
            <Sidebar></Sidebar>
        </div>
        <div className="main-content position-relative">
            <Navbar></Navbar>
            <div className="page-content">
              	<div className="row justify-content-center">


					<div className="col-lg-12">

						<div className='row mb-3'>
							<div className='col-md-5'>
								<PageTitle  link_not_required="1" pageName={patientData ? "Appointment Details  -  "+patientData.patient.name: ""} link_to="appointment" link_name="View All Appointment"></PageTitle>
							</div>

							{patientData ? 
							<div className='col-md-7 text-right'>
								<button className="btn btn-sm btn-white btn-icon rounded-pill" onClick={handelAppointmentEditShow}>
									<span className="btn-inner--icon"><i className="fa fa-pencil"></i></span>
									<span className="btn-inner--text">Edit Appointment</span>
								</button>	

								{cancellationMessage == "" && patientData.status == "Pending" ? 
									<button className="btn btn-sm btn-white btn-icon rounded-pill" onClick={handeCancelAppointment}>
										<span className="btn-inner--icon"><i className="fa fa-times"></i></span>
										<span className="btn-inner--text">Cancel Appointment</span>
									</button>	
								: 	""}

								<Link to="/appointment" className="btn btn-sm btn-white btn-icon rounded-pill">
									<span className="btn-inner--icon"><i className="far fa-list"></i></span>
									<span className="btn-inner--text">View All Appointment</span>
								</Link>								

							</div> 
							: ""}


						</div>
						
						

						<div className="card">
							<div className="card-body">

								<ul className="nav nav-tabs" role="tablist">
									<li className="nav-item">
										<a className="nav-link tab_link active" data-bs-toggle="tab" data_href="start_treatment" href="#!" onClick={handleTab}>Start Treatment</a>
									</li>

									<li className="nav-item">
										<a className="nav-link tab_link" data-bs-toggle="tab" data_href="prescription" href="#!" onClick={handleTab}>Make Prescription</a>
									</li>

									
									<li className="nav-item">
										<a className="nav-link tab_link" data-bs-toggle="tab" data_href="personal_detail" href="#!" onClick={handleTab}>Chief Complaints & Personal Detail</a>
									</li>

									<li className="nav-item">
										<a className="nav-link tab_link" data-bs-toggle="tab" data_href="treatment_history" href="#!" onClick={handleTab}>Treatment History</a>
									</li>
									

									<li className="nav-item">
										<a className="nav-link tab_link" data-bs-toggle="tab" data_href="other_detail" href="#!" onClick={handleTab}>Other Detail</a>
									</li>
								</ul>

								<div className="tab-content">
									<div id="start_treatment" className="container tab-pane tab_content show active"><br/>
										<table className='table table-bordered table-striped'>
											<tbody>
												<tr>
													<th>Patient Name</th>
													<td>{patientData ? patientData.patient.name: ""}</td>
													<th>Doctor Name</th>
													<td>{patientData ? patientData.doctor.name: ""}</td>
												</tr>
												<tr>
													<th>Appointment Start Date & Time</th>
													<td>{patientData ? patientData.appointment_at: ""}</td>
													<th>Appointment End Date & Time</th>
													<td>{patientData ? patientData.appointment_end_at: ""}</td>
												</tr>
												<tr>
													<th>Appointment Status </th>
													{cancellationMessage != ""? <td>Cancelled</td>
														:<td>{patientData ? patientData.status: ""
													}</td>}

													<th>Booked At</th>
													<td>{patientData ? patientData.created_at: ""}</td>
												</tr>
												<tr>
													<th >About Disease</th>
													<td colSpan={3}>{patientData ? patientData.about_disease: ""}</td>
												
												</tr>
											</tbody>
										</table>
										<br/>
										
										{treatmentDetail.length > 0 ? 
											<>
												<div className='row'>
													<div className='col-md-12'><b>Observation and Notes</b> <button onClick={handleShowTreatmentForm} className='ml-4 btn btn-xs btn-primary'><i class="fa fa-pencil"></i> Edit</button></div>
												</div>

												<table className='table table-bordered table-striped mt-3'>
													<tbody>
														<tr>
															<th className='width150px'>Treatment</th>
															<td>
																{treatmentDetail[0] ? treatmentDetail[0]['sub_disease'].map((info) => (
																	<div className='mt-2'>{info.name}</div>
																)): ""}															
															</td>
														</tr>
														<tr>
															<th className='width150px'>Observation</th>
															<td>{treatmentDetail[0] ? treatmentDetail[0]['observation']: ""}</td>
														</tr>
														<tr>
															<th className='width150px'>Medical History</th>
															<td>{treatmentDetail[0] ? treatmentDetail[0]['medical_history']: ""}</td>
														</tr>
														<tr>
															<th className='width150px'>Procedure History</th>
															<td>{treatmentDetail[0] ? treatmentDetail[0]['procedure_history']: ""}</td>
														</tr>

														<tr>
															<th className='width150px'>Technician Name</th>
															<td>{treatmentDetail[0] ? treatmentDetail[0]['technician_name']: ""}</td>
														</tr>

													</tbody>
												</table>
												<br/>
												<div className='row mt-3'>
													<div className='col-md-12'><b>Before Treatement Images</b> <button onClick={handleShowBeforeImages} className='ml-4 btn btn-xs btn-primary'><i class="fa fa-pencil"></i> Edit</button></div>
												</div>
												<div className='row mt-3'>
													{treatmentDetail[0] ? treatmentDetail[0]['images'].map((info) => (
														<div className='col-md-3 text-center'>
															<img width={200} src={apiBaseUrl+info['image']} alt="" /></div>
													)): ""}	
												</div>
												<div className='row mt-3'>
													<div className='col-md-12 mt-3'><b>After Treatement Images</b> <button onClick={handleShowAfterImages} className='ml-4 btn btn-xs btn-primary'><i class="fa fa-pencil"></i> Edit</button></div>
												</div>
												<div className='row'>
													{treatmentDetail[0] ? treatmentDetail[0]['after_images'].map((info) => (
														<div className='col-md-3 text-center'>
															<img width={200} src={apiBaseUrl+info['image']} alt="" /></div>
													)): ""}	
												</div>
											</>
										: <form className="mt-4 start_treatment_form" action='#!' encType='multipart/form-data' onSubmit={handleTreatmentFormSubmit}>
										<div className="row">

										<div className="col-md-6">
											<div className="form-group">
												<label className="form-control-label">Select Treatment (press CTRL + Select)</label>
												<div className='treatment_options_div'>
													{diseaseList.map((info) => (
														<div class="form-check">
															<input class="form-check-input" value={info.id} type="checkbox"  name="sub_disease" />
															<label class="form-check-label">{info.text}</label>
														</div>
													))}
												</div>

												{/* sub_disease */}

												{/* <select multiple className='form-control'  name="sub_disease">
													<option value="">Select Treatment</option>
													{diseaseList.map((info) => (
														<option value={info.id}>{info.text}</option>
													))}
												</select> */}



												
												<span className='error_message'>{treatmentFormErrors.sub_disease}</span>
											</div>
										</div>
{/* 
											<div className="col-md-6">
												<div className="form-group">
													<label className="form-control-label">Select Disease</label>
													<Select2  value={treatmentFormValues.sub_disease} onChange={handleTreatmentChange}  name="sub_disease" className="form-control"
														data={diseaseList}
														options={{
															placeholder: 'Select Disease',
														}}
													/>
													<span className='error_message'>{treatmentFormErrors.sub_disease}</span>
												</div>
											</div> */}

											<div className="col-md-6">												
												<div className="form-group">
													<label className="form-control-label">Technician Name</label>
													<input value={treatmentFormValues.technician_name} onChange={handleTreatmentChange} className="form-control" type="text" name="technician_name"/>
													<span className='error_message'>{treatmentFormErrors.technician_name}</span>
												</div>
												<span className='error_message'>{treatmentFormErrors.technician_name}</span>
											</div>
										
											<div className="col-md-12">
												<div className="form-group">
													<label className="form-control-label">Your Observation & Suggestion</label>
													<input value={treatmentFormValues.observation} onChange={handleTreatmentChange} className="form-control" type="text" name="observation"/>
													<span className='error_message'>{treatmentFormErrors.observation}</span>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label className="form-control-label">Medical History</label>
													<input value={treatmentFormValues.medical_history} onChange={handleTreatmentChange} className="form-control" type="text" name="medical_history"/>
													<span className='error_message'>{treatmentFormErrors.medical_history}</span>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<label className="form-control-label">Procedure History</label>
													<input value={treatmentFormValues.procedure_history} onChange={handleTreatmentChange} className="form-control" type="text" name="procedure_history"/>
													<span className='error_message'>{treatmentFormErrors.procedure_history}</span>
												</div>
											</div>

											<div className="col-md-12">
												<div className="form-group">
													<label className="form-control-label">Upload Image of Before Treatment (To upload multiple image... Use ctrl+select )</label>
													<input value={treatmentFormValues.files} onChange={handleTreatmentChange} className="form-control" type="file" name="files" multiple/>
													<span className='error_message'>{treatmentFormErrors.files}</span>
												</div>
											</div>
											
											<div className="col-md-12">
												<div className="form-group">
													<label className="form-control-label">Upload Image of After Treatment(To upload multiple image... Use ctrl+select )</label>
													<input value={treatmentFormValues.after_files} onChange={handleTreatmentChange} className="form-control" type="file" name="after_files" multiple/>
													<span className='error_message'>{treatmentFormErrors.after_files}</span>
												</div>
											</div>
											
										</div>
										<div className="col-md-12 ">
											
											<div className="text-right">
												{isTreatmentFormSubmitted == false ? 
												<button type="submit" className="btn btn-primary rounded-pill">Submit</button>:
												<button type="button" className="btn btn-info rounded-pill">Please wait...</button>
												}
											</div>
										</div>
										</form>}


									</div>

									<div id="prescription" className="container tab-pane tab_content">
										
										<div classList="prescription_medicine_block ">
											{existingPrescriptionList.length == 0 && existingPrescriptionAdviceTestList.length == 0 ?
											<div className='row'>
												{formSubmitError != "" ? 
												<div className='col-md-12 mt-3 text-center'><p>{formSubmitError}</p></div>
												: 
												""}
												
												<div className='col-md-4'>
													<form className="mt-3 prescription_form" action='#!'>
														<div className='col-md-12 form-group tab_content_form'>
															<label>Select Medicine</label>
															<Select2 name="medicine" value={formValues.medicine} onChange={handleChange} 
																data={medicineList ? medicineList: []}
																options={{
																	placeholder: 'Select Medicine',
																}}
															/>
															<span className='error_message'>{formErrors.medicine}</span>
														</div>

														<div className='col-md-12 form-group tab_content_form'>
															<div className='row'>
																<div className='col-md-12'>
																	<label>Dose and Frequency</label>
																</div>
																<div className='col-md-5 form-group'>
																	<input type='text' onChange={handleChange} name="dose" value={formValues.dose} placeholder='E.g 1'  className='form-control'/>
																</div>
																<div className='col-md-7 form-group'>
																	<select className='form-control' onChange={handleChange} name="frequency" value={formValues.frequency}>
																		<option value="">Select</option>
																		{FREQUENCYLIST.map((info, i) => (
																			<option>{info}</option>
																		))}
																	</select>
																</div>
																<div className='col-md-12'>
																	<span className='error_message'>{formErrors.dose}</span>
																</div>
															</div>
														</div>
															
														<div className='col-md-12 form-group tab_content_form'>
															<div className='row'>
																<div className='col-md-12'>
																	<label>Select Course Duration</label>
																</div>
																<div className='col-md-6 form-group'>
																	<input type='text' onChange={handleChange} value={formValues.duration_count} name="duration_count" placeholder='E.g 1'  className='form-control'/>
																</div>
																<div className='col-md-6 form-group'>
																	<select className='form-control' onChange={handleChange} name="duration_name" value={formValues.duration_name}>
																		<option value="">Select</option>
																		{DURATIONLIST.map((info, i) => (
																			<option>{info}</option>
																		))}
																	</select>
																</div>
																<div className='col-md-12'>
																	<span className='error_message'>{formErrors.duration_name}</span>
																</div>
															</div>
														</div>


														<div className='form-group col-md-12 tab_content_form'>
															<label>Instruction or Remark</label>
															<textarea type='text' value={formValues.instruction} onChange={handleChange} name='instruction' className='form-control'></textarea>
															<span className='error_message'>{formErrors.instruction}</span>
														</div>

														<div className="form-group col-md-12 mt-3 tab_content_form">
															<div className="text-right">
																<button type="button" onClick={handleFormSubmit} className="btn btn-primary btn-sm rounded-pill">Add Medicine</button>
															</div>
														</div>
													</form>
												</div>
												
												{prescriptionMedicineList.length > 0 ? 
												<div className='col-md-8'>
													<h5 className='mt-4'>Medicine List</h5>
													<table className='table table-bordered bg-white'>
														<thead>
															<tr>
																<th>Medicine</th>
																<th>Dose & Frequency</th>
																<th>Course Duration</th>
																<th>Instruction / Remark</th>
																<th>Action</th>
															</tr>
														</thead>
														<tbody>

														{prescriptionMedicineList.map((info,i) => (
															<tr>
																<td>{medicineListInKeyValue ? medicineListInKeyValue[info['medicine']]: ""}</td>
																<td>{info['dose'] + " " +  info['frequency']}</td>
																<td>{info['duration_count'] + " "+ info['duration_name']}</td>
																<td>{info['instruction']}</td>
																<td><a href="#!" onClick={deletePrecriptionItem} data-index={i}><i onClick={deletePrecriptionItem} data-index={i} className='fa fa-trash'></i></a></td>
															</tr>
														))}
														</tbody>
														
														
													</table>
													{prescriptionAdviceTestList.length == 0 ?
													<div className='text-right'>
														{isPrescriptionFormSubmitted == false ? 
														<button type='button' onClick={handleCreatePrescriptionButton} className='btn btn-primary mt-3 rounded-pill'>Create Prescription</button>
														:
														<button type='button' className='btn btn-info mt-3 rounded-pill'>Please wait...</button>
														}
													</div>
													: ""}
												</div>
												: <div className='col-md-8 mt-10 text-center'>Medincine not added in Prescription.</div>}

											</div>
											: <div className='row'>

												<div className='col-md-6 mt-2 mb-2'>
													<h5 className='mt-4'>Medicine List</h5>
												</div>
												<div className='col-md-6 mt-4 mb-1 text-right'>
													<Link type="button" to={"/edit-prescription/"+id} className='btn btn-xs btn-primary'><i className='fa fa-pencil mr-2'></i>  Edit Prescription</Link>
													<a type="button" href={apiBaseUrl + "cms/prescription/pdf/?appointment_id="+id } className='btn btn-xs btn-primary'><i className='fa fa-download mr-2'></i>  Download Prescription</a>		
												</div>
												<div className='col-md-12'>											
													<table className='table table-bordered bg-white'>
														<thead>
															<tr>
																<th className='width250px text-center'>Medicine</th>
																<th className='text-center width60px'>Dose</th>
																<th className='width150px text-center'>Frequency</th>
																<th className='width150px text-center'>Course Duration</th>
																<th className='width250px text-center'>Instruction / Remark</th>
																{/* <th>Action</th> */}
															</tr>
														</thead>

														<tbody>
															{existingPrescriptionList.map((info) => (
																<tr>
																	<td className='width250px text-center'><div className='width250px text_word_wrap'>{info['medicine']}</div></td>
																	<td className='text-center width60px'>{info['dose']%1 == 0 ? Math.round(info['dose']): info['dose']}</td>
																	<td className='width150px text-center'>{info['frequency']}</td>
																	<td className='width150px text-center'>{(info['duration_count']%1 == 0 ? Math.round(info['duration_count']): info['duration_count']) + " "+ info['duration_name']}</td>
																	<td className='width250px text-center'>{info['instruction']}</td>
																	{/* <td className='text-center'><a href="#!"><i className='fa fa-pencil'></i></a></td> */}
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</div>
											}
										</div>

										<div className='prescription_test_block'>
											<div className='row'>
												{existingPrescriptionAdviceTestList.length == 0 && existingPrescriptionList.length == 0 ?
												// { !existingPrescriptionAdviceTestList ?
												<div className='col-md-4'>
													<form className="mt-3 advice_form" action='#!'>
														<div className='col-md-12 form-group tab_content_form'>
															<label>Select Test Name</label>
															<Select2 name="adviced_test" value={adviceFormValues.adviced_test} onChange={handleAdviceFormChange} 
																data={testsList ? testsList: []}
																options={{
																	placeholder: 'Select',
																}}
															/>
															<span className='error_message'>{adviceFormErrors.adviced_test}</span>
														</div>
														
														<div className='form-group col-md-12 tab_content_form'>
															<label>Select Fasting / Pardial</label>
															<select className='form-control' onChange={handleAdviceFormChange} name="fasting_pardial" value={adviceFormValues.fasting_pardial}>
																<option value="">Select</option>
																<option>Fasting</option>
																<option>Pardial</option>
															</select>
															<span className='error_message'>{adviceFormErrors.fasting_pardial}</span>
														</div>

														<div className='form-group col-md-12 tab_content_form'>
															<label>Instruction or Remark</label>
															<textarea type='text' value={adviceFormValues.remark} onChange={handleAdviceFormChange} name='remark' className='form-control'></textarea>
															<span className='error_message'>{adviceFormErrors.remark}</span>
														</div>

														<div className="form-group col-md-12 mt-3 tab_content_form">
															<div className="text-right">
																<button type="button" onClick={handleAdviceTestFormSubmit} className="btn btn-primary btn-sm rounded-pill">Add Test Advice</button>
															</div>
														</div>
														
													</form>
												</div>
												: ""}
												
												{prescriptionAdviceTestList.length > 0 ? 
												// {prescriptionAdviceTestList ? 
												<div className='col-md-8'>
													<h5 className='mt-4'>Advice Tests List</h5>
													<table className='table table-bordered bg-white'>
														<thead>
															<tr>
																<th>Test Name</th>
																<th>Fasting / Pardial</th>
																<th>Instruction / Remark</th>
																<th>Action</th>
															</tr>
														</thead>
														<tbody>

														{prescriptionAdviceTestList.map((info,i) => (
															<tr>
																<td>{testsListInKeyValue ? testsListInKeyValue[info['adviced_test']]: ""}</td>
																<td>{info['fasting_pardial']}</td>
																<td>{info['remark']}</td>
																<td><a href="#!" onClick={deletePrecriptionAdviceTestItem} data-index={i}><i onClick={deletePrecriptionAdviceTestItem} data-index={i} className='fa fa-trash'></i></a></td>
															</tr>
														))}
														</tbody>													
													</table>

													
													<div className='text-right'>
														{isPrescriptionFormSubmitted == false ? 
														<button type='button' onClick={handleCreatePrescriptionButton} className='btn btn-primary mt-3 rounded-pill'>Create Prescription</button>
														:
														<button type='button' className='btn btn-info mt-3 rounded-pill'>Please wait...</button>
														}
													</div>
												</div>
												: 
													
														<>
														{ existingPrescriptionAdviceTestList.length > 0 ? 
														// { existingPrescriptionAdviceTestList ? 
														<>
															<div className='col-md-12 mt-2 mb-2'>
																<h5 className='mt-4'>Adviced Test List</h5>
															</div>
															<div className='col-md-12'>											
																<table className='table table-bordered bg-white'>
																	<thead>
																		<tr>
																			<th>Test Name</th>
																			<th>Fasting / Pardial</th>
																			<th>Instruction / Remark</th>
																		</tr>
																	</thead>

																	<tbody>
																		{existingPrescriptionAdviceTestList.map((info) => (
																			<tr>
																				<td>{info['adviced_test']}</td>
																				<td>{info['fasting_pardial']}</td>
																				<td>{info['remark']}</td>
																			</tr>
																		))}
																	</tbody>
																</table>
															</div>
														</>
														: <div className='text-center col-md-8 mt-6'>No advice tests added in Prescription</div> }
														</>
													
													
												}

											</div>
										</div>



									</div>

									<div id="personal_detail" className="container tab-pane tab_content "><br/>
										<h5>Chief Complaints</h5>
										<table className='table table-bordered table-stripped'>
											<tbody>
												<tr>
													<th>Dermatology Concerns</th>
													<td colSpan={5}>{patientData ? patientData.patient_detail.dermatology_concern: ""}</td>
												</tr>
												<tr>
													<th>Any other Dermatology Concerns</th>
													<td colSpan={5}>{patientData ? patientData.patient_detail.other_dermatology_concern: ""}</td>
												</tr>
												<tr>
													<th>Cosmetic Concerns</th>
													<td colSpan={5}>{patientData ? patientData.patient_detail.cosmetic_concern: ""}</td>
												</tr>
												<tr>
													<th>Any other Cosmetic Concerns</th>
													<td colSpan={5}>{patientData ? patientData.patient_detail.other_cosmetic_concern: ""}</td>
												</tr>
											</tbody>
										</table>
																		 
										<h5 className='mt-4'>Personal Detail</h5>
										<div>
											<table className='table table-bordered table-striped'>
												<tbody>
													<tr>
														<th>Name</th>
														<td>{patientData ? patientData.patient.name: ""}</td>
														<th>Email</th>
														<td>{patientData ? patientData.patient.email: ""}</td>
														<th>Mobile Number</th>
														<td>{patientData ? patientData.patient.mobile: ""}</td>
													</tr>

													<tr>
														<th>Date of Birth</th>
														<td>{patientData ? patientData.patient.date_of_birth: ""}</td>
														<th>Age</th>
														<td>{patientData ? patientData.age: ""}</td>
														<th>Gender</th>
														<td>{patientData ? patientData.patient.gender: ""}</td>
													</tr>
													
													<tr>
														<th>Branch</th>
														<td>{patientData ? patientData.branch.name: ""}</td>
														<th>Created At</th>
														<td>{patientData ? patientData.patient.created_at: ""}</td>
														<th>Created By</th>
														<td></td>
													</tr>
												</tbody>
											</table>
											<br/>
											<h5>Address</h5>
											{patientData && patientData.address.length > 0 &&
												<table className='table table-bordered table-stripped'>
													<tr>
														<th>Address</th>
														<td colSpan={5}>{patientData ? patientData.address[0].address: ""}</td>
													</tr>
													<tr>
														<th>City</th>
														<td>{patientData ? patientData.address[0].city: ""}</td>
														<th>State</th>
														<td>{patientData ? patientData.address[0].state: ""}</td>
														<th>Pincode</th>
														<td>{patientData ? patientData.address[0].pincode: ""}</td>
													</tr>
												</table>
											}
										</div>
									</div>

									<div id="other_detail" className="container tab-pane fade tab_content"><br/>
										{patientData && 
											<><table className='table table-bordered table-stripped'>
												<tbody>
													<tr>
														<th>Alcohal</th>
														<td>{patientData.patient_detail.has_alcoholic ? "Yes": "No"}</td>
														<th>Smoking</th>
														<td>{patientData.patient_detail.has_smoking ? "Yes": "No"}</td>
														<th>Sun Screen Use</th>
														<td>{patientData.patient_detail.has_sunscreen_use ? "Yes": "No"}</td>
													</tr>
													<tr>
														<th>Pets</th>
														<td>{patientData.patient_detail.has_pets ? "Yes": "No"}</td>
														<th>Outdoor Activities</th>
														<td>{patientData.patient_detail.has_outdoor_activities ? "Yes": "No"}</td>
														<th>Skin Care Routine</th>
														<td>{patientData.patient_detail.has_skin_care_activity ? "Yes": "No"}</td>
													</tr>
												</tbody>
											</table>

											<br/>

											<table className='table table-bordered table-stripped'>
												<tbody>
													<tr>
														<th><div className='w-50'>Medications including non prescriptions allergy or oral contraceptive or ayurvedic pill</div></th>
														<td colSpan={5}>{patientData ? patientData.patient_detail.allergy_or_oral_contraceptive: ""}</td>
													</tr>
													<tr>
														<th>Menstrual History / Childbirth History</th>
														<td colSpan={5}>{patientData ? patientData.patient_detail.menstrual_child_birth_history: ""}</td>
													</tr>
													<tr>
														<th>Hair Complaints</th>
														<td colSpan={5}>{patientData ? patientData.patient_detail.hair_complaints: ""}</td>
													</tr>
													<tr>
														<th>Nail Complaints</th>
														<td colSpan={5}>{patientData ? patientData.patient_detail.nail_compalints: ""}</td>
													</tr>
													<tr>
														<th>Weight gain / loss in the last 3 months</th>
														<td colSpan={5}>{patientData ? patientData.patient_detail.weight_gain_loss_in_3_months: ""}</td>
													</tr>
													<tr>
														<th>Diet</th>
														<td colSpan={5}>{patientData ? patientData.patient_detail.diet: ""}</td>
													</tr>
													<tr>
														<th>Excercise</th>
														<td colSpan={5}>{patientData ? patientData.patient_detail.excercise: ""}</td>
													</tr>
													<tr>
														<th>Sleeping Habits</th>
														<td colSpan={5}>{patientData ? patientData.patient_detail.sleeping_habits: ""}</td>
													</tr>
												</tbody>
											</table>
											</>
										}
									</div>

									<div id="treatment_history" className="container tab-pane fade tab_content"><br/>
										<h3>Treatment History</h3>
										{patientData ? 
											<TreatmentHistory patient_id={patientData.patient.id} />
										: ""}
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>

			<Modal show={showCancel} onHide={handleCancelClose}>
				<Modal.Header>
					<Modal.Title id ='contained-modal-title-vcenter'>Cancel Appointment</Modal.Title>
					<Button type='button' className='btn-close btn-xs btn-secondary' onClick={handleCancelClose}>X</Button>
				</Modal.Header>
				<Modal.Body>
					
					{cancellationMessage == "" ? 
						<div>Are you sure you want to cancel appointment?</div>
					:	<div className='alert alert-success'>{cancellationMessage}</div>}
				</Modal.Body>
				
				{cancellationMessage == "" ? 
					<Modal.Footer>
						{isLoader == false ? <>
							<Button variant="secondary btn-xs " onClick={handleCancelClose}>Cancel</Button>
							<Button variant="primary btn-xs" onClick={handleCancelConfirmationButton} >Confirm</Button>
							</>
							: "Please wait..."
						}
						
					</Modal.Footer>
				: ""}
			</Modal>
			
			

			<Modal show={showEditAppointment} onHide={handleEditAppointmentClose}>
				<Modal.Header>
					<Modal.Title id ='contained-modal-title-vcenter'>{id ? "Edit Appointment": "Add Appointment"}</Modal.Title>
					<Button type='button' className='btn-close btn-xs btn-secondary' onClick={handleEditAppointmentClose}>X</Button>
				</Modal.Header>
				<Modal.Body>
					<AppointmentForm is_edit={true} isPatientSelected={true} is_border_required={false} appointment_id={id}  patient_id={patientData ? patientData.patient.id: ""} />
				</Modal.Body>
			</Modal>

			{/* Upload Before Images */}
			<Modal show={showBeforeImage} onHide={handleShowBeforeImageClose}>
				<Modal.Header>
					<Modal.Title id ='contained-modal-title-vcenter'>Edit Before Images</Modal.Title>
					<Button type='button' className='btn-close btn-xs btn-secondary' onClick={handleShowBeforeImageClose}>X</Button>
				</Modal.Header>
				<Modal.Body>
					<UploadTreatmentImage upload_link="before" is_border_required={false} appointment_id={id} treatment_id={treatmentDetail.length > 0 ? treatmentDetail[0].id: ""} />
				</Modal.Body>
			</Modal>

			{/* Upload after Images */}
			<Modal show={showAfterImage} onHide={handleShowAfterImageClose}>
				<Modal.Header>
					<Modal.Title id ='contained-modal-title-vcenter'>Edit After Images</Modal.Title>
					<Button type='button' className='btn-close btn-xs btn-secondary' onClick={handleShowAfterImageClose}>X</Button>
				</Modal.Header>
				<Modal.Body>
					<UploadTreatmentImage upload_link="after" is_border_required={false} appointment_id={id} treatment_id={treatmentDetail.length > 0 ? treatmentDetail[0].id: ""}  />
				</Modal.Body>
			</Modal>


			{/* Treatment Form */}
			<Modal size="lg" show={showTreatmentForm} onHide={handleShowTreatmentFormClose}>
				<Modal.Header>
					<Modal.Title id ='contained-modal-title-vcenter'>Edit Treatment Details</Modal.Title>
					<Button type='button' className='btn-close btn-xs btn-secondary' onClick={handleShowTreatmentFormClose}>X</Button>
				</Modal.Header>
				<Modal.Body>
					<TreatmentForm upload_link="after" treatment_detail={treatmentDetail.length > 0 ?treatmentDetail[0]: []} disease_list={diseaseList} is_border_required={false} appointment_id={id} treatment_id={treatmentDetail.length > 0 ? treatmentDetail[0].id: ""}  />
				</Modal.Body>
			</Modal>


        </div>
    </div>
  )
}
