import React from 'react'
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

export default function AlertMessage(props) {
    const [show, setShow] = useState(true);

    if (show && props.alert_message) {
        return (
            <Alert variant="success" onClose={() => setShow(false)} dismissible>
                <p>{props.alert_message}</p>
            </Alert>
          )
    } else {
        return (
            <div></div>
        )
    }

}
