import React, { useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';
import axios from 'axios';
import { accessToken } from '../../components/ValidationRegex';
import PageTitle from '../../components/PageTitle';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';

export default function PurchaseDetail() {
  	const { id } = useParams()
	const [purchaseData, setPurchaseData] = useState();

  	useEffect(() => {
		if (id) {
			axios({
				url: apiBaseUrl+ "api/inventory/purchase/"+id,
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
			}).then((res) => { 
				var data = res.data;
				setPurchaseData(data.data);
			}).catch((err) => { 
				if (err.response.status == 401) {
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, []);

  return (
    <div className="container-fluid container-application">
		<div className="sidenav show" id="sidenav-main">
			<Header></Header>
			<Sidebar></Sidebar>
		</div>
    	<div className="main-content position-relative">
			<Navbar></Navbar>
			<div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<PageTitle pageName="Purchase Details" link_to="purchase-list" link_name="View All Purchase's"></PageTitle>
						<div className="card mt-4">
						<div className="card-body card-stats">
							<div className="row">
								<div className="col-md-12">

									<table className='table table-bordered table-striped'>
										<tbody>
											<tr>
												<th>Vendor Name</th>
												<td>{purchaseData ? purchaseData.vendor: ""}</td>
												<th>Invoice Date</th>
												<td>{purchaseData ? purchaseData.invoice_date: ""}</td>
											</tr>
											<tr>
												<th>Invoice No.</th>
												<td>{purchaseData ? purchaseData.invoice_no: ""}</td>
												<th>Download Invoice</th>
												<td>{purchaseData ? <Link target='blank' to={purchaseData.invoice} download><i className='fa fa-download'></i> Download</Link>: ""}</td>
											</tr>
                                            <tr>
												<th>Created At</th>
												<td>{purchaseData ? purchaseData.created_at: ""}</td>
												
											</tr>
										</tbody>
									</table>

                    				<h5 className='mt-4'>Purchase Items</h5>
									{purchaseData ? 
										<table className='table table-bordered'>
											<thead>
												<tr>
													<th>Medicine</th>
													<th>Qty</th>
													<th>Price</th>
													<th>Sub Total</th>
													<th>Description</th>
												</tr>
											</thead>
											{purchaseData.purchase_item ? 
												<tbody>
													{purchaseData.purchase_item.map((info) => (
														<tr>
															<td>{info.medicine}</td>
															<td>{info.qty}</td>
															<td>{info.price}</td>
															<td>{info.price*info.qty}</td>
															<td>{info.description}</td>
														</tr>
													))}													
												</tbody>
											: <tbody>
													<tr>
														<td colSpan={4}>Purchase items not found</td>
													</tr>
											</tbody>
										}
										</table>
									: 
									<table className='table table-bordered'>
										
										<tbody>
											<tr>
												<td colSpan={4}>Purchase items not found</td>
											</tr>
										</tbody>
										
									</table>
									
									}
                                    
								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
  )
}
