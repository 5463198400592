import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import PageTitle from '../../components/PageTitle';
import { Navigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { accessToken } from '../../components/ValidationRegex';
import { apiBaseUrl } from '../../components/ConstantUtils';


export default function Prescription() {

    const { id } = useParams()
    const [prescribedMedicine, setPrescribedMedicine] = useState()

    const [salesInfo, setSalesInfo] = useState()
    
    useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/book/prescription/item-stock/"+id,
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			setPrescribedMedicine(data)
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


    const handleMedicineDetails = (e) => {
        e.preventDefault();
        const formObject = document.querySelector(".prescribed_medicine_form");
        const formData = new FormData(formObject);
        formData.append("prescription", id);
        formData.append("appointment", prescribedMedicine ? prescribedMedicine[0]['prescription']['appointment']['id']: "");
        formData.append("patient", prescribedMedicine ? prescribedMedicine[0]['prescription']['appointment']['patient']: "");

        axios({
			url: apiBaseUrl+ "api/inventory/sales",
			method: "POST",
			headers: {authorization: "Bearer "+accessToken},
			data: formData,
		}).then((res) => { 
			var data = res.data.data;
            console.log("data ", data)
            setSalesInfo(data)
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
    }

	return (
        <div className="container-fluid container-application">
            
            <div className="sidenav show" id="sidenav-main">
                <Header></Header>
                <Sidebar></Sidebar>
            </div>
            
            <div className="main-content position-relative">
                <Navbar></Navbar>
                <div className="page-content">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <PageTitle pageName="Prescribed Medicines" link_to="appointment" link_name="View All Appointment"></PageTitle>

                            <div className="card mt-4">
                                <div className="card-body card-stats">
                                    <div className="row">
                                        <div className="col-md-12">
                                        <form onSubmit={handleMedicineDetails} action='#!' method="post"  className='prescribed_medicine_form'>
                                            <div className='row'>
                                                    <div className='col-md-6 mt-2 mb-2'>
                                                        <h5 className='mt-4'>Medicine List</h5>
                                                    </div>
                                                    
                                                    
                                                        {prescribedMedicine && <>
                                                            <div className='col-md-12'>		
                                                            <table className='table table-bordered bg-white'>
                                                                <thead>
                                                                    <tr>
                                                                        <th className='text-center'>Patient</th>
                                                                        <td className='text-center'>{prescribedMedicine[0]['prescription']['patient']}</td>
                                                                        <th className='text-center'>Doctor</th>
                                                                        <td className='text-center'>{prescribedMedicine[0]['prescription']['doctor']}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className='text-center'>Branch</th>
                                                                        <td className='text-center'>{prescribedMedicine[0]['prescription']['branch']}</td>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                            </div>

                                                            <div className='col-md-12 mt-4'>								
                                                                <table className='table table-bordered bg-white'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className='width1500px text-center'>Medicine</th>
                                                                            <th className='text-center width60px'>Dose</th>
                                                                            <th className='width150px text-center'>Frequency</th>
                                                                            <th className='width150px text-center'>Course Duration</th>
                                                                            <th className='width150px text-center'>Instruction / Remark</th>
                                                                            <th className='width100px text-center'>Available Qty</th>
                                                                            { salesInfo && 
                                                                                <th className='width100px text-center'>Sell Qty</th>
                                                                            }
                                                                            {/* <th className='width100px text-center'>Sub Total</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {prescribedMedicine.map((info) => (
                                                                            <tr>
                                                                                <td className='width150px text-center'><div className='width250px text_word_wrap'>{info['medicine']['name']}</div></td>
                                                                                <td className='text-center width60px'>{info['dose']%1 == 0 ? Math.round(info['dose']): info['dose']}</td>
                                                                                <td className='width150px text-center'>{info['frequency']}</td>
                                                                                <td className='width150px text-center'>{(info['duration_count']%1 == 0 ? Math.round(info['duration_count']): info['duration_count']) + " "+ info['duration_name']}</td>
                                                                                <td className='width250px text-center'>{info['instruction']}</td>
                                                                                <td className='width100px text-center'>{info['medicine']['total_qty']}</td>
                                                                                { !salesInfo && 
                                                                                    <td className='width100px text-center'>
                                                                                        <input type="hidden" className='form-control' value={info['medicine']['price']}  name="price"/> 
                                                                                        <input type="hidden" className='form-control' value={info['medicine']['id']}  name="medicine"/> 
                                                                                        <input type="number" className='form-control'  name="qty"/>
                                                                                    </td>
                                                                                }
                                                                                {/* <td className='width100px text-center'>Sub Total</td> */}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div> 
                                                            { !salesInfo && 
                                                            <div className='col-md-12 text-right mt-4'>		
                                                                <button type='submit' className='btn btn-primary btn-sm rounded-pill'>Submit Medicine Detail</button>
                                                            </div>
                                                            }
                                                            </>
                                                        }
                                                    
                                            </div>
                                            </form>
                                        </div>
                                    </div>

                                    
                                    <div className='row'>                                        
                                        { salesInfo && 
                                            <div className='col-md-12'>	
                                                <h5>Medicine Provided to Patient from Your Pharmacy</h5>	
                                                <table className='table table-bordered bg-white'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>Medicine</th>
                                                            <th className='text-center'>Qty</th>
                                                            <th className='text-center'>Price</th>
                                                        </tr>
                                                        {salesInfo.sales_item ? 
                                                            salesInfo.sales_item.map((info) => (
                                                                <tr>
                                                                    <td className='text-center'>{info.medicine}</td>
                                                                    <td className='text-center'>{info['qty']%1 == 0 ? Math.round(info['qty']): info['qty']}</td>
                                                                    <td className='text-center'>{info['price']%1 == 0 ? Math.round(info['price']): info['price']}</td>
                                                                </tr>
                                                            )): <tr>
                                                                    <td colSpan={3} className='text-center'>Medicines not found</td>                                                       
                                                                </tr>
                                                        } 
                                                    </thead>
                                                </table>
                                            </div>
                                        }
                                    </div>



                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </div>
  )

}
