import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { accessToken } from './ValidationRegex';
import { apiBaseUrl, frontEndBaseUrl, isProductionMode } from './ConstantUtils';



export default function Header() {

    if (localStorage.getItem("isLoggedIn") == "true") {
        if (!accessToken) {
            window.location.reload();
        }
    } else {
        window.location.href = "/login"
    }
    
    const loginInfo = {
        "name":localStorage.getItem("name"),
        "user":localStorage.getItem("user"),
        "branch_id": localStorage.getItem("branch_id"),
        "role": localStorage.getItem("role"),
        "specialization": localStorage.getItem("specialization"),
        "gender": localStorage.getItem("gender"),
        "branch_code": localStorage.getItem("branch_code"),
        "branch_name": localStorage.getItem("branch_name"),
    }   

    const [userInfo, setUserInfo] = useState(loginInfo);
    const [userProfile, setUserProfile] = useState();

    useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/account/profile",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			setUserProfile(data)
		}).catch((err) => { 
            // console.log("I am header ", err)
			if (err.response.status == 401) {
				// //localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			} else {
            }
		});
	}, []);


  return (
    <>
        <div className="sidenav-header d-flex align-items-center">
            <Link className="navbar-brand" to="/">
                <img src={isProductionMode ? frontEndBaseUrl+"assets/img/brand/skin-n-logo.jpg" : "../assets/img/brand/skin-n-logo.jpg"} className="navbar-brand-img" title="Skin & You Clinic" alt="Skin & You Clinic" /> 
            </Link>
            <div className="ml-auto">
                <div className="sidenav-toggler sidenav-toggler-dark d-md-none" data-action="sidenav-unpin" data-target="#sidenav-main">
                    <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line bg-white"></i>
                    <i className="sidenav-toggler-line bg-white"></i>
                    <i className="sidenav-toggler-line bg-white"></i>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="sidenav-user d-flex flex-column align-items-center justify-content-between text-center">
            <div>
                <a href="#" className="avatar rounded-circle avatar-xl">
                    <img alt="Image placeholder" src={isProductionMode ? frontEndBaseUrl+"assets/img/theme/light/user-icon.jpg" : "../assets/img/theme/light/user-icon.jpg"} className="" />
                </a>
                <div className="mt-2">
                    <h5 className="mb-0 text-white">{userInfo && userInfo['name']}</h5>
                    <span className="d-block text-sm text-white opacity-8 mb-1">{userInfo && userInfo['specialization']}</span>
                    <span class="d-block text-md text-white opacity-8 mb-1"><i class="fas fa-map-marker-alt"></i> {userInfo && userInfo['branch_code'] +" - "+ userInfo && userInfo['branch_name']}</span>
                    
                    {/* <a href="#" className="btn btn-sm btn-white btn-icon rounded-pill shadow hover-translate-y-n3" title={`${userProfile && userProfile.patient_treated} Patients Treated successfully`}> */}
                    {/* <span className="btn-inner--icon"><i className="far fa-light fa-stretcher"></i></span> */}
                    {/* <span className="btn-inner--text">{userProfile && userProfile.patient_treated}</span> */}

                        
                    {/* </a>/ */}
                </div>
            </div>
            <div className="w-100 mt-2 actions d-flex justify-content-between">
                <Link to="/dashboard" title="Home" className="action-item action-item-lg text-white pl-0">
                    <i className="far fa-home"></i>
                </Link>
                <Link to="/add-appointment" title="Add Appointment" className="action-item action-item-lg text-white pl-0">
                    <i className="far fa-calendar-check"></i>
                </Link>

                <Link to="/prescription-list" title="View All Prescription" className="action-item action-item-lg text-white pr-0">
                    <i className="far fa-receipt"></i>
                </Link>
            </div>
        </div>
    </>

  )
}
