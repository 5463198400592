import React from 'react'

export const validEmail = new RegExp(
    '^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
);

export const validPassword = new RegExp('^(?=.*?[a-z])(?=.*?[0-9]).{6,}$');

export const validTelephone = new RegExp('^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$');

export const validMobile = new RegExp('^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$');

export const validName = new RegExp('/^[a-zA-Z ]{2,30}$/');


export const accessToken = localStorage.getItem("token");
export const loggedInUserId = localStorage.getItem("id");
export const loggedInBranchId = localStorage.getItem("branch");