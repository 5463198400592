import React from 'react'

export default function HeaderSearchInput() {
    
    const handleSearchInput = (event) => {
        console.log(" ===== Navbar =======")
    }

  return (
    <div id="omnisearch" className="omnisearch">
        <div className="container">
        
        <form className="omnisearch-form">
            <div className="form-group">
            <div className="input-group input-group-merge input-group-flush">
                <div className="input-group-prepend">
                <span className="input-group-text"><i className="far fa-search"></i></span>
                </div>
                <input type="text"  onChange={handleSearchInput}  className="form-control" placeholder="Type and hit enter ..." />
            </div>
            </div>
        </form>

        <div className="omnisearch-suggestions">
            <h6 className="heading">Search Suggestions</h6>
            <div className="row">
            <div className="col-sm-12">
                <ul className="list-unstyled mb-0">
                <li>
                    <a className="list-link" href="#">
                    <i className="far fa-search"></i>
                    <span>Dr. Geeta Mehra Fazalbhoy</span> Dermatology Specialist
                    </a>
                </li>
                <li>
                    <a className="list-link" href="#">
                    <i className="far fa-search"></i>
                    <span>Acne Scars</span> in Dermatology
                    </a>
                </li>
                <li>
                    <a className="list-link" href="#">
                    <i className="far fa-search"></i>
                    <span>Laser Hair Removal</span> in Laser Treatment
                    </a>
                </li>
                </ul>
            </div>
            </div>
        </div>
        </div>
    </div>
  )
}
