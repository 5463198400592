import React from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/PageTitle'
import Footer from '../../components/Footer'

export default function ReportList() {

	if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }
	

	return (
		<div className="container-fluid container-application">
			
			<div className="sidenav show" id="sidenav-main">
				<Header></Header>
				<Sidebar></Sidebar>
			</div>
			
			<div className="main-content position-relative">
				<Navbar></Navbar>
				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<PageTitle pageName="All Branch" link_to="add-branch" link_name="Add New Branch"></PageTitle>

							<div className="card mt-4">
								<div className="card-body card-stats">
									<div className="row">
										<div className="col-md-12 text-center mt-10 mb-10">
											<b>Coming Soon </b>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
				<Footer></Footer>
			</div>
		</div>
  )
}
