import React, { useEffect, useState } from 'react'

import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import SelectField from '../../components/SelectField';
import axios from 'axios';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import { accessToken } from '../../components/ValidationRegex';
import { Navigate } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';

// import { apiBaseUrl } from  '../components/Constant';

export default function DiseaseList() {
	if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }

	const intialFormValues = {
		"name": "",
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [isReloadDisease, setIsReloadTable] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");

	const tableColumns = [
		{
			"name": "Disease Name",
			"selector": (row) => row.name,
			"sortable": true 
		},
		{
			"name": "Created At",
			"selector": (row) => row.created_at,
			"sortable": true 
		},
		{
			"name": "Action",
			"cell": (row) => <button data-name={row.name} data-id={row.id} className='btn btn-primary btn-xs' onClick={handleActionClick}>Edit</button> 
		},
	]
	const [tableRows, setTableRows] = useState([]);

	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/core/disease",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			setTableRows(data);
			setIsReloadTable(false);
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, [isReloadDisease]);


	// Save Disease Form
	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".disease_form");
			const formdata = new FormData(formObject);

			let url = apiBaseUrl+ "api/core/disease";
			let method = 'POST';
			if (formValues.id){
				url = apiBaseUrl+ "/api/core/disease/"+formValues.id;
				method = 'PATCH';
			}

			axios({
                url: url,
                method: method,
                headers: {authorization: "Bearer "+ accessToken},
                data: formdata,
            }).then((res) => { 
				setFormSubmitError("Disease added successfully.");
				setFormValues(intialFormValues);
				setIsReloadTable(true);
			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail'])
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormError(err.response.data);
				}
			});
		}
	}, [formErrors]);

	const handleChange = (e) => {
		console.log("e.target ", e.target);
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}

	const handleDiseaseFormSubmit = (e) => {
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	} 

	const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.name == "") {
			errors.name = "This field is required";
		}
		return errors;
	}

	const handleActionClick = (e) => {
		const id = e.currentTarget.dataset.id;
		const name = e.currentTarget.dataset.name;
 		console.log("Data Set ", id, name); 
		const a = {
			'name': name,
			'id': id
		} 
		setFormValues(a);
	}

  return (
    <div className="container-fluid container-application">
        
        <div className="sidenav show" id="sidenav-main">
            <Header></Header>
            <Sidebar></Sidebar>
        </div>
        
        <div className="main-content position-relative">
            <Navbar></Navbar>
            <div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<PageTitle pageName="All Disease" link_to="#disease_modal" isModal="modal" link_name="Add New Disease"></PageTitle>

						<div className="card mt-4">
							<div className="card-body card-stats">
								<form onSubmit={handleDiseaseFormSubmit} className='disease_form'>
									<div className='row'>
										<div className="col-md-4">
											<div className="form-group">
												<label className="form-control-label">Treatment Area Name</label>
												<input className="form-control" type="text"  value={formValues.name} onChange={handleChange} name="name"  
													placeholder="Enter Disease Name" />
												<span className='error_message'>{formErrors.name}</span>
											</div>
										</div>
										<div className="col-md-2 mt-4">
											<button type="submit" className="btn mt-1  btn-primary rounded-pill">Submit</button>
										</div>
									</div>
								</form>

								<div className="row">
									<div className="col-md-12">
										<BoostrapDatatable tableColumns={tableColumns} tableRows={tableRows}></BoostrapDatatable>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
            </div>
            <Footer></Footer>
        </div>
    </div>
  )
}
