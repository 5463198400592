import React, { useEffect, useState } from 'react'

import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import SelectField from '../../components/SelectField';
import axios from 'axios';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import { accessToken } from '../../components/ValidationRegex';
import { Navigate } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SubDiseaseForm from '../../components/SubDiseaseForm';

// import { apiBaseUrl } from  '../components/Constant';


export default function SubDiseaseList() {

	if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }

	// const intialFormValues = {
	// 	"name": "",
	// 	"disease_for": "",
	// };

	// const [formValues, setFormValues] = useState(intialFormValues);
	// const [formErrors, setFormError] = useState({});
	// const [isSubmit, setIsSubmit] = useState(false);
	// const [isReloadDisease, setIsReloadTable] = useState(false);
	// const [formSubmitError, setFormSubmitError] = useState("");

	const [diseaseList, setDiseaseList] = useState([]);

	const tableColumns = [
		{
			"name": "Treatment Name",
			"selector": (row) => row.name,
			"sortable": true 
		},
		{
			"name": "Treatment Area",
			"selector": (row) => row.disease_for,
			"sortable": true 
		},
		{
			"name": "Created At",
			"selector": (row) => row.created_at,
			"sortable": true 
		},
		{
			"name": "Action",
			"cell": (row) => <button data-id={row.id} className='btn btn-primary btn-xs' onClick={handleAddDisease}>Edit</button>
		},
	]

	const [tableRows, setTableRows] = useState([]);
	const [diseaseId, setDiseaseId] = useState();
    const pageendpoint = "api/core/sub-disease"

	// Handle Boostrap Modal
	const [show, setShow] = useState(false);
	const handleClose = (e) => {
		setShow(false);
	} 
	
	const handleShow = () => setShow(true);
	const handleAddDisease = (e) => {
        setShow(true);
		const id = e.currentTarget.dataset.id;
		setDiseaseId(id)
    }



  return (
    <div className="container-fluid container-application">
        
        <div className="sidenav show" id="sidenav-main">
            <Header></Header>
            <Sidebar></Sidebar>
        </div>
        
        <div className="main-content position-relative">
            <Navbar></Navbar>
            <div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">


						<div className="page-title">
							<div className="row justify-content-between align-items-center">
								<div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
									<div className="d-inline-block">
										<h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">All Treatment</h5>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
									<a href="#!" onClick={handleAddDisease} className="btn btn-sm btn-white btn-icon rounded-pill">Add Treatment</a>
								</div>	
							</div>
						</div>

						<div className="card mt-4">
							<div className="card-body card-stats">

								<div className="row">
									<div className="col-md-12">
										<BoostrapDatatable isReload={show} page_url={pageendpoint} isServerSide={true} tableColumns={tableColumns} tableRows={tableRows}></BoostrapDatatable>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
            </div>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title id ='contained-modal-title-vcenter'>{diseaseId ? "Edit Treatment": "Add Treatment"}</Modal.Title>
					<Button type='button' className='btn-close btn-xs btn-secondary' onClick={handleClose}>X</Button>
				</Modal.Header>
				<Modal.Body>
					<SubDiseaseForm diseaseId={diseaseId} />
				</Modal.Body>
			</Modal>
            <Footer></Footer>
        </div>
    </div>
  )
}
