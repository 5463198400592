
import './App.css';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
// import { Link } from "react-router-dom";


import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';

import BranchAdd from './pages/branch/BranchAdd';
import BranchList from './pages/branch/BranchList';
import BranchDetail from './pages/branch/BranchDetail';

import AppointmentAdd from './pages/appointment/AppointmentAdd';
import AppointmentList from './pages/appointment/AppointmentList';
import AppointmentDetail from './pages/appointment/AppointmentDetail';

import DiseaseList from './pages/master/DiseaseList';
import SubDiseaseList from './pages/master/SubDiseaseList';

import UserAdd from './pages/user/UserAdd';
import UserList from './pages/user/UserList';
import UserDetail from './pages/user/UserDetail';

import PatientAdd from './pages/patient/PatientAdd';
import PatientList from './pages/patient/PatientList';
import PatientDetail from './pages/patient/PatientDetail';

import ProductAdd from './pages/inventory/ProductAdd';
import ProductList from './pages/inventory/ProductList';
import { useState } from 'react';

import { isProductionMode } from './components/ConstantUtils';
import ChangePassword from './pages/user/ChangePassword';
import UserChangePassword from './pages/user/UserChangePassword';

import ProductDetail from './pages/inventory/ProductDetail';
import Prescription from './pages/appointment/Prescription';
import PurchaseDetail from './pages/inventory/PurchaseDetail';
import PurchaseAdd from './pages/inventory/PurchaseAdd';
import PurchaseList from './pages/inventory/PurchaseList';
import PrescriptionList from './pages/appointment/PrescriptionList';
import ReportList from './pages/report/ReportsList';
import VendorList from './pages/inventory/VendorList';
import { accessToken } from './components/ValidationRegex';
import MedicineCategory from './pages/inventory/MedicineCategory';
import EditPrescription from './pages/appointment/EditPrescription';
	


function App() {
	const token = localStorage.getItem('token');

	return (
		// basename={isProductionMode ? '/hms': '/'}
		// basename='/hms'
		<BrowserRouter>
			<Routes>	
				<Route path="/" element={token ? <Dashboard/>: <LoginPage />} />
				<Route path="/dashboard" element={<Dashboard/>} />				
			
				<Route path="/add-appointment" element={<AppointmentAdd />} />
				<Route path="/appointment" element={<AppointmentList />} />
				<Route path="/appointment-detail/:id" element={<AppointmentDetail />} />
				<Route path="/add-appointment/:id" element={<AppointmentAdd />} />
				<Route path="/edit-prescription/:id" element={<EditPrescription />} />
	
				<Route path="/disease" element={<DiseaseList />} />
				<Route path="/sub-disease" element={<SubDiseaseList />} />	
				<Route path="/user" element={<UserList />} />
				<Route path="/add-user" element={<UserAdd />} />
				<Route path="/user-detail/:id" element={<UserDetail />} />
				<Route path="/add-user/:id" element={<UserAdd />} />
	
				<Route path="/change-user-password/:id" element={<UserChangePassword />} />
	
				<Route path="/patient" element={<PatientList />} />
				<Route path="/add-patient" element={<PatientAdd />} />
				<Route path="/patient-detail/:id" element={<PatientDetail />} />
				<Route path="/add-patient/:id" element={<PatientAdd />} />

	
				<Route path="/product" element={<ProductList />} />
				<Route path="/add-product" element={<ProductAdd />} />
				<Route path="/product-detail/:id" element={<ProductDetail />} />
				<Route path="/add-product/:id" element={<ProductAdd />} />
	
				<Route path="/branch-list" element={<BranchList />} />
				<Route path="/add-branch" element={<BranchAdd />} />
				<Route path="/add-branch/:id" element={<BranchAdd />} />
				<Route path="/branch-detail/:id" element={<BranchDetail />} />
				<Route path="/change-password" element={<ChangePassword />} />
				<Route path="/prescription/:id" element={<Prescription />} />
				<Route index path="/login" element={<LoginPage />} />

				<Route path="/purchase-list" element={<PurchaseList />} />
				<Route path="/add-purchase" element={<PurchaseAdd />} />
				<Route path="/add-purchase/:id" element={<PurchaseAdd />} />
				<Route path="/purchase-detail/:id" element={<PurchaseDetail />} />

				<Route path="/prescription-list" element={<PrescriptionList />} />
				<Route path="/report" element={<ReportList />} />
				<Route path="/vendor" element={<VendorList />} />
				<Route path="/medicine-category" element={<MedicineCategory />} />
	
			</Routes>
		</BrowserRouter>
	);
}

export default App;
