import React, { useState } from 'react'
import { frontEndBaseUrl, isProductionMode } from './ConstantUtils'
import { Link, Navigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

export default function Navbar() {

    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);    
    const handleNavbarDropdownMenu = (event) => {
        setIsDropdownMenuOpen(isDropdownMenuOpen ? false:true);
    }

    const handleLogout = () => {
        localStorage.setItem("isLoggedIn",false);
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("role");
        localStorage.removeItem("user");
        localStorage.removeItem("branch");
        localStorage.removeItem("specialization");
        localStorage.removeItem("gender");
        return <Navigate to="/login" replace />;
    }

    return (
        <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
            <div className="container-fluid">
                <button className="sidenav-toggler active" type="button" data-action="sidenav-pin" data-target="#sidenav-main" aria-controls="navbar-main-collapse" aria-expanded="true" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-user d-lg-none ml-auto">
                    <ul className="navbar-nav flex-row align-items-center">
                        <li className="nav-item">
                            <a href="#" className="nav-link nav-link-icon" data-action="omnisearch-open" data-target="#omnisearch"><i className="far fa-search"></i></a>
                        </li>

                        {/* <li className="nav-item dropdown dropdown-animate">
                            <a className="nav-link nav-link-icon" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="far fa-bell"></i></a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdown-menu-arrow p-0">
                            <div className="py-3 px-3">
                                <h5 className="heading h6 mb-0">Notifications</h5>
                            </div>
                            <div className="list-group list-group-flush">
                                <a href="#" className="list-group-item list-group-item-action">
                                <div className="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="2 hrs ago">
                                    <div>
                                    <span className="avatar bg-primary text-white rounded-circle">AM</span>
                                    </div>
                                    <div className="flex-fill ml-3">
                                    <div className="h6 text-sm mb-0">Alex Michael <small className="float-right text-muted">2 hrs ago</small></div>
                                    <p className="text-sm lh-140 mb-0">
                                        Some quick example text to build on the card title.
                                    </p>
                                    </div>
                                </div>
                                </a>
                                <a href="#" className="list-group-item list-group-item-action">
                                <div className="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="3 hrs ago">
                                    <div>
                                    <span className="avatar bg-warning text-white rounded-circle">SW</span>
                                    </div>
                                    <div className="flex-fill ml-3">
                                    <div className="h6 text-sm mb-0">Sandra Wayne <small className="float-right text-muted">3 hrs ago</small></div>
                                    <p className="text-sm lh-140 mb-0">
                                        Some quick example text to build on the card title.
                                    </p>
                                    </div>
                                </div>
                                </a>
                                <a href="#" className="list-group-item list-group-item-action">
                                <div className="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="5 hrs ago">
                                    <div>
                                    <span className="avatar bg-info text-white rounded-circle">JM</span>
                                    </div>
                                    <div className="flex-fill ml-3">
                                    <div className="h6 text-sm mb-0">Jason Miller <small className="float-right text-muted">5 hrs ago</small></div>
                                    <p className="text-sm lh-140 mb-0">
                                        Some quick example text to build on the card title.
                                    </p>
                                    </div>
                                </div>
                                </a>
                                <a href="#" className="list-group-item list-group-item-action">
                                <div className="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="2 hrs ago">
                                    <div>
                                    <span className="avatar bg-dark text-white rounded-circle">MJ</span>
                                    </div>
                                    <div className="flex-fill ml-3">
                                    <div className="h6 text-sm mb-0">Mike Thomson <small className="float-right text-muted">2 hrs ago</small></div>
                                    <p className="text-sm lh-140 mb-0">
                                        Some quick example text to build on the card title.
                                    </p>
                                    </div>
                                </div>
                                </a>
                                <a href="#" className="list-group-item list-group-item-action">
                                <div className="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="3 hrs ago">
                                    <div>
                                    <span className="avatar bg-primary text-white rounded-circle">RN</span>
                                    </div>
                                    <div className="flex-fill ml-3">
                                    <div className="h6 text-sm mb-0">Richard Nixon <small className="float-right text-muted">3 hrs ago</small></div>
                                    <p className="text-sm lh-140 mb-0">
                                        Some quick example text to build on the card title.
                                    </p>
                                    </div>
                                </div>
                                </a>
                            </div>
                            <div className="py-3 text-center">
                                <a href="#" className="link link-sm link--style-3">View all notifications</a>
                            </div>
                            </div>
                        </li>
                        
                        <li className="nav-item dropdown dropdown-animate">
                            <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="avatar avatar-sm rounded-circle">
                                <img alt="Image placeholder" src="../assets/img/theme/light/user-icon.jpg" />
                            </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                            <h6 className="dropdown-header px-0">Hi, Admin!</h6>
                            <a href="user/profile.html" className="dropdown-item">
                                <i className="far fa-user"></i>
                                <span>My profile</span>
                            </a>
                            <a href="account/settings.html" className="dropdown-item">
                                <i className="far fa-cog"></i>
                                <span>Settings</span>
                            </a>
                            <a href="account/billing.html" className="dropdown-item">
                                <i className="far fa-credit-card"></i>
                                <span>Billing</span>
                            </a>
                            <a href="shop/orders.html" className="dropdown-item">
                                <i className="far fa-shopping-basket"></i>
                                <span>Orders</span>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a href="authentication/login.html" className="dropdown-item">
                                <i className="far fa-sign-out-alt"></i>
                                <span>Logout</span>
                            </a>
                            </div>
                        </li> */}
                    </ul>
                </div>
                <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                    <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">

                    <Dropdown align={{ lg: 'end' }}>
                        <Dropdown.Toggle  id="dropdown-basic" className='display_flex'>
                            <div className="media media-pill align-items-center drop_navbar" >
                                <span className="avatar rounded-circle">
                                    <img alt="Image placeholder" src={isProductionMode ? frontEndBaseUrl+"assets/img/theme/light/user-icon.jpg" : "../assets/img/theme/light/user-icon.jpg"}/>
                                </span>
                                <div className="ml-2 d-none d-lg-block" onClick={handleNavbarDropdownMenu}>
                                    <span className="mb-0 text-sm  font-weight-bold">{localStorage.getItem('name')}</span>
                                </div>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#!">
                                <h6 className="dropdown-header px-0">Hi, {localStorage.getItem('role')}!</h6></Dropdown.Item>

                            <Dropdown.Item href="#!">
                                <Link to="/change-password" className='color_unset' onClick={handleNavbarDropdownMenu}> 
                                    <i className="far fa-cog"></i> <span>Change Password</span>
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item>                            
                                <Link to="/login" className='color_unset' onClick={handleLogout}  >
                                    <i className="far fa-sign-out-alt"></i>  <span>Logout</span>
                                </Link>
                            </Dropdown.Item>
                        </Dropdown.Menu>                
                    </Dropdown>
                    
                    {/* <li className="nav-item">
                        <a href="#" className="nav-link nav-link-icon" data-action="omnisearch-open" data-target="#omnisearch"><i className="far fa-search"></i></a>
                    </li> */}

                    {/* <li className="nav-item dropdown dropdown-animate">
                        <a className="nav-link nav-link-icon" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="far fa-bell"></i></a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdown-menu-arrow p-0">
                        <div className="py-3 px-3">
                            <h5 className="heading h6 mb-0">Notifications</h5>
                        </div>
                        <div className="list-group list-group-flush">
                            <a href="#" className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="2 hrs ago">
                                <div>
                                <span className="avatar bg-primary text-white rounded-circle">AM</span>
                                </div>
                                <div className="flex-fill ml-3">
                                <div className="h6 text-sm mb-0">Alex Michael <small className="float-right text-muted">2 hrs ago</small></div>
                                <p className="text-sm lh-140 mb-0">
                                    Some quick example text to build on the card title.
                                </p>
                                </div>
                            </div>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="3 hrs ago">
                                <div>
                                <span className="avatar bg-warning text-white rounded-circle">SW</span>
                                </div>
                                <div className="flex-fill ml-3">
                                <div className="h6 text-sm mb-0">Sandra Wayne <small className="float-right text-muted">3 hrs ago</small></div>
                                <p className="text-sm lh-140 mb-0">
                                    Some quick example text to build on the card title.
                                </p>
                                </div>
                            </div>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="5 hrs ago">
                                <div>
                                <span className="avatar bg-info text-white rounded-circle">JM</span>
                                </div>
                                <div className="flex-fill ml-3">
                                <div className="h6 text-sm mb-0">Jason Miller <small className="float-right text-muted">5 hrs ago</small></div>
                                <p className="text-sm lh-140 mb-0">
                                    Some quick example text to build on the card title.
                                </p>
                                </div>
                            </div>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="2 hrs ago">
                                <div>
                                <span className="avatar bg-dark text-white rounded-circle">MJ</span>
                                </div>
                                <div className="flex-fill ml-3">
                                <div className="h6 text-sm mb-0">Mike Thomson <small className="float-right text-muted">2 hrs ago</small></div>
                                <p className="text-sm lh-140 mb-0">
                                    Some quick example text to build on the card title.
                                </p>
                                </div>
                            </div>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center" data-toggle="tooltip" data-placement="right" data-title="3 hrs ago">
                                <div>
                                <span className="avatar bg-primary text-white rounded-circle">RN</span>
                                </div>
                                <div className="flex-fill ml-3">
                                <div className="h6 text-sm mb-0">Richard Nixon <small className="float-right text-muted">3 hrs ago</small></div>
                                <p className="text-sm lh-140 mb-0">
                                    Some quick example text to build on the card title.
                                </p>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="py-3 text-center">
                            <a href="#" className="link link-sm link--style-3">View all notifications</a>
                        </div>
                        </div>
                    </li> */}
{/* 
                    <li className="nav-item dropdown dropdown-animate" onClick={handleNavbarDropdownMenu}>
                        <a  className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="media media-pill align-items-center" onClick={handleNavbarDropdownMenu}>
                                <span className="avatar rounded-circle">
                                    <img alt="Image placeholder" src={isProductionMode ? frontEndBaseUrl+"assets/img/theme/light/user-icon.jpg" : "../assets/img/theme/light/user-icon.jpg"}/>
                                </span>
                                <div className="ml-2 d-none d-lg-block" onClick={handleNavbarDropdownMenu}>
                                    <span className="mb-0 text-sm  font-weight-bold">{localStorage.getItem('name')}</span>
                                </div>
                            </div>
                        </a>
                        <div className={isDropdownMenuOpen ? 'dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow show': 'dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow'} >
                            <h6 className="dropdown-header px-0">Hi, {localStorage.getItem('role')}!</h6>
                            
                            <a href="#!" className="dropdown-item" onClick={handleNavbarDropdownMenu}> 
                                <i className="far fa-user"></i>
                                <span>My profile</span>
                            </a>
                            
                            <Link to="/change-password" className="dropdown-item" onClick={handleNavbarDropdownMenu}>
                                <i className="far fa-cog"></i>
                                <span>Change Password</span>
                            </Link>
                            
                            <div className="dropdown-divider"></div>
                            
                            <Link to="/login" onClick={handleLogout} className="dropdown-item" >
                                <i className="far fa-sign-out-alt"></i>
                                <span>Logout</span>
                            </Link>
                        </div>
                    </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    )
}
