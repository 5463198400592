import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import axios from 'axios';
import { accessToken } from '../../components/ValidationRegex';
import { Navigate } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';
import ActionMenu from '../../components/ActionMenu';


export default function ProductList() {
	if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }
  
	const actionMenuItem = [
		{
			'link_name': 'View',
			'link_to': '/product-detail',
			'column': 'id',
			'url_param': true
		},
		{
			'link_name': 'Edit',
			'link_to': '/add-product',
			'column': 'id',
			'url_param': true
		},
		// {
		// 	'link_name': 'Delete',
		// 	'link_to': '/patient-delete',
		// 	'column': 'id',
		// 	'url_param': true
		// }
	]
	

  	const tableColumns = [
		{
			"name": "Name",
			"selector": (row) => row.name,
			"sortable": true 
		},
		{
			"name": "Content",
			"selector": (row) => row.content,
			"sortable": true 
		},
		{
			"name": "Category",
			"selector": (row) => row.medicine_category,
			"sortable": true 
		},
		// {
		// 	"name": "Sku code",
		// 	"selector": (row) => row.sku_code,
		// 	"sortable": true 
		// },
		{
			"name": "Qty",
			"selector": (row) => row.total_qty,
			"sortable": true 
		},
		// {
		// 	"name": "Opening Qty",
		// 	"selector": (row) => row.opening_qty ? row.opening_qty: 0,
		// 	"sortable": true 
		// },
		{
			"name": "Price",
			"selector": (row) => row.price,
			"sortable": true 
		},
		{
			"name": "Action",
			"cell": (row) => <ActionMenu row_data={row} pk={row.id} actionMenuItem={actionMenuItem}></ActionMenu>
		},
	]

	const [tableRows, setTableRows] = useState([]);

  return (
    <div className="container-fluid container-application">
		<div className="sidenav show" id="sidenav-main">
			<Header></Header>
			<Sidebar></Sidebar>
		</div>
    	<div className="main-content position-relative">
			<Navbar></Navbar>
			<div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<PageTitle  pageName="Medicine List" link_to="add-product" link_name="Add Medicine"></PageTitle>
						<div className="card mt-4">
						<div className="card-body card-stats">
							<div className="row">
								<div className="col-md-12">
									<BoostrapDatatable isServerSide={true} tableColumns={tableColumns} tableRows={tableRows}></BoostrapDatatable>
								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
  )
}
