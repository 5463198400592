import React, { useState } from 'react'
import ActionMenu from '../../components/ActionMenu';
import PageTitle from '../../components/PageTitle';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

export default function PurchaseList() {
	if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }

    const actionMenuItem = [
		{
			'link_name': 'View',
			'link_to': '/purchase-detail',
			'column': 'id',
			'url_param': true
		},
	]
	

  	const tableColumns = [
		{
			"name": "Vendor",
			"selector": (row) => row.vendor,
			"sortable": true 
		},
		{
			"name": "Invoice Date",
			"selector": (row) => row.invoice_date,
			"sortable": true 
		},
		{
			"name": "Invoice No.",
			"selector": (row) => row.invoice_no,
			"sortable": true 
		},
		{
			"name": "Total Amount",
			"selector": (row) => row.qty,
			"sortable": true 
		},
		{
			"name": "Download Invoice",
			"cell": (row) => row.invoice ? <a target='blank' href={row.invoice} download>Download</a>: "",
			
		},
		{
			"name": "Action",
			"cell": (row) => <ActionMenu row_data={row} pk={row.id} actionMenuItem={actionMenuItem}></ActionMenu>
		},
	]

	const [tableRows, setTableRows] = useState([]);
    const page_url = "api/inventory/purchase"


  return (
    <div className="container-fluid container-application">
		<div className="sidenav show" id="sidenav-main">
			<Header></Header>
			<Sidebar></Sidebar>
		</div>
    	<div className="main-content position-relative">
			<Navbar></Navbar>
			<div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<PageTitle pageName="All Purchase Invoice" link_to="add-purchase" link_name="Add Purchase"></PageTitle>
						<div className="card mt-4">
						<div className="card-body card-stats">
							<div className="row">
								<div className="col-md-12">
									<BoostrapDatatable page_url={page_url} isServerSide={true} tableColumns={tableColumns} tableRows={tableRows}></BoostrapDatatable>
								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
  )

  
}

