import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import axios from 'axios';
import { accessToken } from '../../components/ValidationRegex';
import { Link, Navigate } from 'react-router-dom';
import ActionMenu from '../../components/ActionMenu';
import { apiBaseUrl } from '../../components/ConstantUtils';


export default function PatientList() {

	if (localStorage.getItem("isLoggedIn") != "true") {
        window.location.href = "/login"
    }

	const actionMenuItem = [
		{
			'link_name': 'View',
			'link_to': '/patient-detail',
			'column': 'id',
			'url_param': true
		},
		{
			'link_name': 'Add Appointment',
			'link_to': '/add-appointment',
			'column': 'id',
			'url_param': false
		},
		{
			'link_name': 'Edit',
			'link_to': '/add-patient',
			'column': 'id',
			'url_param': true
		}
		// {
		// 	'link_name': 'Delete',
		// 	'link_to': '/patient-delete',
		// 	'column': 'id',
		// 	'url_param': true
		// }
	]
	
  	const tableColumns = [
		{
			"name": "Name",
			"selector": (row) => row.user.name,
			"sortable": true 
		},
		{
			"name": "Email",
			"selector": (row) => row.user.email,
			"sortable": true 
		},
		{
			"name": "Mobile No.",
			"selector": (row) => row.user.mobile,
			"sortable": true 
		},
		{
			"name": "Branch",
			"selector": (row) => row.branch.name,
			"sortable": true 
		},
		{
			"name": "Created At",
			"selector": (row) => row.created_at,
			"sortable": true 
		},
		{
			"name": "Action",
			"cell": (row) => <ActionMenu row_data={row} pk={row.user.id} actionMenuItem={actionMenuItem}></ActionMenu>
		},
	]

	// <><button className='btn btn-primary btn-sm' onClick={() => alert(row.name)}>Edit</button> 
	// 			<Link to = {`/patient-detail/${row.user.id}`} className='btn btn-primary btn-sm' >Details</Link> </>

	const [tableRows, setTableRows] = useState([]);
	const [pageEndPoint, setPageEndpoint] = useState("api/account/patient-list-for-table")

	// useEffect(() => {
	// 	axios({
	// 		url: apiBaseUrl+ "api/account/staff?user_type=2",
	// 		method: "GET",
	// 		headers: {authorization: "Bearer "+accessToken},
	// 		data: [],
	// 	}).then((res) => { 
	// 		var data = res.data.data;
	// 		setTableRows(data);
	// 	}).catch((err) => { 
	// 		if (err.response.status == 401) {
	// 			//localStorage.removeItem("token");
	// 			return <Navigate to="/login" replace />;
	// 		}
	// 	});
	// }, []);


  return (
    <div className="container-fluid container-application">
		<div className="sidenav show" id="sidenav-main">
			<Header></Header>
			<Sidebar></Sidebar>
		</div>
    	<div className="main-content position-relative">
			<Navbar></Navbar>
			<div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<PageTitle pageName="All Patients" link_to="add-patient" link_name="Add Patient"></PageTitle>
						<div className="card mt-4">
							<div className="card-body card-stats">
								<div className="row">
								<div className="col-md-12">
									<BoostrapDatatable page_url={pageEndPoint} isServerSide={true} tableColumns={tableColumns} tableRows={tableRows}></BoostrapDatatable>
								</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
  )
}
