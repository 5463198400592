import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
import BoostrapDatatable from '../../components/BoostrapDatatable';
import axios from 'axios';
import { accessToken } from '../../components/ValidationRegex';
import { Navigate, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';
import PatientTreatment from '../../components/PatientTreatment';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TreatmentHistory from '../../components/TreatmentHistory';

// import { apiBaseUrl } from  '../components/Constant';

export default function PatientDetail(props) {
	const { id } = useParams()
	const [isLoaded, setIsLoaded] = useState(false)
	const [patientData, setPatientData] = useState()
	const [patientAppointmentList, setPatientAppointmentList] = useState()
	const [selectedAppointmentId, setAppointmentId] = useState()
	const [selectedPatientId, setPatientId] = useState()
	const [show, setShow] = useState(false);

	const handleTab = (currentElement) => {
		document.querySelectorAll('.tab_link').forEach(function(e) {
			e.classList.remove("active");
		});

		document.querySelectorAll('.tab_content').forEach(function(e) {
			e.classList.remove("active");
			e.classList.remove("show");
		});

		currentElement.target.classList.add("active");
		const data_href = currentElement.target.getAttribute("data_href");
		
		var element = document.getElementById(data_href);
		element.classList.add("active");
		element.classList.add("show");
	}

	useEffect(() => {
		axios({
			url: apiBaseUrl+ "api/account/staff/"+id+"?user_type=2",
			method: "GET",
			headers: {authorization: "Bearer "+accessToken},
			data: [],
		}).then((res) => { 
			var data = res.data.data;
			setPatientData(data)
			setIsLoaded(true)
		}).catch((err) => { 
			if (err.response.status == 401) {
				//localStorage.removeItem("token");
				return <Navigate to="/login" replace />;
			}
		});
	}, []);


	useEffect(() => {
		if (id) {	
			axios({
				url: apiBaseUrl+ "api/book/patient-appointment?patient_id="+id,
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				// data: {'patient_id': patientData.user.id},
			}).then((res) => { 
				var data = res.data.data;
				setPatientAppointmentList(data)
			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, []);

	// Modal Window 
	const handleClose = () => setShow(false);
	const handleShow = (e) => {
		const appointmentId = e.target.getAttribute('data_appointment_id')
		const patientId = e.target.getAttribute('data_patient_id')
		setAppointmentId(appointmentId);
		setPatientId(patientId);
		setShow(true);
	}
	
  return (
	<div className="container-fluid container-application">
        <div className="sidenav show" id="sidenav-main">
            <Header></Header>
            <Sidebar></Sidebar>
        </div>
        <div className="main-content position-relative">
            <Navbar></Navbar>
            <div className="page-content">
              	<div className="row justify-content-center">
					<div className="col-lg-12">
						<PageTitle pageName="Patient Details" link_to="patient" link_name="View All Patient"></PageTitle>

						

						<div className="card">
							<div className="card-body">

								<ul className="nav nav-tabs" role="tablist">
									<li className="nav-item">
										<a className="nav-link tab_link active" data-bs-toggle="tab" data_href="personal_detail" href="#!" onClick={handleTab}>Personal Detail</a>
									</li>

									<li className="nav-item">
										<a className="nav-link tab_link " data-bs-toggle="tab" data_href="chief_complaint" href="#!" onClick={handleTab}>Chief Complaints</a>
									</li>
									
									<li className="nav-item">
										<a className="nav-link tab_link" data-bs-toggle="tab" data_href="other_detail" href="#!" onClick={handleTab}>Other Detail</a>
									</li>
									
									<li className="nav-item">
										<a className="nav-link tab_link" data-bs-toggle="tab" data_href="treatment_history" href="#!" onClick={handleTab}>Treatment History</a>
									</li>
									
									{/* <li className="nav-item">
										<a className="nav-link tab_link" data-bs-toggle="tab" data_href="family_details" href="#!" onClick={handleTab}>Family Details</a>
									</li> */}
								</ul>

								<div className="tab-content">
									<div id="personal_detail" className="container tab-pane tab_content show active"><br/>
										<div>
											<table className='table table-bordered table-striped'>
												<tbody>
													<tr>
														<th>Name</th>
														<td>{patientData ? patientData.user.name: ""}</td>
														<th>Email</th>
														<td>{patientData ? patientData.user.email: ""}</td>
														<th>Mobile Number</th>
														<td>{patientData ? patientData.user.mobile: ""}</td>
													</tr>

													<tr>
														<th>Medical Record No.</th>
														<td>{patientData ? patientData.medical_record_no: ""}</td>
														<th>Age</th>
														<td>{patientData ? patientData.age: ""}</td>
														<th>Gender</th>
														<td>{patientData ? patientData.user.gender: ""}</td>
													</tr>
													
													<tr>
														<th>Branch</th>
														<td>{patientData ? patientData.branch.name: ""}</td>
														<th>Created At</th>
														<td>{patientData ? patientData.created_at: ""}</td>
														<th>Created By</th>
														<td></td>
													</tr>

												</tbody>
											</table>

											
											<br/>
											<h5>Address</h5>
											{patientData ?  
												<table className='table table-bordered table-stripped'>
													{patientData.address.length > 0 ? 
													<tbody>
														<tr>
															<th>Address</th>
															<td colSpan={5}>{patientData ? patientData.address[0].address: ""}</td>
														</tr>
														<tr>
															<th>City</th>
															<td>{patientData ? patientData.address[0].city: ""}</td>
															<th>State</th>
															<td>{patientData ? patientData.address[0].state: ""}</td>
															<th>Pincode</th>
															<td>{patientData ? patientData.address[0].pincode: ""}</td>
														</tr>
													</tbody>
													: ""}

												</table>
											: ""}
										</div>
									</div>

									<div id="chief_complaint" className="container tab-pane fade  tab_content"><br/>
										<table className='table table-bordered table-stripped'>
											<tr>
												<th>Dermatology Concerns</th>
												<td colSpan={5}>{patientData ? patientData.dermatology_concern: ""}</td>
											</tr>
											<tr>
												<th>Any other Dermatology Concerns</th>
												<td colSpan={5}>{patientData ? patientData.other_dermatology_concern: ""}</td>
											</tr>
											<tr>
												<th>Cosmetic Concerns</th>
												<td colSpan={5}>{patientData ? patientData.cosmetic_concern: ""}</td>
											</tr>
											<tr>
												<th>Any other Cosmetic Concerns</th>
												<td colSpan={5}>{patientData ? patientData.other_cosmetic_concern: ""}</td>
											</tr>
										</table>
									</div>

									<div id="other_detail" className="container tab-pane fade tab_content"><br/>
										{patientData && 
											<><table className='table table-bordered table-stripped'>
												<tr>
													<th>Alcohal</th>
													<td>{patientData.has_alcoholic ? "Yes": "No"}</td>
													<th>Smoking</th>
													<td>{patientData.has_smoking ? "Yes": "No"}</td>
													<th>Sun Screen Use</th>
													<td>{patientData.has_sunscreen_use ? "Yes": "No"}</td>
												</tr>
												<tr>
													<th>Pets</th>
													<td>{patientData.has_pets ? "Yes": "No"}</td>
													<th>Outdoor Activities</th>
													<td>{patientData.has_outdoor_activities ? "Yes": "No"}</td>
													<th>Skin Care Routine</th>
													<td>{patientData.has_skin_care_activity ? "Yes": "No"}</td>
												</tr>
											</table>

											<br/>

											<table className='table table-bordered table-stripped'>
												<tr>
													<th><div className='w-50'>Medications including non prescriptions allergy or oral contraceptive or ayurvedic pill</div></th>
													<td colSpan={5}>{patientData ? patientData.allergy_or_oral_contraceptive: ""}</td>
												</tr>
												<tr>
													<th>Menstrual History / Childbirth History</th>
													<td colSpan={5}>{patientData ? patientData.menstrual_child_birth_history: ""}</td>
												</tr>
												<tr>
													<th>Hair Complaints</th>
													<td colSpan={5}>{patientData ? patientData.hair_complaints: ""}</td>
												</tr>
												<tr>
													<th>Nail Complaints</th>
													<td colSpan={5}>{patientData ? patientData.nail_compalints: ""}</td>
												</tr>
												<tr>
													<th>Weight gain / loss in the last 3 months</th>
													<td colSpan={5}>{patientData ? patientData.weight_gain_loss_in_3_months: ""}</td>
												</tr>
												<tr>
													<th>Diet</th>
													<td colSpan={5}>{patientData ? patientData.diet: ""}</td>
												</tr>
												<tr>
													<th>Excercise</th>
													<td colSpan={5}>{patientData ? patientData.excercise: ""}</td>
												</tr>
												<tr>
													<th>Sleeping Habits</th>
													<td colSpan={5}>{patientData ? patientData.sleeping_habits: ""}</td>
												</tr>
											</table>
											</>
										}
									</div>

									<div id="treatment_history" className="container tab-pane fade tab_content"><br/>

										<TreatmentHistory patient_id={id} />
										
										{/* <div class="row">
											<div className='col-md-12'>
												
												<table className='table table-bordered'>
													<thead>
														<tr>
															<th>Appointment Date</th>
															<th>Patient</th>
															<th>Doctor</th>
															<th>Status</th>
															<th>Have Prescription ?</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														{patientAppointmentList ? patientAppointmentList.map((info) => (
															<tr>
																<td>{info.appointment_at}</td>
																<td>{info.patient__name}</td>
																<td>{info.doctor__name}</td>
																<td>{info.status}</td>
																<td>{info.prescription_appointment__id ? "Yes": "No"}</td>
																<td><button onClick={handleShow} data_appointment_id={info.id} data_patient_id={info.patient} type="button" className='btn btn-xs btn-primary'>View Details</button></td>
															</tr>
														)): 
															<tr>
																<td colSpan={6}>Data Not Available</td>
															</tr>
														}
														
													</tbody>
												</table>

											</div>
										</div> */}


									</div>

									{/* 									
									<div id="family_details" className="container tab-pane fade tab_content"><br/>
										<h3>Family Details</h3>
										<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
									</div> */}

								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>

			<Modal size="xl" show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title id = 'contained-modal-title-vcenter'>View Treatment Details</Modal.Title>
					<Button type='button' className='btn-close btn-xs btn-secondary' onClick={handleClose}>X</Button>
				</Modal.Header>
				<Modal.Body>
					<PatientTreatment patientId={selectedPatientId} appointmentId={selectedAppointmentId} />
				</Modal.Body>
			</Modal>


            <Footer></Footer>
        </div>
    </div>
  )
}
