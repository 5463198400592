import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header' 

import TextField from '../../components/TextField' 
import PageTitle from '../../components/PageTitle';
import DateField from '../../components/DateField';
// import SelectField from '../../components/SelectField';

import { accessToken } from '../../components/ValidationRegex';

import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../components/ConstantUtils';
import { BlobProvider, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PrescriptionPDF from '../appointment/PrescriptionPDF';

export default function ProductDetail() {
    const { id } = useParams()
	const [medicineData, setMedicineData] = useState();
	
	useEffect(() => {
		if (id) {
			axios({
				url: apiBaseUrl+ "api/inventory/medicine/"+id,
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
			}).then((res) => { 
				var data = res.data;

                console.log('data.data ', data.data);
				setMedicineData(data.data);

                // console.log("medicineData['branch_list'].length ", medicineData['branch_list'].length);
			}).catch((err) => { 
				if (err.response.status == 401) {
					//localStorage.removeItem("token");
					return <Navigate to="/login" replace />;
				}
			});
		}
	}, []);


  return (
    <div className="container-fluid container-application">
		<div className="sidenav show" id="sidenav-main">
			<Header></Header>
			<Sidebar></Sidebar>
		</div>
    	<div className="main-content position-relative">
			<Navbar></Navbar>
			<div className="page-content">
				<div className="row justify-content-center">
					<div className="col-lg-12">
						<PageTitle pageName="Medince Details" link_to="product" link_name="View All Medicine's"></PageTitle>
						<div className="card mt-4">
						<div className="card-body card-stats">
							<div className="row">
								<div className="col-md-12">

                                {/* <PDFDownloadLink document={<PrescriptionPDF />} filename="FORM">
                                    {({loading}) => (loading ? <a target='_blank'>Loading Document...</a> : <a target='_blank'>Download</a> )}
                                </PDFDownloadLink>
 */}

                                {/* <PDFViewer width={"100%"} height={"700px"} fullScreen={true}>
                                    <PrescriptionPDF />
                                </PDFViewer> */}


                                {/* <BlobProvider document={<PrescriptionPDF/>} >
                                    {({ url }) => (
                                    <a className="button" href={url} target="_blank" rel="noopener noreferrer">
                                        Open in New Tab
                                    </a>
                                )}
                                </BlobProvider> */}

									<table className='table table-bordered table-striped'>
										<tbody>
											<tr>
												<th>Medicine Name</th>
												<td>{medicineData ? medicineData.medicine.name: ""}</td>
												<th>Content</th>
												<td>{medicineData ? medicineData.medicine.content: ""}</td>
												
											</tr>
											<tr>
												<th>Medicine Category</th>
												<td>{medicineData ? medicineData.medicine.medicine_category: ""}</td>
												<th>SKU Code</th>
												<td>{medicineData ? medicineData.medicine.sku_code: ""}</td>
												
											</tr>
                                            <tr>
											<th>Total Qty</th>
												<td>{medicineData ? medicineData.medicine.total_qty: ""}</td>
												<th>Price</th>
												<td>{medicineData ? medicineData.medicine.price: ""}</td>
												
											</tr>
										</tbody>
									</table>

                                    <h5 className='mt-4'>Branchwise Available Quantity </h5>
                                    
                                    

                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Branch Name</th>
                                                <th>Available Qty</th>
											</tr>
                                        </thead>
                                        { medicineData && medicineData.branch_list.length > 0?
                                            <tbody>
                                                {medicineData.branch_list.map((info, i) => (
                                                    <tr>
                                                        <td>{info.medicine_quantity__branch__name}</td>
                                                        <td>{info.medicine_quantity__qty}</td>
                                                    </tr>
                                                ))}
                                            </tbody>   
                                        : <tbody>
                                            <tr>
                                                <td className='text-center' colSpan={2}>Branch wise medicine details not available</td>
                                            </tr>
                                        </tbody>
                                    }                                    
									</table>   
                                    



								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
  )
}
