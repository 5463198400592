import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { apiBaseUrl } from './ConstantUtils';
import { accessToken } from './ValidationRegex';
import { Navigate } from 'react-router';

export default function MedicineCategoryForm(props) {
    const intialFormValues = {
		"name": ""
	};

	const [formValues, setFormValues] = useState(intialFormValues);
	const [formErrors, setFormError] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState("");


    const handleChange = (e) => {
		const {name,value} = e.target;
		setFormValues({...formValues, [name]: value});
	}

    // Submit Vendor Form
	useEffect(() => {
		if (Object.keys(formErrors).length == 0 && isSubmit){
			const formObject = document.querySelector(".mc_form");
			const formdata = new FormData(formObject);

            let url  = apiBaseUrl+ "api/inventory/medicine/category";
            let method = "POST";

            if (props.medicine_category_id) {
                url = apiBaseUrl+ "api/inventory/medicine/category/"+props.medicine_category_id;
                method = "PATCH";
            } 

			axios({
                url: url,
                method: method,
                headers: {authorization: "Bearer "+ accessToken},
                data: formdata,
            }).then((res) => { 
				if (props.vendorId) {
                    setFormSubmitError("Medicine Category has been updated successfully.");
                } else {
                    setFormSubmitError("Medicine Category has been added successfully.");
                    setFormValues(intialFormValues);
                }   
				
			}).catch((err) => { 
				if (err.response.status == 401) {
					setFormSubmitError(err['response']['data']['detail']);					
					return <Navigate to="/login" replace />;
				} else if (err.response.status == 400) {
					setFormError(err.response.data);
				}
			});
		}
	}, [formErrors]);


    useEffect(() => {
		if (props.medicine_category_id) {
			axios({
				url: apiBaseUrl +"api/inventory/medicine/category/"+props.medicine_category_id,
				method: "GET",
				headers: {authorization: "Bearer "+accessToken},
				data: [],
			}).then((res) => { 
				var data = res.data.data;
                console.log("data ", data)
                setFormValues(data)
			}).catch((err) => { 
				if (err.response.status == 401) {
					return <Navigate to="/login" replace />;
				}
			});
		} else {
			setFormValues(intialFormValues);
		}
	}, [props.medicine_category_id]);


    const validateForm = (formDataObject) => {
		const errors = {};
		if (formDataObject.name == "") {
			errors.name = "This field is required";
		}

        // let nameRegex = /^[a-zA-Z ]{2,30}$/;
		// if (!nameRegex.test(formDataObject.name)) {
		// 	errors.name = "Please enter valid name";
		// }


		// if (formDataObject.email == "") {
		// 	errors.email = "This field is required";
		// }

		// if (formDataObject.contact == "") {
		// 	errors.contact = "This field is required";
		// }

		return errors;
	}

    const handleAppointmentForm = (e) =>{
		e.preventDefault();
		setFormError(validateForm(formValues));
		setIsSubmit(true);
	}


  return (
    <div>
        {formSubmitError && <div className='alert alert-success'>{formSubmitError}</div>}
        <form onSubmit={handleAppointmentForm} action='#!' method="post"  className={props.is_border_required ? 'border p-4 mc_form': 'mc_form'}>
            <div className='row'>
                
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="form-control-label">Name</label>
                        <input className="form-control" type="text"  value={formValues.name} onChange={handleChange} name="name"  
                            placeholder="Enter Medicine Category" />								
                        <span className='error_message'>{formErrors.name}</span>
                    </div>
                </div>


            </div>

            <div className='row'>
                <div className="col-md-12">
                    {formErrors.non_field_errors && <p className='error_message mb-4'>{formErrors.non_field_errors ? "Medicine Category already exist": ""}</p>}
                    <div className="form-group text-right">
                        <button type="submit" className="btn btn-primary btn-sm" >Add Medicine Category</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
  )
}

